import styles from 'pages/auth/Auth.module.css'
import { RefreshCircle } from 'iconsax-react'
import { Modal } from 'components/common/Modal'
import { useCollaborations } from 'hooks/useCollaborations'
import { useEffect, useState } from 'react'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { HelpItem } from 'components/create-profile/Collaborate'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'
import { setUser } from '../../redux/reducers/authReducer'
import UserService from 'services/user.service'
import { useNotifications } from 'hooks/useNotification'

const service = new UserService()

export const CollaborateModal = ({ showModal, setShowModal }) => {
    const { collaborations, loading } = useCollaborations()
    const [servicesAsking, setServicesAsking] = useState<any>([])
    const [servicesOffering, setServicesOffering] = useState<any>([])

    const dispatch = useThunkDispatch()
    const { successMessage } = useNotifications()
    const user = useSelector(userSelector)

    useEffect(() => {
        setServicesAsking(
            collaborations.map(item => ({
                ...item,
                selected: user?.collaborate[0]?.servicesAskingFor.some(
                    sessionItem => sessionItem?._id === item._id,
                ),
            })),
        )
        setServicesOffering(
            collaborations.map(item => ({
                ...item,
                selected: user?.collaborate[0]?.servicesOfferTo.some(
                    sessionItem => sessionItem?._id === item._id,
                ),
            })),
        )
    }, [collaborations])

    const handleSubmit = e => {
        e.preventDefault()

        const servicesAskingFor = servicesAsking
            .filter(item => item.selected)
            .map(item => item._id)
            .join(',')

        const servicesOfferTo = servicesOffering
            .filter(item => item.selected)
            .map(item => item._id)
            .join(',')

        service
            .addCollaborate({
                servicesAskingFor,
                servicesOfferTo,
                userId: user._id,
            })
            .then(resp => {
                dispatch(setUser(resp.data.data))
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setShowModal(false)
                successMessage('Collaboration updated successfully')
            })
    }

    return (
        <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            title={'Collaborate'}
            footerButton="Save"
            handleClickPrimary={handleSubmit}
            headerIcon={''}>
            <div className="grid px-6 md:px-8 py-3">
                <p className="font-normal text-lg md:text-body-caption-bold text-dark-8 font-OTabolas">
                    Connect and collaborate with other creatives
                </p>
                <p className={`mt-1 ${styles.messageText}`}>
                    Suura Collaboration Board is where our community comes
                    together to help each other thrive. Tell us what you're
                    seeking and what you're offering.
                </p>
                <hr className="text-gray-outline mt-8" />
                <div className="mt-6">
                    <div className="w-full">
                        <div className="text-base text-gray-700 font-TTHoves font-semibold">
                            I'd like help with
                        </div>
                        <div className="text-body-thin  text-gray-600 font-TTHovesEL">
                            Select the services you’d like to ask from other
                            professionals
                        </div>
                        {loading ? (
                            <RefreshCircle className="animate-spin h-10 w-10 mt-2 text-tale-10" />
                        ) : (
                            <div className="flex flex-row flex-wrap pt-4">
                                {servicesAsking.map((item, index) => {
                                    return (
                                        <HelpItem
                                            key={index}
                                            collaborations={servicesAsking}
                                            setCollaborations={
                                                setServicesAsking
                                            }
                                            {...item}
                                        />
                                    )
                                })}
                            </div>
                        )}
                    </div>
                    <hr className="text-gray-outline mt-6" />
                    <div className="w-full mt-6">
                        <div className="text-base text-gray-700 font-TTHoves font-semibold">
                            I can offer
                        </div>
                        <div className="text-body-thin  text-gray-600 font-TTHovesEL">
                            Select the services you can offer to other
                            professionals
                        </div>
                        {loading ? (
                            <RefreshCircle className="animate-spin h-10 w-10 mt-2 text-tale-10" />
                        ) : (
                            <div className="flex flex-row flex-wrap pt-4">
                                {servicesOffering.map((item, index) => {
                                    return (
                                        <HelpItem
                                            key={index}
                                            collaborations={servicesOffering}
                                            setCollaborations={
                                                setServicesOffering
                                            }
                                            {...item}
                                        />
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    )
}
