import UseBalance from 'hooks/useBalance'
import { useNavigate } from 'react-router-dom'
import { Payjob } from 'utils/staticData'

const ApplyJobPayment = ({ setOpenPayment, setQuoteModal, selected }) => {
    const { userBalance } = UseBalance()
    const navigate = useNavigate()
    const is_balance = userBalance < selected?.serviceType.price

    const onClickPay = () => {
        if (userBalance >= selected?.serviceType.price) {
            setQuoteModal(true)
            setOpenPayment(false)
        } else {
            navigate('/dashboard/wallet')
        }
    }

    return (
        <div className="bg-white w-[688px] rounded-xl">
            <div className="flex p-6 items-center gap-4 border-b border-[#EAECF0]">
                <span
                    className={`w-[48px] h-[48px] p-3 border rounded border-[#EAECF0]`}>
                    <img src={Payjob} alt="" className="w-full" />
                </span>

                <div>
                    <p className="text-[18px] leading-[21px] text-[#101828] font-medium flex items-center gap-4 mb-1">
                        Apply with Confidence
                    </p>

                    {userBalance < selected?.serviceType.price ? (
                        <p
                            className={`text-[14px] leading-[20px]  font-semibold text-[#EB5757] w-[400px]`}>
                            You don't have enough balance to apply for this job!
                            Please add balance in to your wallet first.
                        </p>
                    ) : (
                        <p className="text-[14px] leading-[20px] text-[#475467] ">
                            A small application fee ensures serious
                            opportunities with potential clients. Invest in
                            showcasing your skills and secure your next project.
                        </p>
                    )}
                </div>
            </div>
            <div className="flex flex-col p-6">
                <p className="text-[50px] leading-[90px] font-medium text-[#101828] text-center border-t border-b py-5 mb-5 border-[#EAECF0]">{`Є ${
                    selected?.serviceType.price
                        ? selected?.serviceType.price.toFixed(2)
                        : '--'
                }`}</p>

                <div className="flex justify-between">
                    <p className="text-[16px] leading-[24px] text-[#101828] font-medium">
                        {'VAT'}
                    </p>
                    <p className="text-[16px] leading-[24px] text-[#475467] font-medium">
                        {'Є0.00'}
                    </p>
                </div>

                <div className="flex justify-between mt-3">
                    <p className="text-[16px] leading-[24px] text-[#101828] font-medium">
                        {'Total'}
                    </p>
                    <p
                        className={`text-[16px] leading-[24px]$ text-[#475467] font-medium`}>{`Є ${selected?.serviceType.price}`}</p>
                </div>
            </div>

            <div className="flex p-6 items-center gap-3 border-t border-[#EAECF0]">
                <button
                    className="text-[16px] leading-[24px] text-[#344054] border rounded-[8px] border-[#D0D5DD] h-[50px] font-bold flex-1"
                    onClick={() => setOpenPayment(false)}>
                    {'Cancel'}
                </button>

                <button
                    className={`text-[16px] leading-[24px] ${
                        userBalance >= selected?.serviceType.price
                            ? 'bg-[#69884F] text-[#fff] '
                            : 'bg-[#FFDADA] text-[#EB5757] '
                    }  rounded-[8px]  h-[50px] font-bold flex-1`}
                    onClick={() => onClickPay()}>
                    {userBalance >= selected?.serviceType.price
                        ? 'Proceed'
                        : 'Deposit Amount'}
                </button>
            </div>
        </div>
    )
}

export default ApplyJobPayment
