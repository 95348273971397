import { Menu, Transition } from '@headlessui/react'
import { Notification } from 'iconsax-react'
import { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'
import UserService from 'services/user.service'
import { timeAgo } from 'utils/funcs'

const service = new UserService()

export const NotificationPanel = () => {
    const [notifications, setNotifications] = useState<any>([])
    const navigate = useNavigate()
    const user = useSelector(userSelector)

    useEffect(() => {
        service
            .getNotifications()
            .then(resp => {
                setNotifications(resp.data.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    const unread = notifications.filter(item => !item.read)

    return (
        <Menu as="div" className="relative inline-block z-90">
            <Menu.Button className="mt-2">
                <div className="relative">
                    <Notification className="h-6 w-6" />
                    <div className="bg-n-orange rounded-full p-1 text-sm absolute top-0 right-0 text-white" />
                </div>
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute z-[1000] h-96 overflow-auto -right-28 md:right-0 mt-2 w-[370px] md:w-[450px] origin-top-right drop-shadow focus:outline-none bg-white rounded-xl">
                    <div className="p-5 justify-between items-center gap-4 flex border-b border-zinc-200">
                        <div className="self-stretch justify-start items-center gap-2 inline-flex">
                            <p className="text-zinc-900 font-TTHovesM">
                                Notifications
                            </p>
                            <div className="px-1.5 py-0.5 bg-white rounded-md border border-zinc-300 justify-start items-center gap-1 flex">
                                <div className="w-2 h-2 relative">
                                    <div className="  w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-green-500 rounded-full" />
                                </div>
                                <div className="text-center text-slate-700 text-xs font-TTHovesM">
                                    {unread.length}
                                </div>
                            </div>
                        </div>
                        <Menu.Item>
                            <Link
                                to="/dashboard/chat"
                                className="text-tale-10 font-TTHovesM">
                                Show all
                            </Link>
                        </Menu.Item>
                    </div>
                    <div className="flex flex-col divide-y divide-zinc-200">
                        {notifications.map(item => (
                            <Menu.Item key={item._id}>
                                <div
                                    onClick={() =>
                                        navigate(`/dashboard/chat/${item._id}`)
                                    }
                                    className="flex-col hover:bg-zinc-100 cursor-pointer justify-start items-start gap-2.5 inline-flex p-5">
                                    <div className="flex justify-between items-start w-full">
                                        <div className="grow shrink basis-0 h-10 justify-start items-center gap-3 flex">
                                            <div className="w-2 h-2 relative bg-tale-10 rounded-full" />
                                            <div className="justify-start items-center gap-3 flex">
                                                <div className="w-10 h-10 relative rounded-full">
                                                    <div className="w-10 h-10 rounded-full">
                                                        <img
                                                            alt="avatar"
                                                            src={`${process.env.REACT_APP_SERVER_DOMAIN}/${item?.sender?.profileImage}`}
                                                            className="h-full w-full rounded-full object-cover"
                                                        />
                                                    </div>
                                                    <div className="w-2.5 h-2.5 left-[30px] top-[30px] absolute bg-green-500 rounded-[5px] border border-white" />
                                                </div>
                                                <div className="">
                                                    <p className="text-slate-800 text-sm font-TTHovesM">
                                                        {
                                                            item?.sender
                                                                ?.firstName
                                                        }
                                                    </p>
                                                    <p className="text-zinc-700 text-sm">
                                                        @
                                                        {item?.sender?.userName}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-zinc-500 text-[13px] font-TTHovesM">
                                            {timeAgo(new Date(item?.createdAt))}
                                        </div>
                                    </div>
                                    <p className="pl-5 text-slate-500 font-TTHovesL text-[15px]">
                                        {item?.message}
                                    </p>
                                </div>
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
