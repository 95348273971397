export const userSelector = (s: { user: any }) => s.user.user
// export const balanceSelector = (s: { balance: unknown }) => s.balanceDetails.user

export const authSelector = (s: {
    user: { isAuth: boolean; loader: boolean; error: string }
}) => ({
    isAuth: s.user.isAuth,
    loader: s.user.loader,
    error: s.user.error,
})
