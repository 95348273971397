import React, { useEffect, useState } from 'react'
import { AllChats } from 'components/dashboard/chat/AllChats'
import { socket } from 'utils/socket'
import UserService from 'services/user.service'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'
import { useChatScroll } from 'hooks/useChatScroll'
import { formatTime } from 'utils/funcs'
import { useMessages } from 'hooks/useMessages'

const service = new UserService()

export const Chat = () => {
    const [messages, setMessages] = useState<any>([])
    const [chatId, setChatId] = useState('')
    const [message, setMessage] = useState('')
    const { chats, setChats, loading, fetchData } = useMessages()
    const [selectedChat, setSelectedChat] = useState<any>(null)

    const navigate = useNavigate()
    const ref = useChatScroll(messages)

    const user = useSelector(userSelector)
    // const onlineUsers = useSocket(user._id)

    const { id } = useParams()

    const sortChatsByUpdatedAt = () => {
        return chats.sort(
            (a, b) =>
                (new Date(b.updatedAt) as any) - (new Date(a.updatedAt) as any),
        )
    }

    useEffect(() => {
        if (id) {
            service
                .getSpecificChat(id ?? '')
                .then(resp => {
                    setSelectedChat(resp.data.data)
                    socket.emit('joinChat', resp.data.data._id)
                    setChatId(resp.data.data._id)
                    setMessages(resp.data.data.messages)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [id])

    useEffect(() => {
        socket.on('newMessage', value => {
            const shallowCopy = [...messages]
            shallowCopy.push(value)
            setMessages(shallowCopy)
            const chatIndex = chats.findIndex(chat => chat._id === value.chat)

            if (chatIndex !== -1) {
                // Update the chat's updatedAt field
                chats[chatIndex].updatedAt = new Date().toISOString() // Or use the actual updatedAt value returned from the server

                // Sort the chats array
                const sortedChats = sortChatsByUpdatedAt()

                // Update the state (assuming you're using React)
                setChats(sortedChats)
            }
        })
        if (messages.length > 0) {
            socket.emit('readMessage', messages[messages?.length - 1]?._id)
        }

        socket.on('messsageIsRead', value => {
            console.log('Read Message', value)
        })
    })

    const sendMessage = () => {
        const chatData = {
            chat: chatId,
            content: message,
            sender: user?._id,
        }
        socket.emit('sendMessage', chatData)
        setMessages([...messages, { ...chatData, createdAt: new Date() }])
        setMessage('')

        service
            .sendMessage(chatData)
            .then(() => {
                fetchData()
            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleKeyDown = e => {
        if (e.keyCode === 13 && message.trim()) {
            sendMessage()
        }
    }

    const Proposal = () => {
        return (
            <div className="max-w-md border p-3 border-zinc-200 rounded-md">
                <p className="text-sm text-zinc-600">
                    Your proposal sent for review
                </p>
            </div>
        )
    }

    return (
        <div className="bg-white shadow rounded-lg w-full h-full overflow-auto">
            <div className="flex h-[calc(100vh-104px)]">
                <AllChats
                    setChatId={setChatId}
                    setMessages={setMessages}
                    setSelectedChat={setSelectedChat}
                />

                {/* Messages */}
                {selectedChat ? (
                    <div className="relative h-full max-w-full overflow-auto flex-1 ">
                        {/* User Details */}
                        <div className="sticky flex justify-between bg-white top-0 border-b border-zinc-200 px-5 py-3">
                            <div className="flex gap-4">
                                <div className="h-12 w-12 rounded-full">
                                    <img
                                        alt="profile"
                                        className="h-full w-full object-top rounded-full object-cover"
                                        src={
                                            selectedChat?.participants
                                                ?.profileImage
                                                ? `${process.env.REACT_APP_SERVER_DOMAIN}/${selectedChat.participants?.profileImage}`
                                                : '/assets/images/blank.webp'
                                        }
                                    />
                                </div>
                                <div className="flex items-start gap-2">
                                    <div>
                                        <p className="text-zinc-900 font-TTHovesSB">
                                            {selectedChat?.participants
                                                ?.firstName +
                                                ' ' +
                                                selectedChat?.participants
                                                    ?.lastName}
                                        </p>
                                        <div className="text-slate-600 text-sm">
                                            @
                                            {
                                                selectedChat?.participants
                                                    ?.userName
                                            }
                                        </div>
                                    </div>
                                    <div className="px-2 py-[1px] bg-white rounded-md border shadow-sm border-zinc-300 items-center gap-1.5 inline-flex">
                                        <div className="w-1.5 h-1.5 bg-green-500 rounded-full" />
                                        <p className="text-slate-700 text-[12px] font-TTHovesM">
                                            Online
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="hidden md:flex items-center gap-3">
                                {/*<button className="px-4 py-2.5 text-sm font-TTHovesSB text-slate-700 border border-zinc-200 shadow-sm rounded-lg flex gap-2 items-center">*/}
                                {/*    <Calendar2 className="h-5 w-5" />*/}
                                {/*    <span>Book a session</span>*/}
                                {/*</button>*/}
                                <button
                                    onClick={() =>
                                        navigate(
                                            `/photographer/${selectedChat?.participants?.userName}`,
                                        )
                                    }
                                    className="text-white bg-tale-10 shadow-sm px-4 py-2.5 rounded-lg text-sm font-TTHovesSB">
                                    View profile
                                </button>
                            </div>
                        </div>
                        {/* End User Details */}

                        {/* Messages */}
                        <div
                            ref={ref}
                            className="p-5 flex flex-col gap-6 scrollbar-hidden overflow-auto h-[calc(100%-210px)]">
                            {/* Message 1 */}
                            {messages.map(item => (
                                <>
                                    {item?.sender !== user?._id ? (
                                        <div
                                            key={item._id}
                                            className="self-start flex gap-3">
                                            <div className="h-8 flex-shrink-0 w-8 rounded-full mt-0.5">
                                                <img
                                                    src={
                                                        selectedChat
                                                            ?.participants
                                                            ?.profileImage
                                                            ? `${process.env.REACT_APP_SERVER_DOMAIN}/${selectedChat.participants?.profileImage}`
                                                            : '/assets/images/blank.webp'
                                                    }
                                                    alt="profile"
                                                    className="h-full w-full object-cover object-top rounded-full"
                                                />
                                            </div>
                                            <div className="flex w-auto flex-col gap-1.5">
                                                <div className="flex gap-2 items-center  justify-between">
                                                    <p className="text-slate-700 text-sm font-TTHovesM">
                                                        {
                                                            selectedChat
                                                                ?.participants
                                                                ?.firstName
                                                        }
                                                    </p>
                                                    <p className="text-slate-600 text-xs">
                                                        {formatTime(
                                                            item?.createdAt,
                                                        )}
                                                    </p>
                                                </div>
                                                <p className="flex bg-zinc-100 px-3.5 py-2.5 text-[15px] rounded-tr-lg rounded-bl-lg rounded-br-lg">
                                                    {item?.content}
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="self-end flex gap-3">
                                            {/*<Proposal />*/}
                                            <div className="flex flex-col gap-1.5">
                                                <div className="flex gap-2 items-center justify-between">
                                                    <p className="text-slate-600 text-xs">
                                                        {formatTime(
                                                            item?.createdAt,
                                                        )}
                                                    </p>
                                                    <p className="text-slate-700 text-sm font-TTHovesM">
                                                        {user?.firstName}
                                                    </p>
                                                </div>
                                                <p className="max-w-xl px-3.5 py-2.5 text-[15px] bg-orange-50 rounded-tl-lg rounded-bl-lg rounded-br-lg ">
                                                    {item?.content}
                                                </p>
                                            </div>
                                            <div className="h-8 flex-shrink-0 w-8 rounded-full mt-0.5">
                                                <img
                                                    src={
                                                        user?.profileImage
                                                            ? `${process.env.REACT_APP_SERVER_DOMAIN}/${user?.profileImage}`
                                                            : '/assets/images/blank.webp'
                                                    }
                                                    alt="profile"
                                                    className="h-full w-full object-cover object-top rounded-full"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </>
                            ))}
                            {/* Message 2 */}

                            {/*    <div className="h-8 w-8 rounded-full mt-0.5">*/}
                            {/*        <img*/}
                            {/*            src="/assets/homepage-photographer-1.jpg"*/}
                            {/*            alt="profile"*/}
                            {/*            className="h-full w-full rounded-full"*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/* Message 3 */}
                            {/*<div className="self-end flex gap-3">*/}
                            {/*    <div className="flex flex-col gap-1.5">*/}
                            {/*        <div className="flex justify-between">*/}
                            {/*            <p className="text-slate-600 text-xs">*/}
                            {/*                1hr ago*/}
                            {/*            </p>*/}
                            {/*            <p className="text-slate-700 text-sm font-TTHovesM">*/}
                            {/*                Brabiana Liu*/}
                            {/*            </p>*/}
                            {/*        </div>*/}
                            {/*        <p className="max-w-xl px-3.5 py-2.5 text-[15px] bg-orange-50 rounded-tl-lg rounded-bl-lg rounded-br-lg ">*/}
                            {/*            Greetings Barbiana Liu Hope you having a*/}
                            {/*            good day, lorem ipsum dolor sit amet,*/}
                            {/*            consectetur adipiscing elit, sed do eiusmod*/}
                            {/*            tempor incididunt ut labore et dolore magna*/}
                            {/*            aliqua. Let me know if you are willing to*/}
                            {/*            collab. Cheers*/}
                            {/*        </p>*/}
                            {/*    </div>*/}

                            {/*    <div className="h-8 w-8 rounded-full mt-0.5">*/}
                            {/*        <img*/}
                            {/*            src="/assets/homepage-photographer-1.jpg"*/}
                            {/*            alt="profile"*/}
                            {/*            className="h-full w-full rounded-full"*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/* Message 4 */}
                            {/*<div className="self-end flex gap-3">*/}
                            {/*    <div className="flex flex-col gap-1.5">*/}
                            {/*        <div className="flex justify-between">*/}
                            {/*            <p className="text-slate-600 text-xs">*/}
                            {/*                1hr ago*/}
                            {/*            </p>*/}
                            {/*            <p className="text-slate-700 text-sm font-TTHovesM">*/}
                            {/*                Brabiana Liu*/}
                            {/*            </p>*/}
                            {/*        </div>*/}
                            {/*        <p className="max-w-xl px-3.5 py-2.5 text-[15px] bg-orange-50 rounded-tl-lg rounded-bl-lg rounded-br-lg ">*/}
                            {/*            Greetings Barbiana Liu Hope you having a*/}
                            {/*            good day, lorem ipsum dolor sit amet,*/}
                            {/*            consectetur adipiscing elit, sed do eiusmod*/}
                            {/*            tempor incididunt ut labore et dolore magna*/}
                            {/*            aliqua. Let me know if you are willing to*/}
                            {/*            collab. Cheers*/}
                            {/*        </p>*/}
                            {/*    </div>*/}

                            {/*    <div className="h-8 w-8 rounded-full mt-0.5">*/}
                            {/*        <img*/}
                            {/*            src="/assets/homepage-photographer-1.jpg"*/}
                            {/*            alt="profile"*/}
                            {/*            className="h-full w-full rounded-full"*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/* Message 5 */}
                            {/*<div className="self-end flex gap-3">*/}
                            {/*    <div className="flex flex-col gap-1.5">*/}
                            {/*        <div className="flex justify-between">*/}
                            {/*            <p className="text-slate-600 text-xs">*/}
                            {/*                1hr ago*/}
                            {/*            </p>*/}
                            {/*            <p className="text-slate-700 text-sm font-TTHovesM">*/}
                            {/*                Brabiana Liu*/}
                            {/*            </p>*/}
                            {/*        </div>*/}
                            {/*        <p className="max-w-xl px-3.5 py-2.5 text-[15px] bg-orange-50 rounded-tl-lg rounded-bl-lg rounded-br-lg ">*/}
                            {/*            Greetings Barbiana Liu Hope you having a*/}
                            {/*            good day, lorem ipsum dolor sit amet,*/}
                            {/*            consectetur adipiscing elit, sed do eiusmod*/}
                            {/*            tempor incididunt ut labore et dolore magna*/}
                            {/*            aliqua. Let me know if you are willing to*/}
                            {/*            collab. Cheers*/}
                            {/*        </p>*/}
                            {/*    </div>*/}

                            {/*    <div className="h-8 w-8 rounded-full mt-0.5">*/}
                            {/*        <img*/}
                            {/*            src="/assets/homepage-photographer-1.jpg"*/}
                            {/*            alt="profile"*/}
                            {/*            className="h-full w-full rounded-full"*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/* Message 6 */}
                            {/*<div className="self-start flex gap-3">*/}
                            {/*    <div className="h-8 w-8 rounded-full mt-0.5">*/}
                            {/*        <img*/}
                            {/*            src="/assets/homepage-photographer-1.jpg"*/}
                            {/*            alt="profile"*/}
                            {/*            className="h-full w-full rounded-full"*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*    <div className="flex flex-col gap-1.5">*/}
                            {/*        <div className="flex justify-between">*/}
                            {/*            <p className="text-slate-700 text-sm font-TTHovesM">*/}
                            {/*                Brabiana Liu*/}
                            {/*            </p>*/}
                            {/*            <p className="text-slate-600 text-xs">*/}
                            {/*                1hr ago*/}
                            {/*            </p>*/}
                            {/*        </div>*/}
                            {/*        <p className="max-w-xl bg-zinc-100 px-3.5 py-2.5 text-[15px] rounded-tr-lg rounded-bl-lg rounded-br-lg">*/}
                            {/*            Hey Olivia, Thank you for reaching me out.*/}
                            {/*            Reviewing the details, in the meanwhile you*/}
                            {/*            can also book a call session to discuss the*/}
                            {/*            job further. Thanks*/}
                            {/*        </p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        {/* Messages End */}

                        {/* Form */}
                        <div className="bg-white sticky bottom-0 p-3 h-auto">
                            <div className=" border border-zinc-200 rounded-lg w-full h-full">
                                <textarea
                                    placeholder="Send a message"
                                    value={message}
                                    onKeyDown={handleKeyDown}
                                    onChange={e => setMessage(e.target.value)}
                                    className="appearance-none w-full focus:outline-none resize-none px-3 py-2 text-zinc-900"
                                />
                                <div className="flex px-4 pb-3 place-content-end w-auto items-center gap-4 bg-transparent">
                                    {/*<p className="text-zinc-900 text-[15px] underline font-TTHovesM">*/}
                                    {/*    Send Invoice*/}
                                    {/*</p>*/}
                                    <p
                                        onClick={() =>
                                            navigate(
                                                '/dashboard/photoshoot/new',
                                            )
                                        }
                                        className="cursor-pointer text-zinc-900 text-[15px] underline font-TTHovesM">
                                        Send Proposal
                                    </p>
                                    <button
                                        onClick={sendMessage}
                                        disabled={!message}
                                        className="text-sm disabled:bg-opacity-25 text-white bg-tale-10 rounded-lg px-3 py-1.5 font-TTHovesM">
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Form End */}
                    </div>
                ) : (
                    <div className="h-[calc(100%-150px)] flex-1 flex flex-col items-center justify-center">
                        <div className="h-60 w-60">
                            <img
                                src="/chat.svg"
                                alt="chat"
                                className="h-full w-full"
                            />
                        </div>
                        <p className="font-TTHovesSB text-3xl">
                            Pick where you left off
                        </p>
                        <p className="font-TTHoves text-zinc-500 mt-1">
                            Select a conversation and chat away.
                        </p>
                    </div>
                )}
            </div>
        </div>
    )
}
