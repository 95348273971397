import PhotoCard from './PhotosCard'

const Gallery = ({ data, selectedImages, selectedPhotgrapherImages, slectedService }) => {
    return (
        <div>
            <div className="text-slate-800 text-lg font-TTHovesSB leading-7">
                {slectedService?.name}
            </div>
            <div className="text-slate-600 text-sm">
                Select as many as you like
            </div>

            <div className="flex gap-[14px] flex-wrap mt-5">
                {data?.images.map((item, key) => (
                    <PhotoCard
                        selectedPhotgrapherImages={selectedPhotgrapherImages}
                        photo={item.image}
                        user={item?.userInfo}
                        key={key}
                        setSelectedGallery={selectedImages}
                    />
                ))}
            </div>
        </div>
    )
}

export default Gallery
