import { StarGrayIcon, StarIcon } from 'utils/staticData'

const ProfileReviews = ({ user }) => {
    return (
        <div className="border-t border-b border-[#e5e2e0] py-5  flex flex-col gap-3">
            <span className="font-TTHovesM text-[#131224]">{'Reviews'}</span>

            <div className="relative flex">
                <div className="relative flex-1">
                    <span className="border-l-[1px] border-[#e5e2e0] absolute right-0 top-[20%] bottom-[20%]" />
                    <p className="font-TTHovesM text-[#131224]">
                        {'Total Reviews'}
                    </p>

                    <div className="mt-6">
                        <p className="text-[32px] text-[#131224] leading-9 font-TTHovesM">
                            {'132'}
                        </p>
                        <p className="text-sm text-[#898690]">
                            {'No of reviews this year'}
                        </p>
                    </div>
                </div>

                <div className="relative flex-1">
                    <span className="border-l-[1px] border-[#e5e2e0] absolute right-0 top-[20%] bottom-[20%]" />
                    <div className="w-[159px] mx-auto">
                        <p className="font-TTHovesM text-[#131224]">
                            {'Average Ratings'}
                        </p>

                        <div className="mt-6">
                            <p className="text-[32px] text-[#131224] flex leading-9 items-center gap-2 font-TTHovesM">
                                {'4.9'}
                                <span className="flex gap-1">
                                    <img src={StarIcon} alt="" className="w-5 h-5" />
                                    <img src={StarIcon} alt="" className="w-5 h-5" />
                                    <img src={StarIcon} alt="" className="w-5 h-5" />
                                    <img src={StarIcon} alt="" className="w-5 h-5" />
                                    <img src={StarGrayIcon} alt="" className="w-5 h-5" />
                                </span>
                            </p>
                            <p className="text-sm text-[#898690]">{'Average ratings this year'}</p>
                        </div>
                    </div>
                </div>

                <div className="relative flex-1 flex flex-col gap-1">
                    <span />
                    <div className="w-[205px] mx-auto">
                        <div className="flex items-center gap-[6px]">
                            <img src={StarGrayIcon} className="w-3 h-3" alt="" />
                            <span className="text-sm font-TTHovesM text-[#131224]">{'5'}</span>
                            <span className="w-[150px] h-[5px] bg-[#61B6A2] rounded-[10px]" />
                            <span className="text-sm font-TTHovesM text-[#131224]">{'121'}</span>
                        </div>

                        <div className="flex items-center gap-[6px]">
                            <img src={StarGrayIcon} className="w-3 h-3" alt="" />
                            <span className="text-sm font-TTHovesM text-[#131224]">{'4'}</span>
                            <span className="w-[100px] h-[5px] bg-[#CF83F7] rounded-[10px]" />
                            <span className="text-sm font-TTHovesM text-[#131224]">{'10'}</span>
                        </div>

                        <div className="flex items-center gap-[6px]">
                            <img src={StarGrayIcon} className="w-3 h-3" alt="" />
                            <span className="text-sm font-TTHovesM text-[#131224]">{'3'}</span>
                            <span className="w-[50px] h-[5px] bg-[#EDC057] rounded-[10px]" />
                            <span className="text-sm font-TTHovesM text-[#131224]">{'01'}</span>
                        </div>

                        <div className="flex items-center gap-[6px]">
                            <img src={StarGrayIcon} className="w-3 h-3" alt="" />
                            <span className="text-sm font-TTHovesM text-[#131224]">{'2'}</span>
                            <span className="w-[20px] h-[5px] bg-[#62BCEA] rounded-[10px]" />
                            <span className="text-sm font-TTHovesM text-[#131224]">{'01'}</span>
                        </div>

                        <div className="flex items-center gap-[6px]">
                            <img src={StarGrayIcon} className="w-3 h-3" alt="" />
                            <span className="text-sm font-TTHovesM text-[#131224]">{'1'}</span>
                            <span className="w-[5px] h-[5px] bg-[#E88339] rounded-[10px]" />
                            <span className="text-sm font-TTHovesM text-[#131224]">{'00'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileReviews
