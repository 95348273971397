import { StarGrayIcon, StarIcon } from "utils/staticData"

const Testimonials = ({ user }) => {
    return (
        <div className="flex flex-col gap-[50px]">
            <div className="flex">
                <div className="flex gap-[10px]">
                    <img
                        src={`${process.env.REACT_APP_SERVER_DOMAIN}/${user?.profileImage}`}
                        className="min-w-[60px] h-[60px] object-cover rounded-full"
                        alt=""
                    />
                    <div className="flex flex-col w-[250px]">
                        <span className="font-TTHovesM text-lg text-dark-8">{'Towhidur Rahman'}</span>
                        <span className="text-[13px] text-[#131224]">
                            Total Spend: <span className="font-TTHovesSB">Є1000</span>
                        </span>
                    </div>
                </div>
                <div>
                    <div className="flex items-center gap-4">
                        <span className="flex gap-1">
                            <img src={StarIcon} alt="" className="w-5 h-5" />
                            <img src={StarIcon} alt="" className="w-5 h-5" />
                            <img src={StarIcon} alt="" className="w-5 h-5" />
                            <img src={StarIcon} alt="" className="w-5 h-5" />
                            <img src={StarGrayIcon} alt="" className="w-5 h-5" />
                        </span>
                        <span className="text-sm text-[#898690]">{'24-10-2024'}</span>
                    </div>
                    <p className="mt-[18px]">
                        Brabiana Liu is an exceptional photographer with 10
                        years of experience, and working with her was a
                        fantastic experience. She perfectly captured our vision
                        with stunning photos, demonstrating her remarkable
                        technical skills and artistic eye. Brabiana's
                        professionalism, creativity, and attention to detail
                        made the entire process smooth and enjoyable. She has a
                        unique ability to make subjects feel comfortable,
                        resulting in beautiful and meaningful images.
                    </p>
                </div>
            </div>

            <div className="flex">
                <div className="flex gap-[10px]">
                    <img
                        src={`${process.env.REACT_APP_SERVER_DOMAIN}/${user?.coverImage}`}
                        className="min-w-[60px] h-[60px] object-cover rounded-full"
                        alt=""
                    />
                    <div className="flex flex-col w-[250px]">
                        <span className="font-TTHovesM text-lg text-dark-8">{'Nikolas Gibbons'}</span>
                        <span className="text-[13px] text-[#131224]">
                            Total Spend: <span className="font-TTHovesSB">Є3000</span>
                        </span>
                    </div>
                </div>
                <div>
                    <div className="flex items-center gap-4">
                        <span className="flex gap-1">
                            <img src={StarIcon} alt="" className="w-5 h-5" />
                            <img src={StarIcon} alt="" className="w-5 h-5" />
                            <img src={StarIcon} alt="" className="w-5 h-5" />
                            <img src={StarIcon} alt="" className="w-5 h-5" />
                            <img src={StarGrayIcon} alt="" className="w-5 h-5" />
                        </span>
                        <span className="text-sm text-[#898690]">{'24-10-2024'}</span>
                    </div>
                    <p className="mt-[18px]">
                        Brabiana Liu is an exceptional photographer with 10
                        years of experience, and working with her was a
                        fantastic experience. She perfectly captured our vision
                        with stunning photos, demonstrating her remarkable
                        technical skills and artistic eye. Brabiana's
                        professionalism, creativity, and attention to detail
                        made the entire process smooth and enjoyable. She has a
                        unique ability to make subjects feel comfortable,
                        resulting in beautiful and meaningful images.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Testimonials
