import React, { Fragment, useEffect, useState } from 'react'
import {
    Add,
    ArrowRight2,
    GalleryAdd,
    Home2,
    RefreshCircle,
} from 'iconsax-react'
import { useNavigate } from 'react-router-dom'
import UserService from 'services/user.service'

const service = new UserService()

export const ClientGalleriesList = () => {
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [galleries, setGalleries] = useState<any>([])
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        service
            .clientGalleries()
            .then(resp => {
                setGalleries(resp.data.data)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const openGallery = id => {
        // if (index === 0) {
        // setPasswordModal(true)
        // } else {
        navigate(`/dashboard/client-gallery/${id}`)
        // }
    }

    return (
        <Fragment>
            <div className="flex items-center justify-between">
                <div className="hidden items-center gap-2 md:flex">
                    <Home2 className="h-5 w-5" />
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div
                        onChange={() => navigate(-1)}
                        className="cursor-pointer px-2 py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                        Galleries
                    </div>
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                        Client Galleries
                    </div>
                </div>
            </div>
            <div className="bg-white rounded-xl border border-zinc-200 mt-6 p-5 w-full">
                <div className="border-b border-zinc-200 pb-5">
                    <p className="text-zinc-900 text-lg font-TTHovesM">
                        Client Galleries
                    </p>
                    {/*<p className="text-slate-600 text-sm leading-tight">*/}
                    {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit,*/}
                    {/*    sed do eiusmod tempor incididunt ut labore et dolore*/}
                    {/*    magna aliqua.*/}
                    {/*</p>*/}
                </div>

                <div className="flex flex-wrap gap-4 mt-4">
                    {loading ? (
                        <RefreshCircle className="text-tale-10 mx-auto my-10 size-10 animate-spin" />
                    ) : (
                        galleries.map(item => {
                            return (
                                <div
                                    key={item._id}
                                    className="w-full cursor-pointer md:w-[245px] h-[270px] relative overflow-hidden rounded-xl">
                                    {item.images.length > 0 ? (
                                        <img
                                            src={`${process.env.REACT_APP_SERVER_DOMAIN}/${item.images[0]}`}
                                            alt="gallery1"
                                            onClick={() =>
                                                openGallery(item._id)
                                            }
                                            className="h-full w-full object-cover cursor-pointer"
                                        />
                                    ) : (
                                        <div
                                            onClick={() =>
                                                openGallery(item._id)
                                            }
                                            className="w-full cursor-pointer md:w-[245px] h-[270px] overflow-hidden flex flex-col items-center justify-center bg-zinc-200 rounded-xl border-2 border-dashed border-zinc-200">
                                            <div className="text-slate-800 text-sm mt-2">
                                                No Photos in Gallery
                                            </div>
                                        </div>
                                    )}
                                    <div className="absolute bottom-1.5 px-3 py-2 flex items-center justify-between w-full">
                                        <p className="text-white font-TTHovesM">
                                            {item.title}
                                        </p>
                                        <div className="font-TTHovesM text-center text-white text-xs px-1.5 py-0.5 bg-white bg-opacity-40 rounded-md shadow border border-zinc-400">
                                            {item.images.length}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    )}
                </div>
            </div>
        </Fragment>
    )
}
