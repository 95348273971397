import { Modal } from 'components/common/Modal'
import { Profile2User } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import { useClients } from 'hooks/useClients'
import UserService from 'services/user.service'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import { UserSelect } from 'components/form/UserSelect'
import { TextArea } from 'components/form/Input/TextArea'
import { useNotifications } from 'hooks/useNotification'

const service = new UserService()

export const ShareGalleryModal = ({ id, showModal, setShowModal }) => {
    const [state, setState] = useState({
        clientEmail: '',
        userId: '',
        message: '',
    })
    const [existing, setExisting] = useState(false)
    const [clientId, setClientId] = useState('')
    const { clients } = useClients()
    const [projects, setProjects] = useState([])

    const { successMessage } = useNotifications()
    const user = useSelector(userSelector)

    const handleShareGallery = () => {
        const email = clients.find(item => item._id === clientId).email
        service
            .shareGallery(id, { userId: user?._id, clientEmail: email })
            .then(resp => {
                setShowModal(false)
                successMessage('Gallery shared successfully')
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        service
            .getBookings()
            .then(resp => {
                setProjects(resp.data.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    return (
        <Modal
            width="w-full md:w-2/3 2xl:w-1/3"
            showModal={showModal}
            setShowModal={setShowModal}
            description=""
            title="Share Gallery"
            footerButton="Share Gallery"
            handleClickPrimary={handleShareGallery}
            headerIcon={<Profile2User />}>
            {/* Form */}
            <form className="p-5">
                <div className="flex flex-col gap-3">
                    <div>
                        <label className="text-sm text-zinc-700 font-TTHovesM">
                            Send to *
                        </label>
                        <ReactSelect
                            value={''}
                            onChange={() => setExisting(!existing)}
                            options={[
                                { value: 'existing', label: 'Existing Client' },
                            ]}
                            placeholder={'Send to'}
                        />
                    </div>
                    <div>
                        <label className="text-sm text-zinc-700 font-TTHovesM">
                            Select Client *
                        </label>
                        <UserSelect
                            value={clientId}
                            selectLabel={false}
                            onChange={value => setClientId(value)}
                            options={clients.map(item => ({
                                ...item,
                                value: item._id,
                                label: `${item?.firstName} ${item?.lastName}`,
                            }))}
                            placeholder={'Select Client'}
                        />
                    </div>
                    {/*<div>*/}
                    {/*    <label className="text-sm text-zinc-700 font-TTHovesM">*/}
                    {/*        Select Project **/}
                    {/*    </label>*/}
                    {/*    <ReactSelect*/}
                    {/*        value={''}*/}
                    {/*        onChange={() => console.log()}*/}
                    {/*        options={[]}*/}
                    {/*        placeholder={''}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div>
                        <label className="text-sm text-zinc-700 font-TTHovesM">
                            Email Message
                        </label>
                        <TextArea
                            value={state.message}
                            onChange={value =>
                                setState({
                                    ...state,
                                    message: value,
                                })
                            }
                        />
                    </div>
                </div>
            </form>
            <hr className="text-zinc-200 w-full " />
            {/* Form End */}
        </Modal>
    )
}
