import { useNotifications } from 'hooks/useNotification'
import { Add, LocationMinus } from 'iconsax-react'
import { trim } from 'lodash'
import React, { memo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import UserService from 'services/user.service'
import { PhotographerData } from 'utils/interfaces/invitePhotographers'

const service = new UserService()

const InvitePhotgrapherTile: React.FC<{ data: PhotographerData }> = ({
    data,
}) => {
    const [loading, setLoading] = useState(false)
    const { pathname, search } = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const { successMessage, errorMessage } = useNotifications()

    const sendInvite = async (id: string) => {
        setLoading(true)
        try {
            await service.invitePhotographer(params?.quoteId || '', {
                photographer: id,
            })
            successMessage('Invitation Send successfully')
            navigate(pathname + search)
        } catch (error) {
            console.log(error)
            errorMessage('Something went wrong!')
        }
        setLoading(false)
    }

    return (
        <div className="flex gap-4 items-center border-b border-primary py-[26px] relative">
            {/* Left Image Side */}
            <div className="min-w-fit">
                <img
                    alt="profile"
                    src={`${process.env.REACT_APP_SERVER_DOMAIN}/${data?.profileImage}`}
                    className="h-[81px] w-[81px] rounded-full object-cover"
                />
            </div>

            {/* Right Content Side */}
            <div className="relative">
                <p
                    onClick={() => navigate(`/photographer/${data?.userName}`)}
                    className="cursor-pointer font-TTHovesM hover:text-tale-10 text-lg leading-[24px] text-dark-8 capitalize">{`${data?.firstName} ${data?.lastName}`}</p>
                <p className="text-[13px] leading-[19px] text-dark-3 flex gap-[7px] mb-[7px] mt-[5px] capitalize">
                    <LocationMinus size={16} />
                    {data.location}
                </p>
                <div className="flex gap-2 flex-wrap">
                    <span className="capitalize text-[13px] leading-[16px] tracking-[0.2px] min-w-fit font-TTHovesM border border-primary rounded text-tale-10 py-[7px] px-[13px]">
                        {data.businessName}
                    </span>

                    <span className="py-[7px] px-[13px] text-[13px] leading-[16px] rounded bg-[#EBF1FD] min-w-fit">
                        {trim(data.currencySymbol)}
                        {`${data?.servicePreferences?.hourlyRate}/hr`}
                    </span>

                    {data?.sessions.map((item, index) => (
                        <span
                            className="text-[13px] leading-[16px] capitalize tracking-[0.2px] min-w-fit border border-primary rounded text-dark-9 py-[7px] px-[13px]"
                            key={index}>
                            {item?.session?.name}
                        </span>
                    ))}
                </div>
            </div>

            <button
                className="flex gap-3 absolute right-0 top-[35px] text-[14px] leading-[20px] tracking-[0.2px] font-TTHovesM border border-primary rounded-lg text-dark-9 py-[7px] px-[13px]"
                type="button"
                onClick={() => !loading && sendInvite(data._id)}>
                <Add size={20} />
                {loading ? 'Sending Invite...' : 'Invite Photographer'}
            </button>
        </div>
    )
}

export default memo(InvitePhotgrapherTile)
