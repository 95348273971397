import { useCallback, useEffect, useState } from 'react'
import UserService from 'services/user.service'

const service = new UserService()

export const useGallery = () => {
    const [galleries, setGalleries] = useState<any>([])
    const [loading, setLoading] = useState(false)

    const fetchData = useCallback(() => {
        setLoading(true)
        service
            .getGalleries()
            .then(resp => {
                setGalleries(resp.data.data)
            })
            .catch(error => {
                console.log('Gallery', error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [])

    return {
        galleries,
        setGalleries,
        loading,
    }
}
