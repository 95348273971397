import React from 'react'
import { AppliedPhotographerListInterface } from 'utils/interfaces/invitePhotographers'
import AppliedPhotographerTile from './AppliedPhotographerTile'

const AppliedPhotographerList: React.FC<AppliedPhotographerListInterface> = ({
    list,
    description = '',
    selected,
}) => (
    <div>
        <h4 className="text-base leading-[21px] text-dark-8 mb-[15px] mt-[34px]">
            {'Applied Photographers'}
        </h4>

        <div className="flex flex-wrap gap-5">
            {list.map((photographer, index) => (
                <div className="w-full md:w-[calc(100%/2-10px)]" key={index}>
                    <AppliedPhotographerTile
                        selected={selected}
                        data={{ ...photographer, description }}
                    />
                </div>
            ))}
        </div>
    </div>
)

export default AppliedPhotographerList
