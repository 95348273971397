import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useNotifications } from 'hooks/useNotification'
import React, { useState } from 'react'
import PaymentSuccess from './success'
import UserService from 'services/user.service'
import { useNavigate } from 'react-router-dom'

const service = new UserService()

const CheckoutForm: React.FC<{
    amount: number
    saveIdsofPayments: { paymentIntentId: string, userId: string }
    onBack?: (e) => void
    onSuccess?: (e) => void
}> = ({ amount, saveIdsofPayments, onBack, onSuccess }) => {
    const stripe = useStripe()
    const elements = useElements()
    const { successMessage, errorMessage } = useNotifications()
    const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false)
    const [done, setDone] = useState(false)
    const navigate = useNavigate()

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            return
        }

        setIsPaymentLoading(true)

        const result = await stripe.confirmPayment({
            elements,
            redirect: 'if_required',
        })

        const payload = {
            paymentIntentId: saveIdsofPayments?.paymentIntentId,
            paymentMethodId: result?.paymentIntent?.payment_method,
            userId: saveIdsofPayments?.userId,
        }

        try {
            await service.confirmPayment(payload)
        } catch (error) {
            console.log(error)
        }

        setIsPaymentLoading(false)

        if (result.error) {
            // Show error to your customer (e.g., insufficient funds, incorrect details)
            errorMessage(result.error.message)
        } else {
            // Payment succeeded, redirect or show a success message.
            if (
                result?.paymentIntent &&
                result.paymentIntent.status === 'succeeded'
            ) {
                setDone(true)
                successMessage('Payment successful!')

                navigate("/dashboard/wallet")

                if (onSuccess) onSuccess(result)
            }
        }
    }

    return (
        <form onSubmit={handleSubmit} className="w-[421px] max-w-[100%]">
            <PaymentElement />
            {done ? <PaymentSuccess /> : undefined}
            {/* {errorMessage && <div className="text-red-500 mt-4">{errorMessage}</div>} */}
            <button
                disabled={!stripe || isPaymentLoading}
                className={`mt-4 p-2 h-12 rounded disabled:opacity-50 ${done ? 'bg-tale-10' : 'bg-tale-10'
                    } text-white w-full text-lg font-medium`}>
                {isPaymentLoading
                    ? 'Processing...'
                    : done
                        ? 'Payment Success'
                        : `Pay Є ${amount}.0`}
            </button>
        </form>
    )
}

export default CheckoutForm
