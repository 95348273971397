import { DropdownIndicator } from 'components/create-profile/SetupProfile'
import { Input } from 'components/form/Input/Input'
import React, { memo, useEffect, useState } from 'react'
import TimezoneSelect from 'react-timezone-select'
import DayDateTime from './DayDateTime'
import { initWeekDays } from 'utils/availabilityData'
import UserService from 'services/user.service'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../../redux/selectors/user'
import { useLocation } from 'react-router-dom'

const service = new UserService()

const Form: React.FC<{ getFromData: (e) => void; validation?: boolean }> = ({
    getFromData,
    validation,
}) => {
    const [formData, setFormData] = useState(initWeekDays)
    const [weekDays, setWeekDays] = useState(initWeekDays.weekDays)
    const user = useSelector(userSelector)
    const route = useLocation()

    console.log('====', user)

    useEffect(() => {
        if (getFromData) getFromData({ ...formData, weekDays })
    }, [formData, weekDays])

    useEffect(() => {
        const preFetch = async () => {
            setWeekDays([])
            try {
                const res = await service.getAvailability(user._id)
                setFormData({ ...res.data?.data?.availabilityPreferences, timezone: user.timezone })
                setWeekDays(res.data?.data?.availabilityPreferences?.weekDays)
            } catch (error) {
                console.log(error)
            }
        }

        if (route.pathname === '/dashboard/edit-availability') preFetch()
    }, [route])

    return (
        <div className="flex gap-[32px] flex-wrap">
            {/* Left Side */}
            <div className="min-w-[732px] border-r border-primary pr-[32px] flex flex-col gap-3">
                <Input
                    type="text"
                    placeholder="Title"
                    label="Title"
                    onChange={({ target }) =>
                        setFormData({ ...formData, title: target.value })
                    }
                    value={formData.title}
                    error={validation && !formData.title}
                />

                <DayDateTime
                    weekDays={weekDays}
                    setWeekDays={setWeekDays}
                    validation={validation}
                />
            </div>

            {/* Right Side */}
            <div className="min-w-[calc(100%-764px)]">
                <div className="relative">
                    <label className="absolute -top-3 left-4 bg-creame-1 z-10 text-gray">
                        Time Zone
                    </label>
                    <TimezoneSelect
                        value={formData.timezone}
                        onChange={(data: any) =>
                            setFormData({ ...formData, timezone: data?.value })
                        }
                        isDisabled
                        className="react-select"
                        name="timezone"
                        placeholder="Select time zone"
                        components={{ DropdownIndicator }}
                        classNamePrefix="react-select"
                    />
                </div>
            </div>
        </div>
    )
}

export default memo(Form)
