import React, { createContext, useState, useEffect } from 'react'
import { socket } from 'utils/socket'
import { useSelector } from 'react-redux'
import { userSelector } from '../redux/selectors/user'
import CustomAlert from 'components/common/Alert'

interface NotificationContextType {
    notifications: Notification[]
    setNotifications: (notification: Notification) => void
}

export const NotificationContext = createContext<
    NotificationContextType | undefined
>(undefined)

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState<any>([])
    const [notification, setNotification] = useState({
        title: '',
        message: '',
    })
    const [visible, setVisible] = useState(false)

    const user = useSelector(userSelector)

    useEffect(() => {
        // Listen for 'notification' events from the server
        if (user) {
            socket.emit('user_connected', user?._id)
            socket.on('new_notification', data => {
                setNotification({
                    ...data,
                })
                setVisible(true)
            })
        }

        // Clean up on unmount
        return () => {
            socket.off('new_notification')
        }
    }, [])

    return (
        <NotificationContext.Provider
            value={{ notifications, setNotifications }}>
            {children}
            <CustomAlert
                title={notification.title}
                message={notification.message}
                visible={visible}
                setVisible={setVisible}
            />
        </NotificationContext.Provider>
    )
}
