import React, { useCallback, useEffect, useState } from 'react'
import { pdfjs } from 'react-pdf'
import classNames from 'classnames'
import { Add, RefreshCircle, Trash } from 'iconsax-react'
import UserService from 'services/user.service'
import { useNotifications } from 'hooks/useNotification'
import { ConfirmModal } from 'components/common/ConfirmModal'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

const service = new UserService()
export const ClientGuide = ({ tab }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [id, setId] = useState('')

    const { successMessage } = useNotifications()

    const fetchData = useCallback(() => {
        setLoading(true)
        service
            .getGuide()
            .then(res => {
                setData(res.data.data)
                console.log('Rsp', res)
            })
            .catch(error => {
                console.log('Client Guide Error', error)
                setData([])
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [])

    const handleUploadGuide = e => {
        const file = e.target.files[0]
        setLoading(true)

        service
            .addClientGuide(file)
            .then(() => {
                fetchData()
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const deleteClientGuide = () => {
        service
            .deleteGuide(id)
            .then(() => {
                successMessage('Client Guide deleted Successfully')
                setIsOpen(false)
                setId('')
                fetchData()
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div
            className={classNames(
                'bg-white rounded-xl border border-zinc-200 mt-5 p-5 w-full',
                {
                    hidden: !tab,
                },
            )}>
            <div className="border-b border-zinc-200 pb-5 flex flex-col md:flex-row gap-3 md:gap-0 items-center justify-between">
                {/*<div className="">*/}
                <p className="text-zinc-900 text-lg font-TTHovesM">
                    Client Guides
                </p>
                {/*<p className="text-slate-600 text-sm leading-tight">*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit,*/}
                {/*    sed do eiusmod tempor incididunt ut labore et dolore*/}
                {/*    magna aliqua.*/}
                {/*</p>*/}
                {/*</div>*/}
                <button
                    type="button"
                    className="w-full md:w-auto px-4 py-2.5 bg-tale-10 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                    <Add className="text-white h-5 w-5" />
                    <span className="text-white text-sm font-TTHovesM ">
                        Upload new
                    </span>
                </button>
            </div>

            <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
                {loading ? (
                    <div className="h-full flex items-center justify-center">
                        <RefreshCircle className="text-tale-10 mx-auto size-10 animate-spin" />
                    </div>
                ) : (
                    <label
                        htmlFor="file"
                        className="bgPattern cursor-pointer bg-no-repeat bg-center relative h-80 flex flex-col items-center justify-center border-2 border-dashed border-zinc-200 rounded-lg mt-8">
                        <div className="w-44">
                            <img
                                alt="no"
                                src="/assets/upload.png"
                                className="h-full w-full object-contain"
                            />
                        </div>
                        <p className="text-zinc-600 text-sm mt-2">
                            Click or Drag to upload
                        </p>
                        <input
                            id="file"
                            type="file"
                            onChange={handleUploadGuide}
                            accept="application/pdf, application/msword"
                            className="hidden"
                        />
                    </label>
                )}
                {/*<div className="h-80 w-full md:w-72 border-2 border-dashed border-zinc-200 rounded-lg overflow-hidden">*/}
                {/*    <img*/}
                {/*        alt="no"*/}
                {/*        src="/assets/homepage-photographer-1.jpg"*/}
                {/*        className="h-full w-full object-cover rounded-lg "*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div className="h-80 w-full md:w-72 border-2 border-dashed border-zinc-200 rounded-lg overflow-hidden">*/}
                {/*    <img*/}
                {/*        alt="no"*/}
                {/*        src="/assets/homepage-photographer-2.jpg"*/}
                {/*        className="h-full w-full object-cover rounded-lg "*/}
                {/*    />*/}
                {/*</div>*/}
                {data?.map((item: any) => (
                    <div key={item._id} className="h-80 w-full md:w-96 ">
                        <button
                            type="button"
                            onClick={() => {
                                setId(item._id)
                                setIsOpen(true)
                            }}
                            className="flex justify-between w-full py-1">
                            <div />
                            <Trash color="red" />
                        </button>
                        <div className="h-80 border-2 border-dashed border-zinc-200 rounded-lg overflow-hidden">
                            <iframe
                                height="100%"
                                width="100%"
                                className="rounded-xl"
                                src={`${process.env.REACT_APP_SERVER_DOMAIN}/${item.clientGuide}`}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <ConfirmModal
                onConfirm={deleteClientGuide}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                title="Delete Guide?"
                description="Guide will be deleted permanently."
            />
        </div>
    )
}
