import ProfileTopHeader from 'components/dashboard/client-profile/top-header'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import ProfileDescription from 'components/dashboard/client-profile/description'
import ProfileChoices from 'components/dashboard/client-profile/choices'
import ProfileReviews from 'components/dashboard/client-profile/reviews'
import Testimonials from 'components/dashboard/client-profile/testimonials'

const ClientProfile = () => {
    const user = useSelector(userSelector)

    console.log("=====", user)
    return (
        <div>
            <div>
                <ProfileTopHeader user={user} />
            </div>

            <div className="mt-[57px] max-w-full w-[825px] mx-10">
                <ProfileDescription user={user} />
            </div>

            <div className="mt-6 mx-10">
                <ProfileChoices user={user} />
            </div>

            <div className="mt-[57px] max-w-full w-[825px] mx-10">
                <ProfileReviews user={user} />
            </div>

            <div className="mt-[57px] max-w-full w-[935px] mx-10">
                <Testimonials user={user} />
            </div>
        </div>
    )
}

export default ClientProfile
