const ProfileDescription = ({ user }) => {
    return (
        <div className="border-t border-b border-[#e5e2e0] py-5  flex flex-col gap-3">
            <span className="font-TTHovesM text-[#131224]">
                About {`${user?.firstName} ${user?.lastName}`}
            </span>
            <div className="flex flex-col gap-6 w-[675px]">
                <p className="text-dark-9">
                    With a decade of professional experience, Brabiana Liu has
                    established herself as a highly skilled and innovative
                    photographer. Her journey began with a passion for capturing
                    the beauty of the world through a lens, which has since
                    evolved into a distinguished career marked by creativity,
                    dedication, and an eye for detail.
                </p>
                <p className="text-dark-9">
                    I spent the last 10 years honing her craft across various
                    photography genres, including portrait, wedding, event, and
                    commercial photography. Her diverse portfolio showcases her
                    ability to adapt to different styles and settings,
                    consistently consistently consistently delivering stunning
                    results that expectations.
                </p>
            </div>
        </div>
    )
}

export default ProfileDescription
