import { Stripe } from "utils/staticData"

const PaymentSuccess = () => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-[#000000bd] z-[50]">
            <img src={Stripe} alt="" className="w-[250px] shadow-[0_0_12px_1px_rgba(0,0,0,0.07)] rounded-xl" />
        </div>
    )
}

export default PaymentSuccess
