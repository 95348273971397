import { PhotographerType } from 'components/landing/photographer/PhotographerType'
import { StatusBadge } from 'components/landing/photographer/StatusBadge'
import { Location } from 'iconsax-react'
import { FavouriteButton } from 'components/landing/photographer/FavouriteButton'
import { StatusColorMap } from 'utils/maps'
import { Link } from 'react-router-dom'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

// import required modules
import { Navigation, Mousewheel, Keyboard } from 'swiper/modules'

export const ImageCard = ({
    avatar,
    name,
    location,
    image,
    userName,
    id,
    status,
    type,
    price,
    tier,
    skills,
    portfolio,
    currencySymbol,
}) => {
    const mergedImages = portfolio?.reduce(
        (acc, obj) => acc.concat(obj.images),
        [],
    )
    return (
        <div className="border-0 md:border-r md:border-b border-zinc-200 md:p-8">
            <div className="flex items-start justify-between">
                <div className="flex items-center gap-3">
                    <div className="w-16 h-16 relative">
                        <img
                            className="h-full w-full border-[3px] border-brown-10 object-cover object-top rounded-full"
                            src={avatar ? avatar : '/assets/images/blank.webp'}
                            alt=""
                        />
                        {/*<p className="text-white text-[10px] bg-brown-10 px-1 py-0.5 font-semibold absolute -bottom-2 left-[26%] uppercase leading-3 tracking-tight rounded-sm">*/}
                        {/*    {tier}*/}
                        {/*</p>*/}
                    </div>
                    <div className="flex flex-col gap-1">
                        <div className="flex gap-2">
                            <PhotographerType
                                type={`${tier ?? ''} Photographer`}
                            />
                            <StatusBadge
                                text={status ? 'Available' : 'Offline'}
                                type={
                                    StatusColorMap[
                                        status ? 'Available' : 'Dead'
                                    ]
                                }
                            />
                        </div>
                        <Link to={`/photographer/${userName}`}>
                            <p className="text-slate-800 text-lg font-TTHovesSB cursor-pointer hover:text-brown-10">
                                {name}
                            </p>
                        </Link>
                        {location && (
                            <div className="flex items-center gap-2 -ml-0.5">
                                <Location className="w-5 h-5" />
                                <p className="text-slate-500 text-sm font-semibold leading-tight">
                                    {location?.slice(0, 40)}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <FavouriteButton />
            </div>
            <div className="flex flex-wrap gap-3 mt-3">
                {price && (
                    <div className="px-4 py-2.5 bg-indigo-50 rounded-[7px] ">
                        <p className="text-center text-zinc-800 text-sm font-normal ">
                            {currencySymbol}
                            {price}
                        </p>
                    </div>
                )}
                {skills.map(skill => (
                    <div
                        key={skill}
                        className="px-4 py-2.5 bg-white rounded-[7px] border border-slate-300">
                        <p className="text-center text-zinc-900 text-sm">
                            {skill}
                        </p>
                    </div>
                ))}
            </div>
            <Swiper
                navigation={true}
                mousewheel={true}
                keyboard={true}
                modules={[Navigation, Mousewheel, Keyboard]}
                className="mySwiper">
                {mergedImages?.map(item => (
                    <SwiperSlide key={item}>
                        <div className="mt-4 h-72 w-full">
                            <img
                                className="h-full w-full object-cover rounded-lg"
                                alt=""
                                src={`${process.env.REACT_APP_SERVER_DOMAIN}/${item}`}
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
