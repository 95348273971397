import { IconGlobe } from 'css/icons/icons'
import React, { memo } from 'react'
import moment from 'moment'
import { userSelector } from '../../../../redux/selectors/user'
import { useSelector } from 'react-redux'

const ViewAvailability: React.FC<{ data: any }> = ({ data }) => {
    const user = useSelector(userSelector)

    console.log(user)
    return (
        <div className="rounded-[10px] border border-zinc-200 p-5 my-5">
            <div className="flex flex-col-reverse gap-6 md:gap-0 md:flex-row items-start justify-between">
                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-4">
                        <p className="text-slate-800 font-TTHovesM capitalize">
                            {data?.title}
                        </p>
                        <p className="text-emerald-500 font-TTHovesM text-xs px-3.5 py-[5px] bg-emerald-500 bg-opacity-10 rounded border border-emerald-500">
                            Default
                        </p>
                    </div>

                    {data?.weekDays.map((items: any) => (
                        <div className="inline-flex gap-3 mt-3" key={items.day}>
                            <p className="text-gray-600 text-[15px] font-TTHovesM w-28">
                                {items.day}
                            </p>
                            <div className="flex flex-col gap-1">
                                {items.workingHours.map((item: any, index) =>
                                    item.from ? (
                                        <div key={index} className="flex">
                                            <p className="text-gray-600 text-[15px] font-TTHovesM w-20">
                                                {item?.from}
                                                {/*{moment(item.from, 'HH:mm').format(*/}
                                                {/*    'hh:mm A',*/}
                                                {/*)}*/}
                                            </p>
                                            <p className="text-gray-600 text-[15px] font-TTHovesM w-6">
                                                {'-'}
                                            </p>
                                            <p className="text-gray-600 text-[15px] font-TTHovesM w-20">
                                                {item?.to}
                                                {/*{moment(item.to, 'HH:mm').format(*/}
                                                {/*    'hh:mm A',*/}
                                                {/*)}*/}
                                            </p>
                                        </div>
                                    ) : (
                                        'Close'
                                    ),
                                )}
                            </div>
                        </div>
                    ))}

                    <div className="flex items-center gap-2 mt-4">
                        <IconGlobe />
                        <p className="text-slate-800 text-sm font-TTHovesM">
                            {user?.timezone}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(ViewAvailability)
