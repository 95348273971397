import { useCallback, useEffect, useState } from 'react'
import UserService from 'services/user.service'
import { useSelector } from 'react-redux'
import { userSelector } from '../redux/selectors/user'

const service = new UserService()

export const useClients = () => {
    const [clients, setClients] = useState<any>([])

    const [loading, setLoading] = useState(false)
    const user = useSelector(userSelector)

    const fetchData = useCallback(() => {
        setLoading(true)
        service
            .getMyContacts()
            .then(resp => {
                // setClients(resp.data.data)
                setClients(
                    resp.data.data.filter(item => item._id !== user?._id),
                )
            })
            .catch(error => {
                console.log({ error })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [])

    return {
        loading,
        setClients,
        clients,
    }
}
