import { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
    ArrowRight,
    HambergerMenu,
    Home2,
    SearchNormal,
    Setting2,
} from 'iconsax-react'
import { Button } from 'components/form/Button/Button'
import { IconGlobe } from '../../css/icons/icons'
import { useSelector } from 'react-redux'
import { authSelector, userSelector } from '../../redux/selectors/user'
import { Menu, Transition } from '@headlessui/react'
import { logOut } from '../../redux/actions/auth'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import classNames from 'classnames'

export const Header = () => {
    const [locale, setLocale] = useState('eng')
    const { isAuth } = useSelector(authSelector)
    const user = useSelector(userSelector)
    const dispatch = useThunkDispatch()
    const [current, setCurrent] = useState(1)
    const navigate = useNavigate()

    useEffect(() => {
        setLocale('eng')
    }, [])

    return (
        <header className="sticky top-0 border-b border-black-10 md:p-0 z-50 bg-creame-1">
            <nav className="flex items-center p-2 lg:px-10 w-full mx-auto  ">
                <div className="flex items-center gap-4 md:justify-evenly h-full md:w-[40%] p-2">
                    <Link to="/dashboard" className=" w-32">
                        <img
                            src="/SUURA_24_Logo_01.png"
                            className="h-full w-full object-contain"
                            alt="logo"
                        />
                    </Link>

                    <div className="hidden md:flex justify-evenly items-center w-full">
                        {/*<Menu as="div" className="relative  z-[1000]">*/}
                        {/*    {({ open }) => (*/}
                        {/*        <>*/}
                        {/*            <Menu.Button className="max-w-xs gap-2 flex items-center focus:outline-none">*/}
                        {/*                <p className="text-dark-5 font-TTHovesM  text-left">*/}
                        {/*                    Features for photographers*/}
                        {/*                </p>*/}
                        {/*            </Menu.Button>*/}
                        {/*            <Transition*/}
                        {/*                show={open}*/}
                        {/*                as={Fragment}*/}
                        {/*                enter="transition ease-out duration-200"*/}
                        {/*                enterFrom="transform opacity-0 scale-95"*/}
                        {/*                enterTo="transform opacity-100 scale-100"*/}
                        {/*                leave="transition ease-in duration-100"*/}
                        {/*                leaveFrom="transform opacity-100 scale-100"*/}
                        {/*                leaveTo="transform opacity-0 scale-95">*/}
                        {/*                <Menu.Items className="absolute w-[600px]  mt-3 overflow-hidden bg-white rounded-lg border border-zinc-100 shadow-sm">*/}
                        {/*                    <div className="flex divide-x divide-zinc-200 z-[1000] overflow-hidden gap-4 z p-4">*/}
                        {/*                        <div className="flex flex-col gap-3">*/}
                        {/*                            <div*/}
                        {/*                                onClick={() =>*/}
                        {/*                                    setCurrent(1)*/}
                        {/*                                }*/}
                        {/*                                className={classNames(*/}
                        {/*                                    'px-5 py-3 cursor-pointer',*/}
                        {/*                                    {*/}
                        {/*                                        'bg-orange-50 rounded-[10px]':*/}
                        {/*                                            current ===*/}
                        {/*                                            1,*/}
                        {/*                                    },*/}
                        {/*                                )}>*/}
                        {/*                                <p className="text-zinc-600 font-TTHovesM">*/}
                        {/*                                    Plan photoshoots*/}
                        {/*                                </p>*/}
                        {/*                            </div>*/}
                        {/*                            <div*/}
                        {/*                                onClick={() =>*/}
                        {/*                                    setCurrent(2)*/}
                        {/*                                }*/}
                        {/*                                className={classNames(*/}
                        {/*                                    'px-5 py-3 cursor-pointer',*/}
                        {/*                                    {*/}
                        {/*                                        'bg-orange-50 rounded-[10px]':*/}
                        {/*                                            current ===*/}
                        {/*                                            2,*/}
                        {/*                                    },*/}
                        {/*                                )}>*/}
                        {/*                                <p className="text-zinc-600 font-TTHovesM">*/}
                        {/*                                    Get Booked*/}
                        {/*                                </p>*/}
                        {/*                            </div>*/}
                        {/*                            <div*/}
                        {/*                                onClick={() =>*/}
                        {/*                                    setCurrent(3)*/}
                        {/*                                }*/}
                        {/*                                className={classNames(*/}
                        {/*                                    'px-5 py-3 cursor-pointer',*/}
                        {/*                                    {*/}
                        {/*                                        'bg-orange-50 rounded-[10px]':*/}
                        {/*                                            current ===*/}
                        {/*                                            3,*/}
                        {/*                                    },*/}
                        {/*                                )}>*/}
                        {/*                                <p className="text-zinc-600 font-TTHovesM">*/}
                        {/*                                    Manage Business*/}
                        {/*                                </p>*/}
                        {/*                            </div>*/}
                        {/*                            <div*/}
                        {/*                                onClick={() =>*/}
                        {/*                                    setCurrent(4)*/}
                        {/*                                }*/}
                        {/*                                className={classNames(*/}
                        {/*                                    'px-5 py-3 cursor-pointer',*/}
                        {/*                                    {*/}
                        {/*                                        'bg-orange-50 rounded-[10px]':*/}
                        {/*                                            current ===*/}
                        {/*                                            4,*/}
                        {/*                                    },*/}
                        {/*                                )}>*/}
                        {/*                                <p className="text-zinc-600 font-TTHovesM">*/}
                        {/*                                    Deliver photos*/}
                        {/*                                </p>*/}
                        {/*                            </div>*/}
                        {/*                            <div*/}
                        {/*                                onClick={() =>*/}
                        {/*                                    setCurrent(5)*/}
                        {/*                                }*/}
                        {/*                                className={classNames(*/}
                        {/*                                    'px-5 py-3 cursor-pointer',*/}
                        {/*                                    {*/}
                        {/*                                        'bg-orange-50 rounded-[10px]':*/}
                        {/*                                            current ===*/}
                        {/*                                            5,*/}
                        {/*                                    },*/}
                        {/*                                )}>*/}
                        {/*                                <p className="text-zinc-600 font-TTHovesM">*/}
                        {/*                                    Connect & learn*/}
                        {/*                                </p>*/}
                        {/*                            </div>*/}
                        {/*                        </div>*/}
                        {/*                        <div className="flex-1">*/}
                        {/*                            <Tab1 tab={current} />*/}
                        {/*                            <Tab2 tab={current} />*/}
                        {/*                            <Tab3 tab={current} />*/}
                        {/*                            <Tab4 tab={current} />*/}
                        {/*                            <Tab5 tab={current} />*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </Menu.Items>*/}
                        {/*            </Transition>*/}
                        {/*        </>*/}
                        {/*    )}*/}
                        {/*</Menu>*/}
                        <p
                            onClick={() =>
                                navigate('/signup?role=Photographer')
                            }
                            className="cursor-pointer text-dark-5 font-TTHovesM  text-left">
                            Are you a photographer?
                        </p>
                        {/*<p className="text-dark-5 font-TTHovesM text-left">*/}
                        {/*    Pricing*/}
                        {/*</p>*/}
                    </div>
                </div>
                <div className="flex justify-between items-center h-full w-full md:w-[60%]">
                    {/*<div className="flex items-center ml-4 md:ml-0 text-dark-5 md:w-72">*/}
                    {/*    <SearchNormal />*/}
                    {/*    <input*/}
                    {/*        type="text"*/}
                    {/*        placeholder="Search for photographers"*/}
                    {/*        onClick={() => {*/}
                    {/*            if (isAuth) {*/}
                    {/*                navigate('/dashboard/photographers')*/}
                    {/*            } else {*/}
                    {/*                navigate('/photographers')*/}
                    {/*            }*/}
                    {/*        }}*/}
                    {/*        className="px-4 w-full focus-visible:outline-none bg-transparent"*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div />

                    {/*<MobileMenu />*/}

                    <div className="flex justify-evenly items-center">
                        {isAuth ? (
                            <Link to="/dashboard">
                                <Button className="hidden md:block text-dark-5 border border-tale-10 mx-2 px-4 md:px-7">
                                    Dashboard
                                </Button>
                            </Link>
                        ) : (
                            <Link to="/dashboard/add-quote">
                                <Button className="hidden md:block bg-n-orange text-white mx-2 px-4 md:px-7">
                                    Request Custom Quotes
                                </Button>
                            </Link>
                        )}

                        {/*{!isAuth && (*/}
                        {/*    <Link to="/signup?role=Photographer">*/}
                        {/*        <Button className="hidden md:block bg-tale-10 border-tale-10 text-white mx-2 px-7">*/}
                        {/*            Try for free*/}
                        {/*        </Button>*/}
                        {/*    </Link>*/}
                        {/*)}*/}

                        {isAuth ? (
                            <Menu as="div" className="relative z-10">
                                {({ open }) => (
                                    <>
                                        <Menu.Button className="max-w-xs gap-2 flex items-center focus:outline-none">
                                            <div className="w-10 h-10 border rounded-full border-zinc-400">
                                                <img
                                                    alt="profile"
                                                    src={
                                                        user?.profileImage
                                                            ? `${process.env.REACT_APP_SERVER_DOMAIN}/${user?.profileImage}`
                                                            : '/assets/images/blank.webp'
                                                    }
                                                    className="h-full w-full rounded-full object-cover object-top"
                                                />
                                            </div>
                                        </Menu.Button>
                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-100"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95">
                                            <Menu.Items
                                                static
                                                className="origin-top-right flex flex-col absolute right-0 mt-2 mr-3 w-52 rounded-md shadow-lg divide-y divide-zinc-200 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <Menu.Item>
                                                    <Link
                                                        to="/dashboard/"
                                                        className="hover:text-tale-10 p-3 text-sm hover:text-logo-yellow text-gray-800 flex items-center gap-x-2">
                                                        <Home2
                                                            className="w-5 h-5 font-light"
                                                            stroke={'1'}
                                                        />
                                                        Dashboard
                                                    </Link>
                                                </Menu.Item>
                                                <Menu.Item>
                                                    <Link
                                                        to={`/dashboard/${user?.userName}`}
                                                        className="hover:text-tale-10 p-3 text-sm hover:text-logo-yellow text-gray-800 flex items-center gap-x-2">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={1}
                                                            stroke="currentColor"
                                                            className="w-5 h-5">
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                                                            />
                                                        </svg>
                                                        Profile
                                                    </Link>
                                                </Menu.Item>
                                                <Menu.Item>
                                                    <Link
                                                        to="/dashboard/settings?tab=profile"
                                                        className="hover:text-tale-10 p-3 text-sm hover:text-logo-yellow text-gray-800 flex items-center gap-x-2">
                                                        <Setting2
                                                            className="w-5 h-5 font-light"
                                                            stroke={'1'}
                                                        />
                                                        Settings
                                                    </Link>
                                                </Menu.Item>

                                                <Menu.Item>
                                                    <button
                                                        type="button"
                                                        onClick={() =>
                                                            dispatch(logOut())
                                                        }
                                                        className="hover:text-tale-10 p-3 text-sm hover:text-logo-yellow text-gray-800 flex items-center gap-x-2">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={1}
                                                            stroke="currentColor"
                                                            className="w-6 h-6">
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                                                            />
                                                        </svg>
                                                        Logout
                                                    </button>
                                                </Menu.Item>
                                            </Menu.Items>
                                        </Transition>
                                    </>
                                )}
                            </Menu>
                        ) : (
                            <Menu as="div" className="relative md:hidden z-10">
                                {({ open }) => (
                                    <>
                                        <Menu.Button className="max-w-md gap-2 flex items-center focus:outline-none">
                                            <HambergerMenu className="text-tale-10" />
                                        </Menu.Button>
                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-100"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95">
                                            <Menu.Items
                                                static
                                                className="origin-top-right p-3 flex flex-col absolute right-0 mt-2 gap-3  w-56 rounded-md shadow-lg  bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <Menu.Item>
                                                    <Link to="/login">
                                                        <Button className=" bg-white text-dark-2 border border-brown-10 mx-2 px-7">
                                                            Login
                                                        </Button>
                                                    </Link>
                                                </Menu.Item>
                                                <Menu.Item>
                                                    <Link to="/signup?role=Client">
                                                        <Button className="text-white bg-n-orange mx-2 px-4 md:px-7">
                                                            Request for a quote
                                                        </Button>
                                                    </Link>
                                                </Menu.Item>
                                                {/*<Menu.Item>*/}
                                                {/*    <Link to="/signup?role=Photographer">*/}
                                                {/*        <Button className=" bg-tale-10 border-tale-10 text-white mx-2 px-7">*/}
                                                {/*            Try for free*/}
                                                {/*        </Button>*/}
                                                {/*    </Link>*/}
                                                {/*</Menu.Item>*/}
                                            </Menu.Items>
                                        </Transition>
                                    </>
                                )}
                            </Menu>
                        )}

                        {!isAuth && (
                            <Link to="/login">
                                <Button className="hidden md:block bg-n-dark text-white mx-2 px-7">
                                    Login
                                </Button>
                            </Link>
                        )}
                        <div className="hidden md:flex items-center ">
                            <IconGlobe />
                            <select
                                className="pl-2 focus:outline-none "
                                value={locale}>
                                <option value="eng">ENG</option>
                                <option value="dutch">DUTCH</option>
                            </select>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

const Tab1 = ({ tab }) => {
    return (
        <div
            className={classNames('px-4', {
                hidden: tab !== 1,
            })}>
            <p className="text-gray-600">Plan Photoshoots</p>
            <p className="text-slate-800 text-[25px] font-medium font-OTabolas mt-2">
                Grow your confidence and rock your next shoot
            </p>
            <div className="flex flex-col gap-4 divide-y divide-zinc-200 mt-4">
                <Menu.Item>
                    <Link
                        to="/dashboard/photographers"
                        className="flex items-center justify-between pt-4 border-t border-zinc-200">
                        <p className="text-zinc-600 font-TTHovesM">
                            World's largest library of poses & prompts
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/quotes"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">
                            Sun tracker
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/board"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">
                            Camera setting guide
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
            </div>
            <button className="bg-brown-10 w-full rounded-lg text-white p-2 mt-6 mb-8">
                Explore posing features
            </button>
        </div>
    )
}

const Tab2 = ({ tab }) => {
    return (
        <div
            className={classNames('flex-1 px-4', {
                hidden: tab !== 2,
            })}>
            <p className="text-gray-600">Photography directory</p>
            <p className="text-slate-800 text-[25px] font-medium font-OTabolas mt-2">
                Book dream jobs with ease
            </p>
            <div className="flex flex-col gap-4 divide-y divide-zinc-200 mt-4">
                <Menu.Item>
                    <Link
                        to="/dashboard/photographers"
                        className="flex items-center justify-between pt-4 border-t border-zinc-200">
                        <p className="text-zinc-600 font-TTHovesM">
                            Showcase your work with a mini website
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/quotes"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">
                            Browse jobs near you
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/board"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">
                            Collab with other photographers
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
            </div>
            <button className="bg-brown-10 w-full rounded-lg text-white p-2 mt-6 mb-8">
                Explore directory features
            </button>
        </div>
    )
}

const Tab3 = ({ tab }) => {
    return (
        <div
            className={classNames('flex-1 px-4', {
                hidden: tab !== 3,
            })}>
            <p className="text-gray-600">Business CRM</p>
            <p className="text-slate-800 text-[25px] font-medium font-OTabolas mt-2">
                Easily make money doing what you love
            </p>
            <div className="flex flex-col gap-4 divide-y divide-zinc-200 mt-4">
                <Menu.Item>
                    <Link
                        to="/dashboard/photographers"
                        className="flex items-center justify-between pt-4 border-t border-zinc-200">
                        <p className="text-zinc-600 font-TTHovesM">
                            Protect your business with contracts
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/quotes"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">
                            Get paid with digital invoices
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/board"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">
                            Client questionnaires & more
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
            </div>
            <button className="bg-brown-10 w-full rounded-lg text-white p-2 mt-6 mb-8">
                See business features
            </button>
        </div>
    )
}

const Tab4 = ({ tab }) => {
    return (
        <div
            className={classNames('flex-1 px-4', {
                hidden: tab !== 4,
            })}>
            <p className="text-gray-600">Client Galleries</p>
            <p className="text-slate-800 text-[25px] font-medium font-OTabolas mt-2">
                Wow clients with beautiful photo galleries
            </p>
            <div className="flex flex-col gap-4 divide-y divide-zinc-200 mt-4">
                <Menu.Item>
                    <Link
                        to="/dashboard/photographers"
                        className="flex items-center justify-between pt-4 border-t border-zinc-200">
                        <p className="text-zinc-600 font-TTHovesM">
                            Stunning templates to choose from
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/quotes"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">
                            Curate favourite lists
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/board"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">
                            Secure & private photo sharing
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
            </div>
            <button className="bg-brown-10 w-full rounded-lg text-white p-2 mt-6 mb-8">
                Explore Gallery features
            </button>
        </div>
    )
}

const Tab5 = ({ tab }) => {
    return (
        <div
            className={classNames('flex-1 px-4', {
                hidden: tab !== 5,
            })}>
            <p className="text-gray-600">Client Galleries</p>
            <p className="text-slate-800 text-[25px] font-medium font-OTabolas mt-2">
                Boost your skills and connect with others
            </p>
            <div className="flex flex-col gap-4 divide-y divide-zinc-200 mt-4">
                <Menu.Item>
                    <Link
                        to="/dashboard/photographers"
                        className="flex items-center justify-between pt-4 border-t border-zinc-200">
                        <p className="text-zinc-600 font-TTHovesM">
                            Join our thriving Facebook community
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/quotes"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">
                            Free video course
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        to="/dashboard/board"
                        className="flex items-center justify-between pt-4">
                        <p className="text-zinc-600 font-TTHovesM">
                            Educational libraries
                        </p>
                        <ArrowRight className="h-6 w-6 text-zinc-600" />
                    </Link>
                </Menu.Item>
            </div>
            <button className="bg-brown-10 w-full rounded-lg text-white p-2 mt-6 mb-8">
                Learn More
            </button>
        </div>
    )
}
