import React from 'react'
import { InvitedPhotographerListInterface } from 'utils/interfaces/invitePhotographers'
import InvitedPhotographerTile from './InvitedPhotographerTile'

const InvitedPhotographerList: React.FC<InvitedPhotographerListInterface> = ({
    list,
}) => (
    <div>
        <h4 className="text-base leading-[21px] text-dark-8 mb-[15px] mt-[34px]">
            {'Invited Photographers'}
        </h4>

        <div className="flex flex-wrap gap-5">
            {list.map((photographer, index) => (
                <div className="w-full md:w-[calc(100%/2-10px)]" key={index}>
                    <InvitedPhotographerTile data={photographer} />
                </div>
            ))}
        </div>
    </div>
)

export default InvitedPhotographerList
