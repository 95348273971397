import React, { useEffect, useRef, useState } from 'react'
import EditorJS from '@editorjs/editorjs'

import { EDITOR_JS_TOOLS } from 'utils/constants'
import { Camera, CloseCircle, Gallery, RefreshCircle } from 'iconsax-react'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import UserService from 'services/user.service'
import { useNotifications } from 'hooks/useNotification'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Input } from 'components/form/Input/Input'
import { IconGallery } from '../../../css/icons/icons'

const service = new UserService()

export const EmailTemplate = () => {
    const user = useSelector(userSelector)
    const [params] = useSearchParams()

    const [data, setData] = useState<any>(null)
    const [state, setState] = useState({
        title: '',
        profileLogo: null,
        headerImage: null,
        user: user?._id,
        items: data,
    })
    const [logoPreview, setLogoPreview] = useState('')
    const [loading, setLoading] = useState(false)
    const [coverPreview, setCoverPreview] = useState('')

    const { successMessage } = useNotifications()
    const navigate = useNavigate()

    useEffect(() => {
        if (params.has('id')) {
            setLoading(true)
            service
                .getTemplate(params.get('id') ?? '')
                .then(resp => {
                    setState(resp.data.data)
                    setLogoPreview(
                        `${process.env.REACT_APP_SERVER_DOMAIN}/${resp.data.data.profileLogo}`,
                    )
                    setCoverPreview(
                        `${process.env.REACT_APP_SERVER_DOMAIN}/${resp.data.data.headerImage}`,
                    )
                    setData({
                        blocks: resp.data.data.items.map(item => ({
                            ...item,
                            id: item._id,
                        })),
                    })
                    setLoading(false)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [params])

    const handleUploadPhoto = e => {
        const file = e.target.files[0]

        const reader = new FileReader()

        service
            .getImagesURL({ images: file })
            .then(resp => {
                if (e.target.name === 'coverphoto') {
                    setState({
                        ...state,
                        headerImage: resp.data.urls[0],
                    })
                } else {
                    setState({
                        ...state,
                        profileLogo: resp.data.urls[0],
                    })
                }
            })
            .catch(error => {
                console.log(error)
            })

        reader.onload = () => {
            if (reader.readyState === 2) {
                if (e.target.name === 'coverphoto') {
                    setCoverPreview(reader.result as string)
                } else {
                    setLogoPreview(reader.result as string)
                }
            }
        }

        reader.readAsDataURL(file)
    }

    const handleAddTemplate = () => {
        if (params.has('id')) {
            service
                .updateEmailTemplate(
                    { ...state, items: data },
                    params.get('id'),
                )
                .then(() => {
                    successMessage('Email template updated successfully')
                    navigate('/dashboard/services?tab=email')
                })
                .catch(error => {
                    console.log(error)
                })
        } else {
            service
                .addEmailTemplate({ ...state, items: data })
                .then(() => {
                    successMessage('Email template saved successfully')
                    navigate('/dashboard/services?tab=email')
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    return (
        <div className="bg-white max-w-5xl relative shadow mx-auto rounded-lg border overflow-hidden border-zinc-200 p-3">
            <div className="justify-end flex mb-3 gap-2">
                <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="shadow-sm bg-white border text-zinc-700 border-zinc-200 rounded-md px-4 py-2 text-sm ">
                    Cancel
                </button>
                <button
                    onClick={handleAddTemplate}
                    className="shadow-sm bg-tale-10  text-white rounded-md px-4 py-2 text-sm ">
                    Save
                </button>
            </div>
            <Input
                type={'text'}
                value={state.title}
                onChange={e => setState({ ...state, title: e.target.value })}
                placeholder={'Enter template title'}
                className="mb-3"
            />
            {coverPreview ? (
                <div className="w-full z-0 relative h-72">
                    <img
                        alt="cover"
                        className="h-full w-full object-cover"
                        src={coverPreview}
                    />
                    <button
                        onClick={() => setCoverPreview('')}
                        className="absolute right-0 top-0 p-3 text-white">
                        <CloseCircle />
                    </button>
                </div>
            ) : (
                <label
                    htmlFor="coverphoto"
                    className="hover:bg-zinc-100 cursor-pointer h-44 w-full p-2 bg-zinc-50 rounded-md border-b border-dashed border-zinc-200  flex items-center justify-center">
                    <Gallery className="h-10 w-10 text-gray-600" />
                    <input
                        type="file"
                        id="coverphoto"
                        name="coverphoto"
                        className="sr-only"
                        onChange={handleUploadPhoto}
                    />
                </label>
            )}
            <label
                id="profile-dropzone"
                className="inline-flex flex-col md:flex-row justify-between md:items-center z-10 -mt-20 ml-3 py-6">
                {logoPreview ? (
                    <div className="w-40 h-40 mb-2 relative">
                        <img
                            alt="profile"
                            src={logoPreview}
                            className="h-full w-full rounded-full object-cover"
                        />
                        <div
                            id="profile-dropzone"
                            className="group absolute cursor-pointer w-full transition-colors ease-in-out flex items-center justify-center top-0 h-full hover:bg-zinc-300 hover:bg-opacity-30 rounded-full text-white">
                            <span className="hidden group-hover:block">
                                <Camera className="h-12 w-12" />
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className="flex cursor-pointer ">
                        <div className="border w-fit border-dashed border-gray-outline rounded-full p-16 bg-creame-1">
                            <IconGallery />
                        </div>
                        <div className="hidden md:flex flex-col justify-center items-center mr-10 pl-6 mt-4">
                            <p className="text-base font-TTHovesM text-brown-10">
                                Click to upload profile image
                            </p>
                            <p className="text-xs sfont-TTHoves text-normal text-dark-5">
                                PNG or JPG (Optimal size 800 x 800px)
                            </p>
                        </div>
                    </div>
                )}

                <input
                    id="profile-dropzone"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleUploadPhoto}
                />
            </label>

            {loading ? (
                <RefreshCircle className="mx-auto text-tale-10 size-10 animate-spin mb-4" />
            ) : (
                <Editor
                    data={data}
                    onChange={value => setData(value.blocks)}
                    editorblock="editorjs-container"
                />
            )}
        </div>
    )
}

const Editor = ({ onChange, data, editorblock }: any) => {
    const ref = useRef<any>()
    //Initialize editorjs
    useEffect(() => {
        //Initialize editorjs if we don't have a reference
        if (!ref.current) {
            ref.current = new EditorJS({
                style: {
                    nonce: '',
                },
                autofocus: true,
                holder: editorblock,
                placeholder: 'Start typing here...',
                tools: EDITOR_JS_TOOLS,
                data: data,

                async onChange(api, event) {
                    const saved = await api.saver.save()
                    onChange(saved)
                },
            })
        }

        //Add a return function to handle cleanup
        return () => {
            if (ref.current && ref.current.destroy) {
                ref.current.destroy()
            }
        }
    }, [])

    return (
        <div
            className="min-w-full !w-full"
            style={{ width: '100%' }}
            id={editorblock}
        />
    )
}
