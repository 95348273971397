import { Input } from 'components/form/Input/Input'
import { ToggleButton } from 'components/form/ToggleButton'
import { CloseSquare, Copy } from 'iconsax-react'
import React, { memo, useState } from 'react'
import { DayDateTimeInterface } from 'utils/interfaces/availability'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import { Times } from 'utils/constants'

const DayDateTimeTile: React.FC<{
    data: DayDateTimeInterface
    getData?: (data) => void
    validation?: boolean
}> = ({ data, getData, validation }) => {
    const [initData, setInitData] = useState(data)

    const addExtraTiming = () => {
        const newBooking = { ...initData }

        if (newBooking.workingHours.length < 2)
            newBooking.workingHours.push({ from: '', to: '' })

        if (getData) getData(newBooking)
        setInitData(newBooking)
    }

    const removeExtraTime = index => {
        const newBooking = { ...initData }
        newBooking.workingHours.splice(1, index)
        if (getData) getData(newBooking)
        setInitData(newBooking)
    }

    const onChangeTime = (name, index, value) => {
        const newBooking = { ...initData }
        newBooking.workingHours[index][name] = value
        if (getData) getData(newBooking)
        setInitData(newBooking)
    }

    return (
        <div className="flex flex-col md:flex-row gap-4">
            <div className="flex gap-4 pt-2">
                <span>
                    <ToggleButton
                        value={initData.openToWork}
                        id={initData.day}
                        onChange={() => {
                            getData &&
                                getData({
                                    ...initData,
                                    openToWork: !initData.openToWork,
                                })
                            setInitData({
                                ...initData,
                                openToWork: !initData.openToWork,
                            })
                        }}
                    />
                </span>
                <p className="w-20">{initData.day}</p>
            </div>

            <div className="flex flex-col gap-3">
                {initData.workingHours.map((times, index) => (
                    <div className="flex items-center gap-3" key={index}>
                        <div className="w-32">
                            <ReactSelect
                                value={times?.from}
                                icon
                                onChange={value =>
                                    onChangeTime('from', index, value)
                                }
                                options={Times}
                                placeholder={'Start'}
                            />
                        </div>
                        {'-'}
                        <div className="w-32">
                            <ReactSelect
                                value={times?.to}
                                icon
                                // error={Boolean(
                                //     validation &&
                                //         !times.from &&
                                //         initData.openToWork,
                                // )}
                                onChange={value =>
                                    onChangeTime('to', index, value)
                                }
                                options={Times}
                                placeholder={'End'}
                            />
                        </div>
                        {/*<Input*/}
                        {/*    type="time"*/}
                        {/*    error={Boolean(*/}
                        {/*        validation &&*/}
                        {/*        !times.from &&*/}
                        {/*        initData.openToWork,*/}
                        {/*    )}*/}
                        {/*    placeholder="Start Date"*/}
                        {/*    className="w-32"*/}
                        {/*    value={times.from}*/}
                        {/*    disabled={!initData.openToWork}*/}
                        {/*    onChange={({ target }) =>*/}
                        {/*        initData.openToWork &&*/}
                        {/*        onChangeTime('from', index, target.value)*/}
                        {/*    }*/}
                        {/*/>*/}
                        {'-'}
                        {/*<Input*/}
                        {/*    type="time"*/}
                        {/*    error={Boolean(*/}
                        {/*        validation && !times.to && initData.openToWork,*/}
                        {/*    )}*/}
                        {/*    placeholder="End Date"*/}
                        {/*    className="w-32"*/}
                        {/*    value={times.to}*/}
                        {/*    disabled={!initData.openToWork}*/}
                        {/*    onChange={({ target }) =>*/}
                        {/*        initData.openToWork &&*/}
                        {/*        onChangeTime('to', index, target.value)*/}
                        {/*    }*/}
                        {/*/>*/}
                        {index >= 1 ? (
                            <CloseSquare
                                size={20}
                                color="#667085"
                                className="cursor-pointer"
                                onClick={() =>
                                    initData.openToWork &&
                                    removeExtraTime(index)
                                }
                            />
                        ) : (
                            <Copy
                                size={20}
                                color="#667085"
                                className="cursor-pointer"
                                onClick={() =>
                                    initData.openToWork && addExtraTiming()
                                }
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default memo(DayDateTimeTile)
