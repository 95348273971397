import React from 'react'
import { icon, icon2, icon3, tick } from 'utils/exportImages'

const SelectAmount: React.FC<{
    depositAmount: (amount) => void
    amount: number
}> = ({ amount, depositAmount }) => {
    return (
        <>
            <div className="flex relative  mt-4">
                <span
                    className={`absolute w-full h-[10px] bg-[#F3F4F8] z-[1] bottom-[8px] after:absolute rounded overflow-hidden  ${amount === 50
                        ? 'after:w-[37%]'
                        : amount === 100
                            ? 'after:w-[62%]'
                            : amount === 200
                                ? 'after:w-[100%]'
                                : 'after:w-[0%]'
                        } after:bg-[#38AE04] after:top-0 after:bottom-0 after:left-0`}
                />
                <div className="flex-1 font-TTHovesSB text-[#404553] text-sm min-h-[58px] relative z-[2] ">
                    <span
                        className="flex flex-col cursor-pointer w-fit"
                        onClick={() => depositAmount && depositAmount(30)}>
                        {'Є 30'}

                        <img
                            className="w-[28px] h-[28px] mt-[21px] relative z-[2]"
                            src={icon}
                            alt=""
                        />
                    </span>
                </div>
                <div
                    className="flex-1 font-TTHovesSB text-sm flex justify-between"
                    role="presentaion">
                    <span
                        className="flex flex-col text-center cursor-pointer"
                        onClick={() => depositAmount && depositAmount(50)}>
                        {'Є 50'}

                        <img
                            className="w-[28px] h-[28px] mt-[21px] relative z-[2] mx-auto"
                            src={amount >= 50 ? icon : icon2}
                            alt=""
                        />
                    </span>
                    <span
                        className="flex flex-col text-center cursor-pointer"
                        role="presentaion"
                        onClick={() => depositAmount && depositAmount(100)}>
                        {'Є 100'}

                        <img
                            className="w-[28px] h-[28px] mt-[21px] relative z-[2] mx-auto"
                            src={amount >= 100 ? icon : icon2}
                            alt=""
                        />
                    </span>
                </div>
                <div
                    className="flex-1 flex flex-col justify-end font-TTHovesSB text-[#404553] text-sm text-right cursor-pointer"
                    role="presentaion"
                    onClick={() => depositAmount && depositAmount(200)}>
                    {'Є 200'}

                    <span className="relative flex justify-end z-[2] mt-[21px]">
                        <img
                            className="w-[28px] h-[28px]"
                            src={amount === 200 ? icon : icon3}
                            alt=""
                        />
                    </span>
                </div>
            </div>

            <div className="py-2 text-center bg-[#EFF3FD] rounded-lg mt-6">
                <p className="text-[15px] font-TTHovesL">
                    {"if you opt for €200, you get "}
                    <span className="font-TTHovesSB">{"20 free credits extra"}</span>
                </p>
            </div>

            <div className="flex justify-between mt-4">
                <span className="text-sm font-TTHovesL text-[#7E859B]">
                    {'Total amount spent'}
                </span>
                <span className="font-TTHovesM">{`Є ${amount}.00`}</span>
            </div>
        </>
    )
}

export default SelectAmount
