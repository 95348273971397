import { useNotifications } from 'hooks/useNotification'
import { Link1, MessageText } from 'iconsax-react'
import { trim } from 'lodash'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import UserService from 'services/user.service'
import { PhotographerData } from 'utils/interfaces/invitePhotographers'

const service = new UserService()

const InvitedPhotographerTile: React.FC<{ data: PhotographerData }> = ({
    data,
}) => {
    const [loading, setLoading] = useState('')
    const navigate = useNavigate()
    const params = useParams()
    const { successMessage } = useNotifications()

    const sendInvite = async (id: string) => {
        setLoading('invite')
        await service.invitePhotographer(params?.id || '', {
            photographer: id,
        })
        setLoading('')
        successMessage('Invitation Send successfully')
    }

    const revokeInvite = async (id: string) => {
        setLoading('revoke')
        await service.revokePhotographer(params?.id || '', {
            photographer: id,
        })
        setLoading('')
        navigate(`/dashboard/quote/${params?.id}`)
        successMessage('Email Revoke successfully')
    }

    return (
        <div className="border border-primary rounded-[10px]">
            <div className="flex flex-col md:flex-row p-4 relative items-center gap-[13px]">
                <div>
                    <img
                        alt="profile"
                        src={`${process.env.REACT_APP_SERVER_DOMAIN}/${data?.profileImage}`}
                        className="h-[75px] w-[75px] rounded-full object-cover"
                    />
                </div>

                {/* Right Content Side */}
                <div className="text-center md:text-left">
                    <p className="font-TTHovesSB text-[22px] leading-[32px] text-dark-8 capitalize">{`${data?.firstName} ${data?.lastName}`}</p>
                    <div className="flex gap-2 mt-[9px]">
                        <span className="capitalize text-[13px] leading-[16px] tracking-[0.2px] font-TTHovesM border border-primary rounded text-tale-10 py-[7px] px-[13px]">
                            {data.businessName}
                        </span>

                        <span className="py-[7px] px-[13px] text-[13px] leading-[16px] border-primary border rounded bg-[#F9FAFB]">
                            {trim(data.currencySymbol)}
                            {`${data?.servicePreferences?.hourlyRate}/hr`}
                        </span>
                    </div>
                </div>
                <button
                    className="flex gap-3 absolute right-[15px] top-[17px] uppercase text-[14px] leading-[18px] tracking-[0.2px] font-TTHovesSB border border-primary rounded-[5px] bg-[#F9FAFB] text-dark-9 py-[4px] px-[13px]"
                    type="button">
                    {'Invited'}
                </button>
            </div>
            <div className="border-t border-primary p-3 flex  md:gap-10">
                <button
                    className="capitalize text-[15px] leading-[20px] flex gap-2 items-center tracking-[0.2px] font-TTHovesM text-dark-5"
                    onClick={() => !loading && sendInvite(data?._id)}>
                    <MessageText size="15px" color="#69884F" />
                    {loading === 'invite'
                        ? 'Sending Invite...'
                        : 'Resend Invitation'}
                </button>

                <button
                    className="capitalize text-[15px] leading-[20px] flex gap-2 items-center tracking-[0.2px] font-TTHovesM text-dark-5"
                    onClick={() => !loading && revokeInvite(data?._id)}>
                    <Link1 size="18px" color="#69884F" />
                    {loading === 'revoke' ? 'Revoking...' : 'Revoke Invitation'}
                </button>
            </div>
        </div>
    )
}

export default InvitedPhotographerTile
