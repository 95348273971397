import styles from 'pages/auth/Auth.module.css'
import { IconGallery } from '../../css/icons/icons'
import { Button } from 'components/form/Button/Button'
import { pages } from 'pages/auth/CreateProfile'
import { useState } from 'react'
import UserService from 'services/user.service'
import { setUser } from '../../redux/reducers/authReducer'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'
import { CloseCircle } from 'iconsax-react'
import { useNotifications } from 'hooks/useNotification'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { RichEditor } from 'components/common/RichEditor'

const service = new UserService()

export const UploadProfileImage = ({
    currentPage,
    stepIncrement,
    pageChange,
}) => {
    const user = useSelector(userSelector)

    const [state, setState] = useState({
        coverImage: '',
        profileImage: '',
        bio: user?.bio ?? '',
    })
    const [coverPreview, setCoverPreview] = useState(
        user?.coverImage
            ? `${process.env.REACT_APP_SERVER_DOMAIN}/${user?.coverImage}`
            : '',
    )
    const [profilePreview, setProfilePreview] = useState(
        user?.profileImage
            ? `${process.env.REACT_APP_SERVER_DOMAIN}/${user?.profileImage}`
            : '',
    )
    const [submitting, setSubmitting] = useState(false)

    const dispatch = useThunkDispatch()
    const { successMessage, errorMessage } = useNotifications()

    const handleUploadImage = e => {
        const file = e.target.files[0]
        const reader = new FileReader()
        const id = e.target.id
        if (!file.type.includes('image')) {
            errorMessage('Please upload a valid image')
            return
        }

        if (id === 'cover-dropzone') {
            setState({
                ...state,
                coverImage: file,
            })
        } else {
            setState({
                ...state,
                profileImage: file,
            })
        }

        reader.onload = () => {
            if (reader.readyState === 2) {
                if (id === 'cover-dropzone') {
                    setCoverPreview(reader.result as string)
                } else {
                    setProfilePreview(reader.result as string)
                }
            }
        }
        file && reader.readAsDataURL(file)
    }

    const handleSubmit = e => {
        e.preventDefault()

        setSubmitting(true)
        service
            .uploadProfileImages(state)
            .then(resp => {
                successMessage('Profile Updated successfully')
                pageChange(pages.NEXT)
                stepIncrement(1)
                dispatch(setUser(resp.data.data))
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    return (
        <div
            id="page-1"
            style={{
                display: currentPage === 1 ? 'block' : 'none',
            }}>
            <div className="grid">
                <p className="text-4xl text-dark-8 font-OTabolas">
                    Let’s set up your profile
                </p>
                <p className={`mt-1 ${styles.messageText}`}>
                    Let's start with the basics. This is what your future dream
                    clients will see.
                </p>
                <div className="mb-20">
                    <div className="relative py-4">
                        {coverPreview ? (
                            <div className="h-72 rounded-xl relative">
                                <img
                                    alt="cover"
                                    src={`${coverPreview}`}
                                    className="h-full w-full rounded-xl object-cover"
                                />
                                <button
                                    onClick={() => setCoverPreview('')}
                                    className="absolute right-0 top-0 p-3 text-white">
                                    <CloseCircle />
                                </button>
                            </div>
                        ) : (
                            <label id="cover-dropzone">
                                <div className="cursor-pointer flex flex-row justify-center items-center border border-dashed border-gray-outline rounded-xl p-12 my-6">
                                    <div className="flex flex-col justify-center items-center">
                                        <div className="mb-1">
                                            <IconGallery />
                                        </div>
                                        <div className="text-base font-TTHovesM text-brown-10 text-center">
                                            Click to upload cover image{' '}
                                            <span className="text-base font-TTHoves text-normal text-dark-5 ">
                                                &nbsp; or drag and drop
                                            </span>
                                        </div>
                                        <div className="text-xs font-TTHoves text-normal text-dark-5">
                                            PNG, JPG or GIF (Optimal size 1440 x
                                            500px)
                                        </div>
                                        <input
                                            id="cover-dropzone"
                                            type="file"
                                            accept="image/png, image/gif, image/jpeg"
                                            onChange={handleUploadImage}
                                            className="hidden"
                                        />
                                    </div>
                                </div>
                            </label>
                        )}

                        <label
                            id="profile-dropzone"
                            className="absolute cursor-pointer -bottom-24 left-2 ">
                            {profilePreview ? (
                                <div className="h-44 w-44 mb-2 relative">
                                    <img
                                        alt="profile"
                                        src={`${profilePreview}`}
                                        className="h-full w-full rounded-full object-cover"
                                    />
                                    <div
                                        id="profile-dropzone"
                                        className="group absolute w-full transition-colors ease-in-out flex items-center justify-center  top-0 h-full hover:bg-zinc-300 hover:bg-opacity-50 rounded-full text-white">
                                        <span className="hidden group-hover:block delay-100 ">
                                            Change
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex">
                                    <div className="border w-fit border-dashed border-gray-outline rounded-full p-16 bg-creame-1">
                                        <IconGallery />
                                    </div>
                                    <div className="hidden md:flex flex-col justify-center items-center mr-10 pl-6 mt-4">
                                        <p className="text-base font-TTHovesM text-brown-10">
                                            Click to upload profile image
                                        </p>
                                        <p className="text-xs sfont-TTHoves text-normal text-dark-5">
                                            PNG or JPG (Optimal size 800 x
                                            800px)
                                        </p>
                                    </div>
                                </div>
                            )}

                            <input
                                id="profile-dropzone"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={handleUploadImage}
                                className="hidden"
                            />
                        </label>
                    </div>
                </div>
                <hr className="text-gray-outline mt-8" />
                <div className="flex flex-col md:flex-row justify-between gap-2 mt-4">
                    <div className="col-span-2 md:col-span-10 mb-3 md:mb-0">
                        <div className="text-base text-dark-8 font-TTHovesM">
                            Bio
                        </div>
                        <div className="text-body-thin text-gray-600 font-TTHovesEL">
                            Write a short introduction.
                        </div>
                    </div>
                    {/*<Editor*/}
                    {/*    value={state.bio}*/}
                    {/*    onChange={value => setState({ ...state, bio: value })}*/}
                    {/*/>*/}
                    <RichEditor
                        content={state.bio}
                        setContent={value =>
                            setState({
                                ...state,
                                bio: value,
                            })
                        }
                    />
                </div>
                <div className="pb-10 pt-5">
                    <Button
                        type="submit"
                        disabled={submitting}
                        className="bg-brown-10 disabled:cursor-not-allowed disabled:bg-opacity-70 border-brown-10 text-white px-7 h-12 w-full"
                        onClick={handleSubmit}>
                        {submitting ? 'Please wait...' : 'Continue'}
                    </Button>
                </div>
            </div>
        </div>
    )
}
