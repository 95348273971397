import React from 'react'
import { SearchNormal } from 'iconsax-react'
import { useNavigate } from 'react-router-dom'

export const Hero = () => {
    const navigate = useNavigate()
    return (
        <div className="bg-hero rounded-lg relative md:h-96 pb-12 md:pb-0">
            <p className="z-10 relative text-center text-3xl md:text-heading-bold font-OTabolas max-w-[700px]  pt-12  mx-auto">
                Find your next professional photographer for your
            </p>
            <p className="text-center text-3xl md:text-heading-bold font-OTabolas">
                Wedding
            </p>
            <div className="hidden md:block absolute z-0 bottom-0 right-0">
                <div className="h-96 w-96">
                    <img
                        src="/assets/photographer.png"
                        className="h-full w-full"
                        alt="hero"
                    />
                </div>
            </div>
            <div className="relative z-10 mx-auto w-3/4 md:w-1/2 mt-2">
                <input
                    className="w-full focus:outline-none  bg-white mx-auto rounded-lg p-3"
                    placeholder="Hire Talents"
                    onClick={() => {
                        navigate('/photographers')
                    }}
                />
                <div className="absolute bg-brown-10 p-1.5 rounded-lg text-white top-1.5 right-2">
                    <SearchNormal className="h-6 w-6" />
                </div>
            </div>
        </div>
    )
}
