import React, { Fragment, useCallback, useEffect, useState } from 'react'
import {
    Add,
    ArrowRight,
    ArrowRight2,
    Home2,
    NotificationBing,
    RefreshCircle,
} from 'iconsax-react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { CheckCircle } from '../../../css/icons/icons'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import { IconButton } from 'components/form/Button/Button'
import UserService from 'services/user.service'
import { TabButton } from 'pages/dashboard/business/Service'
import { Questionnaires } from 'components/dashboard/business/clientProposal/Questionnaires'
import { Contract } from 'components/dashboard/business/clientProposal/Contract'
import { Invoices } from 'components/dashboard/business/clientProposal/Invoices'
import { Notes } from 'components/dashboard/business/clientProposal/Notes'
import { setProject } from '../../../redux/reducers/projectReducer'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { ProjectListing } from 'components/dashboard/business/ProjectListing'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import { ConfirmModal } from 'components/common/ConfirmModal'
import { useNotifications } from 'hooks/useNotification'
import { formatFileSize } from 'utils/funcs'

const service = new UserService()

export const ClientProposal = () => {
    const navigate = useNavigate()
    const [tab, setTab] = useState('overview')
    const [loading, setLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    const [addContract, setAddContract] = useState(false)
    const dispatch = useThunkDispatch()

    const { project } = useSelector((state: any) => state.project)

    const [params] = useSearchParams()
    const { successMessage } = useNotifications()
    const { id } = useParams()

    const fetchData = useCallback(() => {
        setLoading(true)
        service
            .getSpecificProject(id ?? '')
            .then(resp => {
                dispatch(setProject(resp.data.data))
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }, [id])

    useEffect(() => {
        fetchData()
    }, [])

    const handleConfirm = () => {
        service
            .acceptDelivery(id ?? '', project.galleries[0].galleryId?._id)
            .then(() => {
                fetchData()
                setIsOpen(false)
                successMessage('Gallery accepted successfully')
            })
            .catch(error => {
                console.log(error)
            })
    }

    const confirm = project?.invoice?.every(
        item => item.paymentStatus === 'Confirm Payment',
    )

    return (
        <Fragment>
            <div className="flex items-center justify-between ">
                <div className="hidden items-center gap-2 md:flex">
                    <Home2 className="h-5 w-5" />
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                        Business
                    </div>
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <p>...</p>
                    <div className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                        {project?.photoshootTitle}
                    </div>
                </div>
            </div>

            {project?.projectListing?.contractCreated &&
                project?.clientSignature &&
                project?.signature && (
                    <div className="hidden w-full p-1 overflow-auto bg-white rounded-lg border border-zinc-200 justify-start items-center gap-2 md:inline-flex mt-4">
                        <TabButton
                            label="Overview"
                            isActive={!params.has('tab')}
                            onClick={() =>
                                navigate(`/dashboard/business/proposal/${id}`)
                            }
                        />
                        <TabButton
                            label="Questionnaires"
                            isActive={params.get('tab') === 'questionnaires'}
                            onClick={() =>
                                navigate(
                                    `/dashboard/business/proposal/${id}?tab=questionnaires`,
                                )
                            }
                        />
                        <TabButton
                            label="Contract"
                            isActive={params.get('tab') === 'contract'}
                            onClick={() =>
                                navigate(
                                    `/dashboard/business/proposal/${id}?tab=contract`,
                                )
                            }
                        />
                        <TabButton
                            label="Invoices"
                            isActive={params.get('tab') === 'invoices'}
                            onClick={() =>
                                navigate(
                                    `/dashboard/business/proposal/${id}?tab=invoices`,
                                )
                            }
                        />

                        <TabButton
                            label="Notes"
                            isActive={params.get('tab') === 'notes'}
                            onClick={() =>
                                navigate(
                                    `/dashboard/business/proposal/${id}?tab=notes`,
                                )
                            }
                        />
                    </div>
                )}

            <div className="block md:hidden ">
                <ReactSelect
                    value={tab}
                    onChange={value => {
                        if (value === 'overview') {
                            navigate(`/dashboard/business/proposal/${id}`)
                            setTab(value)
                        } else {
                            setTab(value)
                            navigate(
                                `/dashboard/business/proposal/${id}?tab=${value}`,
                            )
                        }
                    }}
                    options={[
                        { label: 'Overview', value: 'overview' },
                        { label: 'Questionnaires', value: 'questionnaires' },
                        { label: 'Contract', value: 'contract' },
                        {
                            label: 'Invoices',
                            value: 'invoices',
                        },
                        { label: 'Notes', value: 'notes' },
                    ]}
                    placeholder="Select"
                />
            </div>
            <div className="flex flex-col md:flex-row gap-2 mt-4 items-start">
                {loading ? (
                    <div className="w-full mt-4">
                        <RefreshCircle className="animate-spin text-tale-10 size-12 mx-auto" />
                    </div>
                ) : (
                    <>
                        {!params.has('tab') && (
                            <div className="w-full bg-white rounded-xl border border-zinc-200 p-5 relative">
                                <p className="font-OTabolas text-2xl">
                                    {project?.photoshootTitle}
                                </p>
                                <p className="text-zinc-600 mt-1">
                                    Project Activity
                                </p>

                                {project?.projectListing?.contractCreated &&
                                project?.clientSignature &&
                                project?.galleries?.length < 1 ? (
                                    <div className="bg-[#FFF4EE] mt-2 rounded-md p-5 border border-zinc-200 w-full md:w-1/2">
                                        <button className="flex items-center gap-1 bg-[#F2CDB6] font-TTHoves bg-opacity-50  text-xs px-2 py-1.5 rounded-md uppercase">
                                            Next Step
                                            <ArrowRight className="h-3 w-3" />
                                        </button>
                                        <p className="font-TTHovesM text-zinc-800 text-lg mt-2">
                                            Project yet to be delivered
                                        </p>
                                        <p className="text-zinc-600 text-sm">
                                            Photographer will attach the gallery
                                            to deliver the photoshoot images.
                                            Please review the gallery and select
                                            the images for the album. You can
                                            accept the delivery once you are
                                            satisfied with the gallery images.
                                        </p>
                                    </div>
                                ) : project?.galleries?.length > 0 ? (
                                    <div className="bg-brown-10/20 mt-2 rounded-md p-5 border border-zinc-200 w-full md:w-1/2">
                                        <p className="font-TTHovesM text-zinc-800 text-lg ">
                                            Gallery Added
                                        </p>
                                        <p className="text-zinc-600 text-sm">
                                            Client gallery attached! Jerome Bell
                                            can now review and access their
                                            photographs, all in one secure
                                            location.
                                        </p>
                                        <div
                                            onClick={() =>
                                                navigate(
                                                    `/dashboard/client-gallery/${project?.galleries[0]?.galleryId?._id}`,
                                                )
                                            }
                                            className="mt-2 cursor-pointer hover:shadow-sm gap-3 bg-white rounded-lg border border-zinc-200 flex items-center p-3">
                                            <div className=" h-20 w-20">
                                                <img
                                                    alt="gallery"
                                                    src={`${process.env.REACT_APP_SERVER_DOMAIN}/${project?.galleries[0]?.galleryId?.images[0]}`}
                                                    className="w-full h-full object-cover rounded-lg"
                                                />
                                            </div>
                                            <div className="">
                                                <p className="font-TTHovesM">
                                                    {
                                                        project?.galleries[0]
                                                            ?.galleryId?.title
                                                    }
                                                </p>
                                                <div className="flex text-sm gap-2 ">
                                                    <p>
                                                        <span className="font-TTHovesM">
                                                            {
                                                                project
                                                                    ?.galleries[0]
                                                                    ?.galleryId
                                                                    ?.images
                                                                    ?.length
                                                            }
                                                        </span>{' '}
                                                        <span className=" text-zinc-600 ">
                                                            Photographs
                                                        </span>
                                                    </p>
                                                    <p>
                                                        <span className="font-TTHovesM">
                                                            {formatFileSize(
                                                                project
                                                                    ?.galleries[0]
                                                                    ?.galleryId
                                                                    ?.size ?? 0,
                                                            )}
                                                        </span>{' '}
                                                        <span className=" text-zinc-600 ">
                                                            Size
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {!project?.galleries[0]
                                            ?.acceptDelivery && (
                                            <IconButton
                                                container="mt-3"
                                                icon={''}
                                                disabled={
                                                    !confirm ||
                                                    project?.invoice?.length ===
                                                        0
                                                }
                                                text="Accept Delivery"
                                                variant="primary"
                                                onClick={() => setIsOpen(true)}
                                            />
                                        )}
                                        {(!confirm ||
                                            project?.invoice?.length === 0) && (
                                            <p className="text-xs mt-2 font-TTHovesM text-zinc-700">
                                                The invoice(s) are pending
                                                payment or awaiting approval
                                                from the photographer. Please
                                                ensure all invoices are cleared
                                                to complete the booking.
                                            </p>
                                        )}
                                    </div>
                                ) : null}

                                {project?.projectListing?.contractCreated &&
                                    !project?.clientSignature && (
                                        <div className="bg-[#FCFFF6] mt-2 rounded-md p-5 border border-zinc-200 w-full md:w-1/2">
                                            <button className="flex items-center gap-1 bg-tale-10 font-TTHoves bg-opacity-50 text-white text-xs px-2 py-1.5 rounded-md uppercase">
                                                Next Step
                                                <ArrowRight className="h-3 w-3" />
                                            </button>
                                            <p className="font-TTHovesM text-zinc-800 text-lg mt-2">
                                                Sign a contract
                                            </p>
                                            <p className="text-zinc-600 text-sm">
                                                {project?.photographers
                                                    ?.length > 0
                                                    ? project?.photographers[0]
                                                          ?.firstName +
                                                      ' ' +
                                                      project?.photographers[0]
                                                          ?.lastName
                                                    : project?.proposalCreatedBy
                                                          ?.firstName +
                                                      ' ' +
                                                      project?.proposalCreatedBy
                                                          ?.lastName}{' '}
                                                has attached a contract to the
                                                project. To confirm the booking,
                                                please review and sign the
                                                contract. If you find any
                                                discrepancies or require
                                                modifications, feel free to
                                                request adjustments.
                                            </p>
                                            <IconButton
                                                container="mt-4"
                                                icon={''}
                                                text="Sign Contract"
                                                variant="primary"
                                                onClick={() =>
                                                    navigate(
                                                        `/dashboard/business/signature/${id}`,
                                                    )
                                                }
                                            />
                                        </div>
                                    )}
                                {!project?.projectListing?.contractCreated && (
                                    <div className="bg-[#FCFFF6] mt-2 rounded-md p-5 border border-zinc-200 w-full md:w-1/2">
                                        <button className="flex items-center gap-1 bg-tale-10 font-TTHoves bg-opacity-50 text-white text-xs px-2 py-1.5 rounded-md uppercase">
                                            Next Step
                                            <ArrowRight className="h-3 w-3" />
                                        </button>
                                        <p className="font-TTHovesM text-zinc-800 text-lg mt-2">
                                            Booking Contract
                                        </p>
                                        <p className="text-zinc-600 text-sm">
                                            Photographer will create a contract
                                            and sent it to you. If they agree
                                            with the project terms, countersign
                                            the contract to start the project!
                                            You can also request changes to
                                            ensure that you align on the project
                                            terms.
                                        </p>
                                        <IconButton
                                            container="mt-4"
                                            icon={
                                                <NotificationBing className="text-white h-5 w-5" />
                                            }
                                            text="Send Reminder"
                                            variant="primary"
                                            onClick={() => setAddContract(true)}
                                        />
                                    </div>
                                )}

                                <div className="relative flex flex-col gap-8 mt-6 ml-5 z-10">
                                    <div className="absolute h-full w-px bg-zinc-200 left-3 -top-6 -z-10" />

                                    {project?.projectListing?.contractCreated &&
                                        project?.clientSignature && (
                                            <div className="flex items-center gap-3 z-10">
                                                <CheckCircle className="text-[#E8C0A6] w-6 h-6" />
                                                <p className="text-zinc-600 font-TTHovesM text-sm">
                                                    Proposal accepted & signed
                                                    the contract
                                                </p>
                                            </div>
                                        )}
                                    {project?.projectListing
                                        ?.invoiceCreated && (
                                        <div className="flex items-center gap-3 z-10">
                                            <CheckCircle className="text-[#E8C0A6] w-6 h-6" />
                                            <p className="text-zinc-600 font-TTHovesM text-sm">
                                                Invoice Added
                                            </p>
                                        </div>
                                    )}

                                    {project?.projectListing
                                        ?.contractCreated && (
                                        <div className="flex items-center gap-3 z-10">
                                            <CheckCircle className="text-[#E8C0A6] w-6 h-6" />
                                            <p className="text-zinc-600 font-TTHovesM text-sm">
                                                Contract Added
                                            </p>
                                        </div>
                                    )}

                                    <div className="flex items-center gap-3">
                                        <CheckCircle className="text-[#E8C0A6] w-6 h-6" />
                                        <p className="text-zinc-600 font-TTHovesM text-sm">
                                            Project Created
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                        <Questionnaires
                            tab={params.get('tab') === 'questionnaires'}
                        />
                        <Contract tab={params.get('tab') === 'contract'} />
                        <Invoices tab={params.get('tab') === 'invoices'} />
                        <Notes tab={params.get('tab') === 'notes'} />
                    </>
                )}
                <div className="flex flex-col gap-3">
                    {/*<div className="hidden md:block w-[350px] bg-[#DEEDEE] rounded-xl border border-zinc-200 p-5">*/}
                    {/*    <p className="text-zinc-800 font-TTHovesM">*/}
                    {/*        Collaborate*/}
                    {/*    </p>*/}

                    {/*    <p className="mt-1 text-zinc-600 text-sm font-TTHovesM  leading-tight">*/}
                    {/*        Collaborate on job with other photographers*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                    <div className="bg-white rounded-xl border border-zinc-200 p-5">
                        <p className="text-zinc-500 text-[10px] font-TTHovesM tracking-wider">
                            PHOTOGRAPHER
                        </p>
                        <div className="flex gap-3 items-center mt-4">
                            <div className="cursor-pointer flex-shrink-0 h-12 w-12 rounded-full relative">
                                <img
                                    alt="profile"
                                    className="h-full w-full rounded-full object-cover object-top"
                                    src={
                                        project?.photographers?.length > 0
                                            ? `${process.env.REACT_APP_SERVER_DOMAIN}/${project?.photographers[0]?.profileImage}`
                                            : project?.proposalCreatedBy
                                                    ?.profileImage
                                              ? `${process.env.REACT_APP_SERVER_DOMAIN}/${project?.proposalCreatedBy?.profileImage}`
                                              : '/assets/images/blank.webp'
                                    }
                                />
                                <div className="absolute bottom-0.5 right-1 h-2 w-2 rounded-full bg-green-600" />
                            </div>
                            <div>
                                <div className="text-zinc-700 text-base font-TTHovesSB ">
                                    {project?.photographers?.length > 0
                                        ? project?.photographers[0]?.firstName +
                                          ' ' +
                                          project?.photographers[0]?.lastName
                                        : project?.proposalCreatedBy
                                              ?.firstName +
                                          ' ' +
                                          project?.proposalCreatedBy?.lastName}
                                </div>
                                <div className="photographers text-sm leading-[18px]">
                                    {project?.photographers?.length > 0
                                        ? project?.photographers[0]?.location
                                        : project?.proposalCreatedBy?.location}
                                </div>
                            </div>
                        </div>
                        <div className="h-px w-full my-4 bg-zinc-200" />
                        <div className="text-zinc-700 text-base font-TTHovesSB">
                            Pre-launch checklist
                        </div>
                        <div className="text-zinc-600 text-[13px] leading-[18px]">
                            Complete the checklist in oder to submit the job
                            quotation
                        </div>
                        <ProjectListing project={project} />
                    </div>
                    <div className=" bg-white rounded-xl border border-zinc-200 p-5">
                        <p className="text-zinc-700 text-base font-TTHovesSB">
                            Project Details
                        </p>

                        <div className="h-px w-full my-4 bg-zinc-200" />
                        <p className="text-zinc-500 text-[10px] font-TTHovesM tracking-wider">
                            TIMELINE
                        </p>
                        <p className="font-TTHovesM text-zinc-800 mt-0.5">
                            {new Date(project?.shootDate).toDateString()}
                        </p>
                        <p className="text-zinc-500 mt-3 text-[10px] font-TTHovesM tracking-wider">
                            PAYMENT SCHEDULE
                        </p>
                        <p className="font-TTHovesM text-zinc-800 mt-0.5">
                            {project?.feeStructure}
                        </p>
                        <div className="flex justify-between">
                            <div>
                                <p className="text-zinc-500 mt-3 text-[10px] font-TTHovesM tracking-wider">
                                    TOTAL PROJECT FEE
                                </p>
                                <p className="font-TTHovesM text-zinc-800 mt-0.5">
                                    {project?.currencySymbol}{' '}
                                    {project?.totalFee}
                                </p>
                            </div>
                            <div>
                                <p className="text-zinc-500 mt-3 text-[10px] font-TTHovesM tracking-wider">
                                    SURRA CHARGES
                                </p>
                                <p className="font-TTHovesM text-zinc-800 mt-0.5">
                                    {project?.currencySymbol} 000
                                </p>
                            </div>
                        </div>

                        <p className="text-zinc-500 mt-3 text-[10px] font-TTHovesM tracking-wider">
                            YOU WILL GET
                        </p>
                        <p className="font-TTHovesM text-zinc-800 mt-0.5">
                            {project?.currencySymbol} {project?.totalFee}
                        </p>
                    </div>
                </div>
            </div>
            <ConfirmModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onConfirm={handleConfirm}
                title="Accept Delivery?"
                confirmButton="Accept"
                description="Accept gallery and view gallery images"
            />
        </Fragment>
    )
}
