import React, { useEffect, useState } from 'react'
import UserService from 'services/user.service'
import { BookingTile } from 'pages/dashboard/business/Booking'
import { RefreshCircle } from 'iconsax-react'

const service = new UserService()

export const PastBookings = () => {
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        service
            .gePastBookings()
            .then(resp => {
                setData(
                    resp.data.data.sort((a, b) => {
                        const dateA = new Date(b.createdAt).getTime()
                        const dateB = new Date(a.createdAt).getTime()

                        return dateA - dateB
                    }),
                )
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    return (
        <div>
            {!loading && data?.length > 0 ? (
                <BookingTile data={data} />
            ) : !loading ? (
                <p>No Past Bookings</p>
            ) : (
                <RefreshCircle className="text-tale-10 animate-spin mx-auto h-12 w-12" />
            )}
        </div>
    )
}
