import { Photo3, heartWhite } from 'utils/staticData'

const PhotoCard = ({ photo, user, setSelectedGallery, selectedPhotgrapherImages }) => {
    return (
        <div className="relative w-[190px] rounded-xl overflow-hidden group">
            <img
                src={`https://api.suura.com/${photo}`}
                alt="Photo"
                className="w-full h-[240px] object-cover"
            />
            <div className={`absolute inset-0 bg-[rgb(0_0_0_/_78%)] flex flex-col justify-between items-baseline text-white p-[14px] ${selectedPhotgrapherImages.includes(photo) ? "opacity-100" : "opacity-0 group-hover:opacity-100 "}transition-all duration-700`}>
                <div>
                    <button type="button" onClick={() => setSelectedGallery(photo)}>
                        <img
                            src={heartWhite}
                            className={
                                selectedPhotgrapherImages.includes(photo)
                                    ? 'filter-red'
                                    : ''
                            }
                            alt="Heart"
                        />
                    </button>
                </div>
                <div className="flex gap-[6px] items-center">
                    <img
                        src={`https://api.suura.com/${user.profileImage}`}
                        alt="DP"
                        className="w-6 h-6 object-cover rounded-full"
                    />
                    <p className="text-[12px] font-TTHovesM">{`${user?.firstName} ${user?.lastName} 🔥`} </p>
                </div>
            </div>
        </div>
    )
}

export default PhotoCard
