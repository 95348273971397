import React from "react"

const BalanceTile: React.FC<{ title?: string, date?: string, balance?: string }> = ({ title, date, balance }) => {
    return (
        <div className="border border-[#EAECF0] rounded-xl py-[19px] px-4 bg-white">
            <h4 className="text-base text-[#14171F] font-TTHovesSB leading-[24px] mb-1">{title}</h4>
            <p className=" text-[#6C6B83] text-[13px] font-TTHovesM leading-[16px] mb-9">{date}</p>
            <p className="text-[30px] leading-[32px] font-TTHovesSB text-[#14171F]">{balance}</p>
        </div>
    )
}

export default BalanceTile
