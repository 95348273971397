import { Calendar, Edit, RefreshCircle, Trash } from 'iconsax-react'
import { TbDots } from '../../css/icons/icons'
import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import UserService from 'services/user.service'
import { ConfirmModal } from 'components/common/ConfirmModal'
import { useNotifications } from 'hooks/useNotification'
import { DataNotFound } from 'components/common/DataNotFound'
import { Badge } from 'components/landing/photographer/StatusBadge'
import { StatusColorMap } from 'utils/maps'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'

const service = new UserService()

export const QuotesTable = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [quotes, setQuotes] = useState<any>([])
    const [quoteId, setQuoteId] = useState('')

    const { successMessage } = useNotifications()
    const navigate = useNavigate()
    const user = useSelector(userSelector)

    useEffect(() => {
        setLoading(true)
        service
            .getQuotes()
            .then(resp => {
                setQuotes(resp.data.data)
            })
            .catch(error => {
                console.log('Quotes', error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const handleDelete = (id: string) => {
        setIsOpen(true)
        setQuoteId(id)
    }

    const onConfirm = () => {
        service.deleteQuote(quoteId).then(resp => {
            if (resp.data.success) {
                const newData = [...quotes].filter(
                    (item: any) => item._id !== quoteId,
                )
                setQuotes(newData)
                successMessage('Quote Deleted Successfully')
                setIsOpen(false)
            }
        })
    }

    return (
        <div className="min-w-full">
            <div className="hidden md:flex items-center text-zinc-500 font-TTHovesM p-4 mt-1">
                <p className="w-1/3">Quote</p>
                <p className="flex-1">Created By</p>
                <p className="flex-1">Type</p>
                <p className="flex-1">Status</p>
                <p className="flex-1" />
            </div>

            {/* Custom Table */}
            {!loading && quotes.length === 0 && (
                <DataNotFound title="You do not have any pending quotes." />
            )}
            {loading ? (
                <div className="h-72 w-full flex items-center justify-center">
                    <RefreshCircle className="h-12 w-12 text-tale-10 animate-spin" />
                </div>
            ) : (
                <div className="flex flex-col gap-4 mt-1 min-w-full">
                    {/* Row 1 */}
                    {quotes.map(item => (
                        <div
                            key={item._id}
                            className="hidden md:flex items-center border border-zinc-200 rounded-lg p-4">
                            <div
                                onClick={() =>
                                    navigate(`/dashboard/quote/${item._id}`)
                                }
                                className="cursor-pointer  w-1/3">
                                <p className="hover:text-tale-10 text-slate-800 font-TTHovesM leading-[21px]">
                                    {item.title}
                                </p>
                                <div className="flex gap-2 items-center mt-1">
                                    <Calendar className="h-4 w-4 text-zinc-600" />
                                    <p className="text-zinc-600 text-sm">
                                        {new Date(item?.date).toDateString()}
                                    </p>
                                </div>
                            </div>
                            <div className="flex-1">
                                <div>
                                    <span className="text-slate-800 font-TTHovesM">
                                        {item?.user?.firstName +
                                            ' ' +
                                            item?.user?.lastName}{' '}
                                    </span>
                                    {item?.user?._id === user?._id && (
                                        <span className="text-zinc-400 text-xs font-TTHovesM">
                                            (You)
                                        </span>
                                    )}
                                </div>
                                <p className="text-zinc-600 text-sm mt-0.5">
                                    {new Date(item?.date).toDateString()}
                                </p>
                            </div>
                            <div className="flex-1">
                                <p className="text-center text-slate-700 text-sm bg-zinc-50 px-6 py-2 rounded-md border border-zinc-200 inline-flex">
                                    {item?.serviceType?.name}
                                </p>
                            </div>
                            <div className="flex-1">
                                <Badge
                                    text={item?.status}
                                    type={StatusColorMap[item.status]}
                                />
                            </div>
                            <div className="flex-1">
                                {item?.user?._id === user?._id && (
                                    <Menu as="div" className="relative ">
                                        {({ open }) => (
                                            <>
                                                <Menu.Button className="inline-flex p-2 bg-white rounded-lg  border border-zinc-300">
                                                    <TbDots />
                                                </Menu.Button>
                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    enter="transition ease-out duration-200"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95">
                                                    <Menu.Items
                                                        static
                                                        className="flex flex-col absolute -left-[75px] mt-2 w-28 z-10 rounded-md shadow-lg divide-y divide-zinc-200 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <Menu.Item>
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    handleDelete(
                                                                        item._id,
                                                                    )
                                                                }
                                                                className="p-3 text-sm hover:text-red/90 text-gray-800 text-red flex items-center gap-x-2">
                                                                <Trash className="text-red" />
                                                                Delete
                                                            </button>
                                                        </Menu.Item>{' '}
                                                        <Menu.Item>
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/dashboard/add-quote?id=${item._id}`,
                                                                    )
                                                                }
                                                                className="p-3 text-sm  text-gray-800  flex items-center gap-x-2">
                                                                <Edit className="text-blue-600" />
                                                                Edit
                                                            </button>
                                                        </Menu.Item>
                                                    </Menu.Items>
                                                </Transition>
                                            </>
                                        )}
                                    </Menu>
                                )}
                            </div>
                        </div>
                    ))}

                    {quotes.map(item => (
                        <div
                            key={item._id}
                            className="flex-col gap-4 md:hidden items-center border border-zinc-200 rounded-lg p-4">
                            <div className="w-full flex justify-between">
                                <div className="w-full">
                                    <p
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/quote/${item._id}`,
                                            )
                                        }
                                        className="text-slate-800 hover:text-tale-10 font-TTHovesM leading-[21px] cursor-pointer">
                                        {item.title}
                                    </p>

                                    <div className="flex gap-2 items-center mt-1">
                                        <Calendar className="h-4 w-4 text-zinc-600" />
                                        <p className="text-zinc-600 text-sm">
                                            {new Date(
                                                item?.date,
                                            ).toDateString()}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex-1">
                                    <Menu as="div" className="relative ">
                                        {({ open }) => (
                                            <>
                                                <Menu.Button className="inline-flex p-2 bg-white rounded-lg  border border-zinc-300">
                                                    <TbDots />
                                                </Menu.Button>
                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    enter="transition ease-out duration-200"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95">
                                                    <Menu.Items
                                                        static
                                                        className="flex flex-col absolute -left-[75px] mt-2 w-28 z-10 rounded-md shadow-lg divide-y divide-zinc-200 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <Menu.Item>
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    handleDelete(
                                                                        item._id,
                                                                    )
                                                                }
                                                                className="p-3 text-sm hover:text-red/90 text-gray-800 text-red flex items-center gap-x-2">
                                                                <Trash className="text-red" />
                                                                Delete
                                                            </button>
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/dashboard/add-quote?id=${item._id}`,
                                                                    )
                                                                }
                                                                className="p-3 text-sm  text-gray-800  flex items-center gap-x-2">
                                                                <Edit className="text-blue-600" />
                                                                Edit
                                                            </button>
                                                        </Menu.Item>
                                                    </Menu.Items>
                                                </Transition>
                                            </>
                                        )}
                                    </Menu>
                                </div>
                            </div>
                            <div className="flex-1 mt-4">
                                <div>
                                    <span className="text-slate-800 font-TTHovesM">
                                        {item?.user?.firstName +
                                            ' ' +
                                            item?.user?.lastName}{' '}
                                    </span>
                                    <span className="text-zinc-400 text-xs font-TTHovesM">
                                        (You)
                                    </span>
                                </div>
                                <p className="text-zinc-600 text-sm mt-0.5">
                                    {new Date(item?.date).toDateString()}
                                </p>
                            </div>
                            <div className="flex-1 mt-4">
                                <p className="text-center text-slate-700 text-sm bg-zinc-50 px-6 py-2 rounded-md border border-zinc-200 inline-flex">
                                    {item?.serviceType?.name}
                                </p>
                            </div>
                            <div className="flex-1 mt-2">
                                <Badge
                                    text={item?.status}
                                    type={StatusColorMap[item.status]}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {/* Custom Table End */}

            <ConfirmModal
                title="Are you sure?"
                description="This operation can not be undone."
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onConfirm={onConfirm}
            />
        </div>
    )
}
