import { Add, ArrowRight2, Home2 } from 'iconsax-react'
import { Fragment, useEffect, useState } from 'react'
import { BiLinkExternal } from '../../../css/icons/icons'
import { AddClientModal } from 'components/dashboard/business/AddClientModal'
import { ClientsTable } from 'components/tables/ClientsTable'
import UserService from 'services/user.service'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'

const service = new UserService()

export const Client = () => {
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const [clients, setClients] = useState([])
    const user = useSelector(userSelector)

    useEffect(() => {
        setLoading(true)
        service
            .getMyContacts()
            .then(resp => {
                setClients(
                    resp.data.data.filter(item => item._id !== user?._id),
                )
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }, [])

    return (
        <Fragment>
            <div className="flex items-center justify-between">
                <div className="hidden items-center gap-2 md:flex">
                    <Home2 className="h-5 w-5" />
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                        Business
                    </div>
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                        {user?.userType === 'Photographer'
                            ? 'Clients'
                            : 'Photographers'}
                    </div>
                </div>
                {user?.userType === 'Photographer' ? (
                    <div className="flex items-center gap-3">
                        <button className="px-4 py-2.5 bg-white rounded-lg shadow border border-zinc-300 justify-center items-center gap-3.5 inline-flex">
                            <BiLinkExternal className="h-5 w-5 text-tale-10" />
                            <span className="text-slate-700 text-sm font-TTHovesM">
                                Invite via link
                            </span>
                        </button>
                        <button
                            onClick={() => setShowModal(true)}
                            type="button"
                            className="px-4 py-2.5 bg-tale-10 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                            <Add className="text-white h-5 w-5" />
                            <span className="text-white text-sm font-TTHovesM ">
                                Add New Client
                            </span>
                        </button>
                    </div>
                ) : (
                    <div className="flex items-center gap-3">
                        <button className="px-4 py-2.5 bg-white rounded-lg shadow border border-zinc-300 justify-center items-center gap-3.5 inline-flex">
                            <BiLinkExternal className="h-5 w-5 text-tale-10" />
                            <span className="text-slate-700 text-sm font-TTHovesM">
                                Invite via link
                            </span>
                        </button>
                        <button
                            onClick={() => setShowModal(true)}
                            type="button"
                            className="px-4 py-2.5 bg-tale-10 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                            <Add className="text-white h-5 w-5" />
                            <span className="text-white text-sm font-TTHovesM ">
                                Add New Photographer
                            </span>
                        </button>
                    </div>
                )}
            </div>
            <div className="bg-white rounded-xl border border-zinc-200 mt-6 p-5 w-full">
                <div className="border-b border-zinc-200 pb-5">
                    <p className="text-zinc-900 text-lg font-TTHovesM">
                        {user?.userType === 'Photographer'
                            ? 'Clients'
                            : 'Photographers'}
                    </p>
                    <p className="text-slate-600 text-sm leading-tight">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                    </p>
                </div>

                <ClientsTable
                    data={clients}
                    setData={setClients}
                    loading={loading}
                />
            </div>
            <AddClientModal
                title={
                    user?.userType === 'Photographer'
                        ? 'Client'
                        : 'Photographer'
                }
                showModal={showModal}
                setShowModal={setShowModal}
                data={clients}
                setData={setClients}
            />
        </Fragment>
    )
}
