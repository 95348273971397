import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    balance: 0,
}

const balanceDetailsSlice = createSlice({
    name: 'balance',
    initialState,
    reducers: {
        setBalanceInStore: (state, action) => {
            state.balance = action.payload
        },
    },
})

export const { setBalanceInStore } = balanceDetailsSlice.actions
export default balanceDetailsSlice.reducer
