import { useState } from "react"

const ProfileChoices = ({ user }) => {
    const [selectedSession, setSelectedSession] = useState(user.sessions?.[0]?.session?.name)
    const array = new Array(20).fill('')

    return (
        <div className="pt-5 flex flex-col gap-3">
            <span className="font-TTHovesM text-[#131224]">
                {'Portfolio choices'}
            </span>
            <div className="flex gap-[10px] mb-[32px]">
                {user.sessions.map((item, key) => (
                    <button
                        type="button"
                        onClick={() => setSelectedSession(item?.session?.name)}
                        key={key}
                        className={`h-[36px] hover:bg-[#373532] hover:text-white ${selectedSession === item?.session?.name ? "border-[#373532] bg-[#373532] text-white" : "border-[#BCC6CF]"} hover:border-[#373532] text-sm flex items-center px-[13px] border  rounded-lg`}>
                        {item?.session?.name}
                    </button>
                ))}
            </div>

            <div className="w-full overflow-x-auto relative">
                <div className="flex gap-[14px] min-w-max">
                    {array.map((item, key) => (
                        <div className="" key={key}>
                            <img
                                src={`${process.env.REACT_APP_SERVER_DOMAIN}/${user.coverImage}`}
                                alt=""
                                className="max-h-[250px] object-contain rounded-[10px]"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ProfileChoices
