import { Modal } from 'components/common/Modal'
import { Input } from 'components/form/Input/Input'
import { TextArea } from 'components/form/Input/TextArea'
import React, { useState } from 'react'
import UserService from 'services/user.service'
import { useNotifications } from 'hooks/useNotification'
import { useNavigate } from 'react-router-dom'

const service = new UserService()

export const ApplyQuoteModal = ({
    sendQuoteModal,
    setQuoteModal,
    selected,

    setSelected,
    fetchData,
}) => {
    const { successMessage } = useNotifications()
    const navigate = useNavigate()
    const [state, setState] = useState({
        price: '',
        message: '',
    })
    const handleSendQuote = e => {
        e.preventDefault()

        if (state.price) {
            service.payForJob({ quoteId: selected?._id })
                .then(res => {
                    service.sendQuote({ ...state, quoteId: selected?._id })
                        .then(() => {
                            if (fetchData) {
                                fetchData()
                            }
                            navigate("/dashboard/browse/jobs")
                            successMessage('Quotation sent successfully')
                            setQuoteModal(false)
                            setState({
                                price: '',
                                message: '',
                            })
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }).catch(() => {
                    console.log("Error")
                })
        }


    }

    return (
        <Modal
            showModal={sendQuoteModal}
            setShowModal={setQuoteModal}
            title="Apply for job"
            width="w-full md:w-1/3"
            footerButton="Apply"
            handleClickPrimary={handleSendQuote}
            headerIcon="">
            <div className="p-5 flex flex-col gap-5">
                <div className="grid grid-cols-3 gap-16">
                    <div className="">
                        <div className="text-zinc-600 text-[13px] ">
                            Location
                        </div>
                        <div className="text-slate-800 text-sm font-TTHovesM">
                            {selected?.locationString}
                        </div>
                    </div>
                    <div className="justify-start ">
                        <div className="text-zinc-600 text-[13px] ">Budget</div>
                        <div className="text-slate-800 text-sm font-TTHovesM">
                            {selected?.user?.currencySymbol} {selected?.budget}{' '}
                            {selected?.rate === 'Hourly' && '/hr'}
                        </div>
                    </div>
                    <div className="">
                        <div className="text-zinc-600 text-[13px] ">
                            Dated Posted
                        </div>
                        <div className="text-slate-800 text-sm font-TTHovesM">
                            {new Date(selected?.date).toDateString()}
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-16">
                    <div className="">
                        <div className="text-zinc-600 text-[13px] ">
                            Shoot Date
                        </div>
                        <div className="text-slate-800 text-sm font-TTHovesM">
                            {new Date(selected?.shootDate).toDateString()}
                        </div>
                    </div>
                    <div className="">
                        <div className="text-zinc-600 text-[13px] ">
                            Shoot Time
                        </div>
                        <div className="text-slate-800 text-sm font-TTHovesM">
                            {selected?.shootTime}
                        </div>
                    </div>
                    <div className="">
                        <div className="text-zinc-600 text-[13px] ">
                            Quotes Received
                        </div>
                        <div className="text-slate-800 text-sm font-TTHovesM">
                            0 / {selected?.quoteLimit}
                        </div>
                    </div>
                </div>
                <hr className="text-zinc-200" />
                <div className="flex flex-col md:flex-row gap-1 md:gap-20">
                    <label className="w-32 md:w-40 text-slate-700 text-sm font-TTHovesM">
                        Quote Price*
                    </label>
                    <Input
                        type="number"
                        onChange={e => {
                            setState({
                                ...state,
                                price: e.target.value,
                            })
                        }}
                        value={state.price}
                        placeholder="$ | 0.0"
                    />
                </div>
                <div className="flex flex-col md:flex-row gap-1 md:gap-20">
                    <label className="w-32 md:w-40 text-slate-700 text-sm font-TTHovesM">
                        Your Message
                    </label>
                    <TextArea
                        onChange={e => {
                            setState({
                                ...state,
                                message: e,
                            })
                        }}
                        value={state.message}
                    />
                </div>
            </div>
        </Modal>
    )
}
