import React from 'react'
import styles from 'pages/auth/Auth.module.css'
import { useFormik } from 'formik'
import TimezoneSelect from 'react-timezone-select'
import { components } from 'react-select'
import { Input } from 'components/form/Input/Input'
import { Button } from 'components/form/Button/Button'
import { SelectArrowIcon } from '../../css/icons/icons'
import UserService from 'services/user.service'
import { useSelector } from 'react-redux'
import { loginSuccess, setUser } from '../../redux/reducers/authReducer'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { useNotifications } from 'hooks/useNotification'
import { ProfileValidation } from 'utils/validations'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import { LocationSelect } from 'components/form/LocationSelect'
import AuthService from 'services/auth.service'
import { useNavigate } from 'react-router-dom'
import { redirectTo } from 'utils/links'
import { setItem } from 'services/localStorage.service'
import * as Yup from 'yup'

const service = new UserService()
const authService = new AuthService()

export const ClientSignup = () => {
    const dispatch = useThunkDispatch()
    const { successMessage } = useNotifications()
    const navigate = useNavigate()

    const { data } = useSelector((state: any) => state.project)

    const {
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        values,
        touched,
        errors,
    } = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            businessName: '',
            userName: '',
            password: '',
            location: '',
            timezone: '',
            currency: '€ - Euro',
        },
        validationSchema: Yup.object().shape({
            firstName: Yup.string()
                .required('Please enter first name')
                .matches(
                    /^[a-zA-Z\s]*$/g,
                    'Must not include space and special characters',
                ),
            lastName: Yup.string()
                .required('Please enter last name')
                .matches(
                    /^[a-zA-Z\s]*$/g,
                    'Must not include space and special characters',
                ),
            userName: Yup.string().required('Please enter user name'),
            email: Yup.string()
                .email('Enter a valid email address')
                .matches(
                    /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    'Enter a valid email address',
                )
                .required('Please enter email address'),
            password: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .matches(
                    /[A-Z]/,
                    'Password must contain at least one uppercase letter',
                )
                .matches(/[0-9]/, 'Password must contain at least one number')
                .matches(
                    /[!@#$%^&*(),.?":{}|<>]/,
                    'Password must contain at least one special character',
                )
                .required('Password is required'),
        }),
        onSubmit: values => {
            authService
                .signup({
                    email: values.email,
                    password: values.password,
                    userType: 'Client',
                })
                .then(re => {
                    dispatch(
                        loginSuccess({
                            ...re?.data?.data,
                            token: re.data.token,
                        }),
                    )
                    setItem('token', re.data.token)
                    setTimeout(() => {
                        service
                            .createProfile(values)
                            .then(resp => {
                                dispatch(setUser(resp?.data?.data))
                                successMessage('Profile created successfully')
                                service
                                    .createQuote(data, resp.data.data._id)
                                    .then(res => {
                                        redirectTo(
                                            `/dashboard/quote/${res.data.data._id}`,
                                        )
                                    })
                                    .catch(error => {
                                        console.log({ error })
                                    })
                                    .finally(() => {
                                        setSubmitting(false)
                                    })
                            })
                            .catch(error => {
                                console.log(error)
                            })
                    }, 1000)
                })
                .catch(error => {
                    console.log({ error })
                })
            // service
        },
    })

    return (
        <div
            id="page-0"
            className="max-w-3xl px-3 h-full  scrollbar-hidden mx-auto overflow-y-auto">
            <div className="w-full mt-8">
                <p className="leading-10 text-body-caption-bold text-dark-8 font-OTabolas">
                    Welcome to Suura web platform!
                </p>
                <p className={`mt-1.5 ${styles.messageText}`}>
                    Let's start with the basics.{' '}
                </p>
            </div>
            <div className="mt-8">
                <form className="" onSubmit={handleSubmit}>
                    <div className="grid grid-cols-2 gap-4 pb-3">
                        <Input
                            type="text"
                            placeholder="First name"
                            label="First name"
                            name="firstName"
                            onChange={handleChange('firstName')}
                            onBlur={handleBlur('firstName')}
                            error={touched.firstName && errors.firstName}
                            value={values.firstName}
                        />
                        <Input
                            name="lastName"
                            onChange={handleChange('lastName')}
                            onBlur={handleBlur('lastName')}
                            error={touched.lastName && errors.lastName}
                            value={values.lastName}
                            type="text"
                            placeholder="Last name"
                            label="Last name"
                        />
                    </div>
                    <div className="py-3">
                        <Input
                            type="text"
                            placeholder="eg. johnsmith"
                            name="userName"
                            value={values.userName}
                            error={touched.userName && errors.userName}
                            onChange={handleChange('userName')}
                            onBlur={handleBlur('userName')}
                            label="Username"
                        />
                    </div>
                    <div className="py-3">
                        <Input
                            type="text"
                            value={values.email}
                            name="email"
                            placeholder="eg. johnsmith@gmail.com"
                            error={touched.email && errors.email}
                            onChange={handleChange('email')}
                            onBlur={handleBlur('email')}
                            label="Email address"
                        />
                    </div>
                    <div className="py-3">
                        <Input
                            type="password"
                            placeholder="Enter your password"
                            value={values.password}
                            error={touched.password && errors.password}
                            onBlur={handleBlur('password')}
                            name="password"
                            onChange={handleChange('password')}
                            label="Password"
                        />
                    </div>

                    {/*<p className="bg-creame-1 text-gray text-base py-1 ">*/}
                    {/*    Note: Usernames must be unique and cannot contain spaces*/}
                    {/*    or special characters.*/}
                    {/*</p>*/}
                    {/*<div className="py-3">*/}
                    {/*    <LocationSelect*/}
                    {/*        value={values.location}*/}
                    {/*        onChange={value => setFieldValue('location', value)}*/}
                    {/*    />*/}
                    {/*    {touched.location && errors?.location && (*/}
                    {/*        <p className="text-red mt-0.5 ml-1 font-TTHoves text-sm">*/}
                    {/*            {errors?.location as string}*/}
                    {/*        </p>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                    {/*<div className="grid grid-cols-2 gap-4 py-3">*/}
                    {/*    <div className="relative">*/}
                    {/*        <label className="absolute -top-3 left-4 bg-creame-1 z-10 text-gray">*/}
                    {/*            Time Zone*/}
                    {/*        </label>*/}
                    {/*        <TimezoneSelect*/}
                    {/*            value={values.timezone}*/}
                    {/*            onChange={(data: any) =>*/}
                    {/*                setFieldValue('timezone', data?.value)*/}
                    {/*            }*/}
                    {/*            className="react-select"*/}
                    {/*            name="timezone"*/}
                    {/*            placeholder="Select time zone"*/}
                    {/*            components={{ DropdownIndicator }}*/}
                    {/*            onBlur={() => setFieldTouched('timezone')}*/}
                    {/*            classNamePrefix="react-select"*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <ReactSelect*/}
                    {/*        placeholder="Select currency"*/}
                    {/*        value={values.currency}*/}
                    {/*        options={[*/}
                    {/*            // { value: '$ - Dollar', label: '$ - Dollar' },*/}
                    {/*            { value: '€ - Euro', label: '€ - Euro' },*/}
                    {/*            // {*/}
                    {/*            //     value: 'AED - Dirham',*/}
                    {/*            //     label: 'AED - Dirham',*/}
                    {/*            // },*/}
                    {/*            // { value: '£ - Pound', label: '£ - Pound' },*/}
                    {/*            // { value: 'Riyal', label: 'Riyal' },*/}
                    {/*        ]}*/}
                    {/*        onChange={value => setFieldValue('currency', value)}*/}
                    {/*        label="Currency"*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className="py-3">
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            className="bg-brown-10 disabled:cursor-not-allowed disabled:bg-opacity-70 border-brown-10 text-white px-7 h-12 w-full">
                            {isSubmitting ? 'Please wait...' : 'Continue'}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <SelectArrowIcon className="h-5 w-5" />
        </components.DropdownIndicator>
    )
}
