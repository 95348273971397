import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { ArrowLeft, ArrowRight2, Home2, Link1 } from 'iconsax-react'
import { BiLinkExternal } from '../../../css/icons/icons'
import { useNavigate, useParams } from 'react-router-dom'
import UserService from 'services/user.service'
import { Modal } from 'components/common/Modal'
import PasswordForm from './PasswordForm'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { useNotifications } from 'hooks/useNotification'

const service = new UserService()

export const ClientSpecificGallery = () => {
    const [showModal, setShowModal] = useState(false)
    const [imageUrl, setImageUrl] = useState('')
    const [data, setData] = useState<any>(null)
    const [showGallery, setShowGallery] = useState(false)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const { errorMessage } = useNotifications()

    const { id } = useParams()

    const fetchData = useCallback(() => {
        setLoading(true)
        service
            .getSpecificGallery(id ?? '')
            .then(resp => {
                setData(resp.data.data)
                setLoading(false)

                setShowGallery(
                    Boolean(resp.data.data.passwordProtected === 'No'),
                )
                setImageUrl(resp.data.data.images[0])
            })
            .catch(error => {
                console.log(error)
                setLoading(false)
            })
    }, [id])

    useEffect(() => {
        fetchData()
    }, [])

    const downloadImagesAsZip = async imageUrls => {
        const zip = new JSZip()
        const imagesFolder = zip.folder('suura-images') // Create a folder inside the zip

        if (!imagesFolder) {
            console.error('Failed to create the images folder in the ZIP.')
            return
        }

        for (let imageUrl of imageUrls) {
            imageUrl = `${process.env.REACT_APP_SERVER_DOMAIN}/${imageUrl}`
            try {
                // Fetch each image as a Blob
                const response = await fetch(imageUrl)
                if (!response.ok) {
                    console.error(`Failed to fetch the image: ${imageUrl}`)
                    continue
                }
                const blob = await response.blob()

                // Add the image to the zip folder
                const fileName = imageUrl.split('/').pop() // Extract the file name from the URL
                imagesFolder.file(fileName || 'image.png', blob)
            } catch (error) {
                console.error('Error adding image to zip:', error)
            }
        }

        // Generate the zip file and trigger download
        zip.generateAsync({ type: 'blob' }).then(content => {
            saveAs(content, 'suura-images.zip') // Save the zip file
        })
    }

    const downloadImage = async suuraImageUrl => {
        try {
            // Fetch the image as a blob
            const response = await fetch(suuraImageUrl, { mode: 'cors' })
            if (!response.ok) {
                throw new Error('Failed to fetch the image')
            }
            const blob = await response.blob()

            // Create a temporary URL for the blob
            const blobUrl = URL.createObjectURL(blob)

            // Create an anchor element
            const anchor = document.createElement('a')
            anchor.href = blobUrl

            // Set the 'download' attribute with a filename
            const fileName = suuraImageUrl.split('/').pop()
            anchor.download = fileName

            // Trigger the download
            document.body.appendChild(anchor)
            anchor.click()

            // Clean up
            document.body.removeChild(anchor)
            URL.revokeObjectURL(blobUrl)
        } catch (error) {
            console.error('Error downloading the image:', error)
        }
    }

    const downloadAll = () =>
        data?.images && data?.images.length
            ? downloadImagesAsZip(data?.images)
            : undefined

    return (
        <Fragment>
            <div className="flex items-center justify-between">
                <div className="hidden items-center gap-2 md:flex">
                    <Home2 className="h-5 w-5" />
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div
                        onClick={() => navigate(-1)}
                        className="px-2 py-1 text-slate-600 cursor-pointer text-sm font-TTHovesM leading-tight">
                        Galleries
                    </div>
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                        {data?.title ?? ''}
                    </div>
                </div>

                <div className="flex gap-2">
                    <button
                        type="button"
                        className="px-4 py-2.5 bg-white border border-zinc-200 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                        <Link1 className="text-tale-10 h-5 w-5" />
                        <span className="text-zinc-700 text-sm font-TTHovesM ">
                            Copy Link
                        </span>
                    </button>
                    <button
                        type="button"
                        className="px-4 py-2.5 bg-white border border-zinc-200 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="w-4 h-4 text-tale-10">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                            />
                        </svg>

                        <span className="text-zinc-700 text-sm font-TTHovesM ">
                            Share
                        </span>
                    </button>
                    <button
                        onClick={e => {
                            if (data.images.length === 0) {
                                errorMessage('This gallery contains no images')
                            } else {
                                e.preventDefault()
                                window.open(
                                    `/dashboard/preview/${id}`,
                                    '_blank',
                                    'noopener,noreferrer',
                                )
                            }
                        }}
                        className="px-4 py-2.5 bg-white border border-zinc-200 rounded-lg shadow justify-center items-center gap-2 inline-flex"
                        rel="noreferrer">
                        <BiLinkExternal className="text-tale-10 h-5 w-5 font-TTHoves" />
                        <span className="text-zinc-700 text-sm font-TTHovesM ">
                            Preview Gallery
                        </span>
                    </button>
                </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4 mt-6">
                <div className="bg-white rounded-xl border border-zinc-200 p-5 w-full">
                    <div className="flex flex-col md:flex-row">
                        <p className="text-zinc-900 text-lg font-TTHovesM">
                            {showGallery
                                ? 'Manage Photos'
                                : !loading
                                    ? 'Gallery Protected'
                                    : ''}
                        </p>
                    </div>
                    {!showGallery && !loading ? (
                        <label className="text-slate-600 text-sm leading-tight">
                            {
                                'This gallery is protected! Enter password to show gallery.'
                            }
                        </label>
                    ) : (
                        ''
                    )}

                    <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mt-4">
                        {showGallery
                            ? data?.images.map(image => (
                                <div
                                    key={image}
                                    onClick={() => setShowModal(true)}
                                    className="flex-1 min-h-fit relative">
                                    <img
                                        draggable={false}
                                        src={`${process.env
                                            .REACT_APP_SERVER_DOMAIN
                                            }/${image.trim()}`}
                                        alt="library"
                                        className="h-full pointer-events-none w-full object-contain "
                                    />
                                </div>
                            ))
                            : !loading && (
                                <PasswordForm showGallery={setShowGallery} />
                            )}
                    </div>
                </div>
                <div className="flex flex-col w-full md:w-1/3 gap-4">
                    <div className="bg-white rounded-xl border border-zinc-200 p-5">
                        <p className="font-TTHovesM text-zinc-900 text-lg">
                            Used in projects
                        </p>
                    </div>
                    <div className="bg-white rounded-xl border border-zinc-200 p-5 ">
                        <p className="font-TTHovesM text-zinc-900 text-lg">
                            Shared with clients
                        </p>
                    </div>
                </div>
            </div>
            <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                hideCross={true}
                showBottom={false}
                showDescription={false}
                width="w-full md:w-[1100px]">
                <div className="p-[32px] pt-[20px]">
                    <div className="flex items-center justify-between">

                        <button
                            onClick={() => setShowModal(false)}
                            className="flex rounded-lg font-TTHovesM items-center text-primary gap-2 shadow-sm border border-zinc-200 px-4 py-2 text-sm">
                            <ArrowLeft className="h-5 w-5" />
                            <span>Back</span>
                        </button>

                        {data?.allowDownload === "Yes" ?
                            <button
                                type="button"
                                onClick={() => downloadAll()}
                                className="flex gap-2 text-sm font-TTHovesL text-primary">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="icon icon-tabler icons-tabler-outline icon-tabler-download">
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                    />
                                    <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                                    <path d="M7 11l5 5l5 -5" />
                                    <path d="M12 4l0 12" />
                                </svg>
                                <span className="underline underline-offset-4">
                                    Download Images
                                </span>
                            </button> : null}
                    </div>

                    <div className="flex gap-4 mt-2 ">
                        <div className="flex-1  group  flex items-center relative justify-center">
                            <img
                                src={`${process.env.REACT_APP_SERVER_DOMAIN
                                    }/${imageUrl.trim()}`}
                                alt="library"
                                className="h-[600px] w-full object-contain "
                            />

                            {data?.allowDownload === "Yes" ?
                                <div
                                    className={`absolute rounded-lg bottom-0 w-full flex justify-end text-white p-[14px] transition-all duration-700`}>
                                    <button
                                        type="button"
                                        onClick={() =>
                                            downloadImage(
                                                `${process.env.REACT_APP_SERVER_DOMAIN}/${imageUrl}`,
                                            )
                                        }
                                        className="flex gap-2 text-sm font-TTHovesL text-white">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="icon icon-tabler icons-tabler-outline icon-tabler-download">
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                            />
                                            <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                                            <path d="M7 11l5 5l5 -5" />
                                            <path d="M12 4l0 12" />
                                        </svg>
                                        <span className="underline underline-offset-4">
                                            Download Image
                                        </span>
                                    </button>
                                </div> : null}


                        </div>
                        <div className="h-[600px] flex overflow-y-scroll  flex-col">
                            <div className="overflow-y-scroll space-y-4  min-h-fit">
                                {data?.images.map(image => (
                                    <div
                                        key={image}
                                        onClick={() => {
                                            setShowModal(true)
                                            setImageUrl(image)
                                        }}
                                        className=" w-60 relative">
                                        <img
                                            draggable={false}
                                            src={`${process.env
                                                .REACT_APP_SERVER_DOMAIN
                                                }/${image.trim()}`}
                                            alt="library"
                                            className="h-min pointer-events-none w-full object-cover "
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </Fragment>
    )
}
