import React, { useState } from 'react'
import classNames from 'classnames'
import { Clock, DollarCircle } from 'iconsax-react'
import css from '../../form/Input/Input.module.css'
import { Input } from 'components/form/Input/Input'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import { LocationSelect } from 'components/form/LocationSelect'
import { useSelector } from 'react-redux'
import { authSelector, userSelector } from '../../../redux/selectors/user'

import 'react-datepicker/dist/react-datepicker.css'
import { Times } from 'utils/constants'

// <
// div
// className = " flex-1" >
//     < DatePicker
// selected = { values.estimatedShootTime }
// onChange = { date
// =>
// setFieldValue('estimatedShootTime', date)
// }
// showTimeSelect
// placeholderText = "Estimated Shoot Time"
// showTimeSelectOnly
// timeIntervals = { 15 }
// customInput = {
// <input
//     placeholder="Select time"
//     className="flex-1 h-14 bg-transparent rounded-xl border border-gray-outline outline-none focus-visible:shadow-none shadow-sm focus:ring-2 ring-brown-10 ring-opacity-50 py-2 pl-4 pr-4 bg-[#FCFDFE] disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;"
// />
// }
// timeCaption = "Time"
// dateFormat = "h:mm aa"
//     / >
//     < /div>
// </div>

export const BudgetForm = ({
    values,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    page,
}) => {
    const [rate, setRate] = useState(() =>
        values?.rate ? values?.rate : 'Flat',
    )
    const user = useSelector(userSelector)
    const { isAuth } = useSelector(authSelector)

    return (
        <div className="flex flex-col gap-8">
            <div className="flex gap-6">
                <div
                    onClick={() => {
                        setRate('Flat')
                        setFieldValue('rate', 'Flat')
                    }}
                    className={classNames(styles.checkbox, {
                        'bg-violet-200': rate === 'Flat',
                        'bg-white': rate !== 'Flat',
                    })}>
                    <DollarCircle />
                    <p className={styles.checkboxHeading}>Flat Rate</p>
                    <p className={styles.subText}>Best for one-time projects</p>
                </div>
                <div
                    onClick={() => {
                        setRate('Hourly')
                        setFieldValue('rate', 'Hourly')
                    }}
                    className={classNames(styles.checkbox, {
                        'bg-violet-200': rate === 'Hourly',
                        'bg-white': rate !== 'Hourly',
                    })}>
                    <Clock />
                    <p className={styles.checkboxHeading}>Hourly Rate</p>
                    <p className={styles.subText}>Best for ongoing projects</p>
                </div>
            </div>
            <div className="flex items-center gap-6">
                <Input
                    type="date"
                    value={values.shootDate}
                    onBlur={handleBlur('shootDate')}
                    error={touched?.shootDate && errors?.shootDate}
                    min={new Date().toISOString().split('T')[0]}
                    label="Shoot Date"
                    placeholder="Select shoot date"
                    onChange={e => setFieldValue('shootDate', e.target.value)}
                />

                <div className="relative w-full">
                    <ReactSelect
                        label="Shoot time*"
                        options={Times}
                        error={touched?.shootTime && errors?.shootTime}
                        placeholder="Select shoot time"
                        onChange={value => setFieldValue('shootTime', value)}
                        value={values.shootTime}
                    />
                </div>
            </div>
            {/*/!*<Input*!/*/}
            {/*//     type="time"*/}
            {/*//     onBlur={handleBlur('estimatedShootTime')}*/}
            {/*//     error={*/}
            {/*//         touched?.estimatedShootTime &&*/}
            {/*//         errors?.estimatedShootTime*/}
            {/*//     }*/}
            {/*//     label="Shoot Time"*/}
            {/*//     placeholder="Select shoot time"*/}
            {/*//     // value={values.estimatedShootTime}*/}
            {/*//     onChange={e =>*/}
            {/*//         setFieldValue(*/}
            {/*//             'estimatedShootTime',*/}
            {/*//             convertTo12HourFormat(e.target.value),*/}
            {/*//         )*/}
            {/*//     }*/}
            {/*// />*/}
            {/*<ReactSelect*/}
            {/*    options={[*/}
            {/*        { value: '5', label: '5' },*/}
            {/*        { value: '10', label: '10' },*/}
            {/*        { value: '15', label: '15' },*/}
            {/*    ]}*/}
            {/*    onChange={value => setFieldValue('quoteLimit', value)}*/}
            {/*    value={values.quoteLimit}*/}
            {/*    error={touched?.quoteLimit && errors?.quoteLimit}*/}
            {/*    placeholder="Select Quote Limit"*/}
            {/*    label="Quote Limit"*/}
            {/*/>*/}
            <div className="relative w-full h-14">
                <label
                    className={`absolute -top-3 left-4 bg-creame-1 ${
                        touched?.budget && errors?.budget
                            ? 'text-red'
                            : 'text-gray'
                    }`}>
                    {rate === 'Flat' ? 'Budget' : 'Hourly Rate'}
                </label>
                <input
                    type="text"
                    placeholder="0.00"
                    name="budget"
                    onChange={handleChange}
                    onBlur={handleBlur('budget')}
                    value={values.budget}
                    className={classNames('!pl-9', [
                        css.input,
                        touched?.budget && errors?.budget && css.inputError,
                    ])}
                />
                <span className="absolute left-3 top-4 font-TTHovesM">
                    {isAuth ? user?.currencySymbol[0]?.trim() : '€'} |{' '}
                </span>
                <p className="text-sm mb-4 text-red ml-1">
                    {touched?.budget && errors?.budget}
                </p>
            </div>
            <ReactSelect
                options={[
                    {
                        value: '1 to 2 hours',
                        label: '1 to 2 Hours',
                    },
                    {
                        value: '3 to 4 hours',
                        label: '3 to 4 Hours',
                    },
                    {
                        value: '5 to 6 hours',
                        label: '5 to 6 Hours',
                    },
                ]}
                onChange={value => setFieldValue('estimatedShootTime', value)}
                value={values.estimatedShootTime}
                error={
                    touched?.estimatedShootTime && errors?.estimatedShootTime
                }
                label="Estimated Shoot Time"
                placeholder="Select shoot time"
            />
            <LocationSelect
                value={values.locationString}
                error={touched?.locationString && errors?.locationString}
                onChange={(value: string) =>
                    setFieldValue('locationString', value)
                }
            />
            <p className="text-red -mt-8 text-sm ml-1">
                {touched?.locationString && errors?.locationString}
            </p>
        </div>
    )
}

const styles = {
    checkbox:
        'cursor-pointer flex-1 rounded-xl border border-zinc-200 flex flex-col items-center py-10',
    checkboxHeading: 'text-slate-800 text-lg font-TTHovesM leading-7 mt-1',
    subText: 'text-zinc-700 text-xs tracking-tight',
}
