import SectionHeading from 'components/common/SectionHeading'
import React, { memo } from 'react'
import Listing from './Listing'
import Filters from './InvitePhotographerFilters'

const InvitePhotographersModule: React.FC = () => (
    <div className="border border-primary rounded-[6px] py-[23px] mt-4 px-7 bg-white">
        <SectionHeading label="Invite Photographers" />

        <Filters />

        <Listing />
    </div>
)

export default memo(InvitePhotographersModule)
