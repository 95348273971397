import ModalSecondary from 'components/common/ModalSecandory'
import BreadCrums from 'components/common/breadCrums'
import TableMain from 'components/dashboard/wallet/TableMain'
import TopStrip from 'components/dashboard/wallet/TopStrip'
import { useEffect, useState } from 'react'
import DepositModal from 'components/dashboard/wallet/DepositModal'
import PaymentMethod from '../../../components/dashboard/wallet/Payment'
import UserService from 'services/user.service'

const service = new UserService()

const Wallet = () => {
    const [open, setOpen] = useState(false)
    const [openStripe, setOpenStripe] = useState(false)
    const [amount, setAmount] = useState(50)
    const [secretClient, setSecretClient] = useState('')
    const [saveIdsofPayments, setSaveIdsofPayments] = useState<any>(null)

    const onSuccess = async (e) => {
        // const payload = {
        //     customerId: saveIdsofPayments?.customerId,
        //     paymentMethodId: e?.paymentIntent?.payment_method,
        // }

        // try {
        //     await service.confirmPayment(payload)
        // } catch (error) {
        //     console.log(error)
        // }

        setTimeout(() => {
            setOpenStripe(false)
            setOpen(false)
        }, 3000)
    }

    const openStripeModal = ({ clientSecret }) => {
        setOpenStripe(true)
        setSecretClient(clientSecret)
    }

    useEffect(() => {
        const preFetch = async () => {
            await service.getPaymentHistory()
        }

        preFetch()
    }, [])

    return (
        <div className="flex flex-col gap-4">
            <BreadCrums>
                <button className="px-2 py-[6px] text-gray-700 text-sm font-TTHovesSB leading-[20px] border border-primary bg-white rounded-[5px]">
                    {'Wallet'}
                </button>
            </BreadCrums>

            <TopStrip setOpen={setOpen} />

            <TableMain />

            <ModalSecondary open={open} toggle={setOpen} width="min-w-[670px]">
                <DepositModal
                    depositAmount={setAmount}
                    amount={amount}
                    openPaymnetModal={openStripeModal}
                    setSaveIdsofPayments={setSaveIdsofPayments}
                    saveIdsofPayments={saveIdsofPayments}
                />
            </ModalSecondary>

            <ModalSecondary open={openStripe} width="w-[100%]">
                <PaymentMethod
                    saveIdsofPayments={saveIdsofPayments}
                    amount={amount}
                    clientSecret={secretClient}
                    onSuccess={onSuccess}
                    onBack={() => {
                        setOpenStripe(false)
                        setOpen(false)
                    }}
                />
            </ModalSecondary>
        </div>
    )
}

export default Wallet
