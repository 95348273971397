import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    project: null,
    quote: null,
    data: null,
}

const projectReducer = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setProject: (state, action) => {
            state.project = action.payload
        },
        setCurrentQuote: (state, action) => {
            state.quote = action.payload
        },
        setAddQuote: (state, action) => {
            state.data = action.payload
        },
    },
})

export const { setProject, setCurrentQuote, setAddQuote } =
    projectReducer.actions

export default projectReducer.reducer
