import { Button } from 'components/form/Button/Button'
import { Input } from 'components/form/Input/Input'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import UserService from 'services/user.service'

const service = new UserService()
const PasswordForm = ({ showGallery }) => {
    const { id } = useParams()
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")

    const getAccess = async () => {
        if (password) {
            try {
                setLoading(true)
                await service.getGalleryAccess(`?password=${password}&galleryId=${id}`)
                showGallery(true)
                setMessage("")
            } catch (error) {
                console.log("=====", error)
                setMessage("Wrong Password!")
            }

            setLoading(false)
        }
    }
    // gallery/access-protected-gallery?galleryId=66f063679e71cb23798483d8&password=pass123
    return (
        <div className="flex flex-col gap-4">
            <Input
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                placeholder="Enter Password"
                error={message}
            />

            <Button
                type="button"
                className="px-4 py-2.5 bg-tale-10 rounded-lg text-white justify-center items-center gap-2 inline-flex disabled:bg-opacity-70"
                onClick={() => getAccess()}>
                {loading ? "Verifying..." : "Show Gallery"}
            </Button>
        </div>
    )
}

export default PasswordForm
