import React, { useEffect, useState } from 'react'
import UserService from 'services/user.service'
import { BookingTile } from 'pages/dashboard/business/Booking'
import { RefreshCircle } from 'iconsax-react'
import { BusinessLayout } from 'components/dashboard/business/BusinessLayout'
import { DataNotFound } from 'components/common/DataNotFound'

const service = new UserService()

export const PastBookings = () => {
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        service
            .gePastBookings()
            .then(resp => {
                setData(
                    resp.data.data.sort((a, b) => {
                        const dateA = new Date(b.createdAt).getTime()
                        const dateB = new Date(a.createdAt).getTime()

                        return dateA - dateB
                    }),
                )
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    return (
        <BusinessLayout title="Bookings">
            <div className="flex-col md:flex-row justify-between items-start gap-6 inline-flex w-full pb-6">
                <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                    <p className="text-zinc-800 text-[22px] font-TTHovesM">
                        Past Bookings
                    </p>
                    <p className="text-zinc-500">
                        Configure time when you are available for bookings.
                    </p>
                </div>
            </div>
            {!loading && data?.length > 0 ? (
                <BookingTile data={data} />
            ) : !loading ? (
                <DataNotFound title="No past bookings" />
            ) : (
                <RefreshCircle className="text-tale-10 animate-spin mx-auto h-12 w-12" />
            )}
        </BusinessLayout>
    )
}
