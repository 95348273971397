import Embed from '@editorjs/embed'
import Image from '@editorjs/image'
import LinkTool from '@editorjs/link'
import Marker from '@editorjs/marker'
import Heading from '@editorjs/header'
import Quote from '@editorjs/quote'
import SimpleImage from '@editorjs/simple-image'
import UserService from 'services/user.service'

interface PatternPasteEventDetail {
    key: string // Ensure the required property 'key' exists
    // other properties...
}
type FixedEmbedTool = typeof Embed & {
    onPaste: (event: { detail: PatternPasteEventDetail }) => void
}

export const Times = [
    { value: '00:00', label: '00:00' },
    { value: '00:15', label: '00:15' },
    { value: '00:30', label: '00:30' },
    { value: '00:45', label: '00:45' },
    { value: '01:00', label: '01:00' },
    { value: '01:15', label: '01:15' },
    { value: '01:30', label: '01:30' },
    { value: '01:45', label: '01:45' },
    { value: '02:00', label: '02:00' },
    { value: '02:15', label: '02:15' },
    { value: '02:30', label: '02:30' },
    { value: '02:45', label: '02:45' },
    { value: '03:00', label: '03:00' },
    { value: '03:15', label: '03:15' },
    { value: '03:30', label: '03:30' },
    { value: '03:45', label: '03:45' },
    { value: '04:00', label: '04:00' },
    { value: '04:15', label: '04:15' },
    { value: '04:30', label: '04:30' },
    { value: '04:45', label: '04:45' },
    { value: '05:00', label: '05:00' },
    { value: '05:15', label: '05:15' },
    { value: '05:30', label: '05:30' },
    { value: '05:45', label: '05:45' },
    { value: '06:00', label: '06:00' },
    { value: '06:15', label: '06:15' },
    { value: '06:30', label: '06:30' },
    { value: '06:45', label: '06:45' },
    { value: '07:00', label: '07:00' },
    { value: '07:15', label: '07:15' },
    { value: '07:30', label: '07:30' },
    { value: '07:45', label: '07:45' },
    { value: '08:00', label: '08:00' },
    { value: '08:15', label: '08:15' },
    { value: '08:30', label: '08:30' },
    { value: '08:45', label: '08:45' },
    { value: '09:00', label: '09:00' },
    { value: '09:15', label: '09:15' },
    { value: '09:30', label: '09:30' },
    { value: '09:45', label: '09:45' },
    { value: '10:00', label: '10:00' },
    { value: '10:15', label: '10:15' },
    { value: '10:30', label: '10:30' },
    { value: '10:45', label: '10:45' },
    { value: '11:00', label: '11:00' },
    { value: '11:15', label: '11:15' },
    { value: '11:30', label: '11:30' },
    { value: '11:45', label: '11:45' },
    { value: '12:00', label: '12:00' },
    { value: '12:15', label: '12:15' },
    { value: '12:30', label: '12:30' },
    { value: '12:45', label: '12:45' },
    { value: '13:00', label: '13:00' },
    { value: '13:15', label: '13:15' },
    { value: '13:30', label: '13:30' },
    { value: '13:45', label: '13:45' },
    { value: '14:00', label: '14:00' },
    { value: '14:15', label: '14:15' },
    { value: '14:30', label: '14:30' },
    { value: '14:45', label: '14:45' },
    { value: '15:00', label: '15:00' },
    { value: '15:15', label: '15:15' },
    { value: '15:30', label: '15:30' },
    { value: '15:45', label: '15:45' },
    { value: '16:00', label: '16:00' },
    { value: '16:15', label: '16:15' },
    { value: '16:30', label: '16:30' },
    { value: '16:45', label: '16:45' },
    { value: '17:00', label: '17:00' },
    { value: '17:15', label: '17:15' },
    { value: '17:30', label: '17:30' },
    { value: '17:45', label: '17:45' },
    { value: '18:00', label: '18:00' },
    { value: '18:15', label: '18:15' },
    { value: '18:30', label: '18:30' },
    { value: '18:45', label: '18:45' },
    { value: '19:00', label: '19:00' },
    { value: '19:15', label: '19:15' },
    { value: '19:30', label: '19:30' },
    { value: '19:45', label: '19:45' },
    { value: '20:00', label: '20:00' },
    { value: '20:15', label: '20:15' },
    { value: '20:30', label: '20:30' },
    { value: '20:45', label: '20:45' },
    { value: '21:00', label: '21:00' },
    { value: '21:15', label: '21:15' },
    { value: '21:30', label: '21:30' },
    { value: '21:45', label: '21:45' },
    { value: '22:00', label: '22:00' },
    { value: '22:15', label: '22:15' },
    { value: '22:30', label: '22:30' },
    { value: '22:45', label: '22:45' },
    { value: '23:00', label: '23:00' },
    { value: '23:15', label: '23:15' },
    { value: '23:30', label: '23:30' },
    { value: '23:45', label: '23:45' },
]

export const Languages = [
    'Mandarin Chinese',
    'Spanish',
    'English',
    'Hindi',
    'Portuguese',
    'Bengali',
    'Russian',
    'Japanese',
    'Yue Chinese',
    'Vietnamese',
    'Turkish',
    'Wu Chinese',
    'Marathi',
    'Telugu',
    'Korean',
    'French',
    'Tamil',
    'Egyptian Arabic',
    'Standard German',
    'Urdu',
    'Javanese',
    'Western Punjabi',
    'Italian',
    'Gujarati',
    'Iranian Persian',
    'Bhojpuri',
    'Hausa',
]

const service = new UserService()
export const EDITOR_JS_TOOLS: any = {
    embed: Embed as any,
    // table: Table,
    heading: Heading,
    // list: List,
    // warning: Warning,
    // code: Code,
    linkTool: LinkTool,
    image: {
        class: Image,
        config: {
            endpoints: {
                byFile: 'http://172.104.251.7:4000/api/user/create-image-urls', // Your backend file uploader endpoint
                // byUrl: 'http://localhost:8008/fetchUrl', // Your endpoint that provides uploading by Url
            },
            uploader: {
                uploadByFile: function (file) {
                    return service.getImagesURL([file]).then(resp => {
                        return {
                            success: 1,
                            file: {
                                url: resp.data.urls[0],
                                // any other image data you want to store, such as width, height, color, extension, etc
                            },
                        }
                    })
                },
            },
        },
    },
    // raw: Raw,
    quote: Quote,
    marker: Marker,
    // checklist: CheckList,
    // delimiter: Delimiter,
    // inlineCode: InlineCode,
    simpleImage: SimpleImage,
}

export const data = {
    blocks: [
        {
            type: 'heading',
            data: {
                text: 'Heading here...',
                level: 2,
            },
        },
        {
            type: 'paragraph',
            data: {
                text: 'Hey. Meet the new Editor. On this page you can see it in action — try to edit this text.',
            },
        },
        {
            type: 'heading',
            data: {
                text: 'Key features',
                level: 3,
            },
        },
        {
            type: 'list',
            data: {
                style: 'unordered',
                items: [
                    'It is a block-styled editor',
                    'It returns clean data output in JSON',
                    'Designed to be extendable and pluggable with a simple API',
                ],
            },
        },
        {
            type: 'heading',
            data: {
                text: 'What does it mean «block-styled editor»',
                level: 3,
            },
        },
        {
            type: 'paragraph',
            data: {
                text: 'Workspace in classic editors is made of a single contenteditable element, used to create different HTML markups. Editor.js <mark class="cdx-marker">workspace consists of separate Blocks: paragraphs, headings, images, lists, quotes, etc</mark>. Each of them is an independent contenteditable element (or more complex structure) provided by Plugin and united by Editor\'s Core.',
            },
        },
        {
            type: 'paragraph',
            data: {
                text: 'There are dozens of <a href="https://github.com/editor-js">ready-to-use Blocks</a> and the <a href="https://editorjs.io/creating-a-block-tool">simple API</a> for creation any Block you need. For example, you can implement Blocks for Tweets, Instagram posts, surveys and polls, CTA-buttons and even games.',
            },
        },
        {
            type: 'heading',
            data: {
                text: 'What does it mean clean data output',
                level: 3,
            },
        },
        {
            type: 'paragraph',
            data: {
                text: 'Classic WYSIWYG-editors produce raw HTML-markup with both content data and content appearance. On the contrary, Editor.js outputs JSON object with data of each Block. You can see an example below',
            },
        },
        {
            type: 'paragraph',
            data: {
                text: 'Given data can be used as you want: render with HTML for <code class="inline-code">Web clients</code>, render natively for <code class="inline-code">mobile apps</code>, create markup for <code class="inline-code">Facebook Instant Articles</code> or <code class="inline-code">Google AMP</code>, generate an <code class="inline-code">audio version</code> and so on.',
            },
        },
        {
            type: 'paragraph',
            data: {
                text: 'Clean data is useful to sanitize, validate and process on the backend.',
            },
        },

        {
            type: 'paragraph',
            data: {
                text: "We have been working on this project more than three years. Several large media projects help us to test and debug the Editor, to make it's core more stable. At the same time we significantly improved the API. Now, it can be used to create any plugin for any task. Hope you enjoy. 😏",
            },
        },
        {
            type: 'image',
            data: {
                file: {
                    url: 'https://capella.pics/6d8f1a84-9544-4afa-b806-5167d45baf7c.jpg',
                },
                caption: '',
                withBorder: true,
                stretched: false,
                withBackground: false,
            },
        },
    ],
}
