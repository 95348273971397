import React, { memo } from 'react'

import BreadCrums from 'components/common/breadCrums'
import QuoteTile from 'components/dashboard/invitePhotographers/QuoteTile'
import InvitePhotographersModule from 'components/dashboard/invitePhotographers/InvitePhotographersModule'
import { useNavigate, useParams } from 'react-router-dom'

const InvitePhotographer: React.FC = () => {
    const navigate = useNavigate()
    const params = useParams()

    return (
        <div className="grid gap-6">
            <div className="flex items-center justify-between">
                <BreadCrums>
                    <div className="px-2 py-[6px] text-gray-700 text-sm font-TTHovesSB leading-[20px] border border-primary bg-white rounded-[5px]">
                        {'Invite Photographers'}
                    </div>
                </BreadCrums>

                <button
                    type="button"
                    onClick={() =>
                        navigate(`/dashboard/quote/${params.quoteId}`)
                    }
                    className="text-sm text-white px-4 py-2 bg-tale-10 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                    Done
                </button>
            </div>

            <QuoteTile />

            <InvitePhotographersModule />
        </div>
    )
}

export default memo(InvitePhotographer)
