import React, { useState } from 'react'
import classNames from 'classnames'
import TimezoneSelect from 'react-timezone-select'
import { styles, TABS } from 'pages/dashboard/Settings'
import { Add, Clock, InfoCircle, Trash } from 'iconsax-react'
import { ToggleButton } from 'components/form/ToggleButton'
import { DropdownIndicator } from 'components/create-profile/SetupProfile'
import { Input } from 'components/form/Input/Input'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import { Languages } from 'utils/constants'
import UserService from 'services/user.service'
import { useNotifications } from 'hooks/useNotification'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { setUser } from '../../../redux/reducers/authReducer'

const service = new UserService()

export const WorkPreferences = ({ tab, user }) => {
    const [submitting, setSubmitting] = useState(false)
    console.log(user)
    const [state, setState] = useState({
        availableUpto: user?.workPreferences?.availableUpto,
        acceptingNewClients:
            user?.workPreferences?.acceptingNewClients ?? false,
        availibility: user?.workPreferences?.availibility ?? false,
        hourlyRate: user.servicePreferences?.hourlyRate ?? '',
        extendedHours: user.servicePreferences?.extendedHours ?? '',
        timezone: user?.timezone ?? '',
        languages: Array.isArray(user?.servicePreferences?.languages)
            ? user?.servicePreferences?.languages
            : [
                  {
                      language: '',
                      proficiency: '',
                  },
              ],
    })

    const dispatch = useThunkDispatch()

    const currentDate = new Date()
    // Add 30 days to the current date
    currentDate.setDate(currentDate.getDate() + 30)
    // Format the result (optional)
    const formattedDate = currentDate.toDateString()

    const { successMessage } = useNotifications()

    const addNewLanguage = () => {
        const shallowCopy = { ...state }
        shallowCopy.languages.push({
            language: '',
            proficiency: '',
        })

        setState(shallowCopy)
    }

    const deleteLanguage = (index: number) => {
        const shallowCopy = { ...state }
        shallowCopy.languages.splice(index, 1)
        setState(shallowCopy)
    }

    const handleLanguageChange = (value, name, index) => {
        const values = { ...state }
        values.languages[index][name] = value
        setState(values)
    }

    const handleSubmit = e => {
        e.preventDefault()
        setSubmitting(true)

        service
            .saveWorkPref(state)
            .then(resp => {
                dispatch(setUser(resp.data.data))
                successMessage('Preferences updated successfully')
            })
            .catch(error => {
                console.log('Error: ', error)
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    console.log(user)

    return (
        <div
            className={classNames({
                hidden: !tab,
            })}>
            <div className="flex-1">
                {/* Buttons */}
                <div className="flex-col md:flex-row justify-between items-start gap-6 inline-flex w-full pb-6">
                    <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                        <p className="text-zinc-800 text-[22px] font-TTHovesM">
                            Work Preferences
                        </p>
                        <p className="text-zinc-500">
                            Update your work preferences
                        </p>
                    </div>
                    <div className="justify-start items-center gap-3 flex">
                        <button type="button" className={styles.default}>
                            <span className="text-slate-700 text-sm font-TTHovesM leading-tight">
                                Cancel
                            </span>
                        </button>
                        <button
                            type="submit"
                            onClick={handleSubmit}
                            className={styles.primary}>
                            <span className="text-white text-sm font-TTHovesM leading-tight">
                                {submitting ? 'Saving...' : 'Save changes'}
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            {/* Work Preferences */}
            <div className="max-w-3xl rounded-[10px] border border-zinc-300 p-5">
                <div className="flex items-center justify-between">
                    <p className="text-zinc-800 text-lg font-TTHovesM">
                        Accepting New Clients
                    </p>
                    <ToggleButton
                        id="acceptingNewClients"
                        value={state.acceptingNewClients}
                        onChange={e =>
                            setState({
                                ...state,
                                acceptingNewClients: e.target.checked,
                            })
                        }
                    />
                </div>
                <div className="mt-2 max-w-xl">
                    <p className="text-zinc-600">
                        Turn this setting on to let potential clients know
                        you're ready to work and to allow them to send you work
                        inquiries. You have indicated you're
                    </p>
                    <p className="text-zinc-800 font-TTHovesM">
                        accepting new Clients until{' '}
                        {!state.acceptingNewClients || !state.availableUpto
                            ? new Date().toDateString()
                            : new Date(state.availableUpto).toDateString()}
                    </p>
                </div>

                <p className="px-4 py-2 mt-5 text-green-900 inline-flex text-[13px] font-TTHovesM bg-emerald-100 rounded-[44px]">
                    Accepting New Clients
                </p>

                <div
                    className={classNames(
                        'w-full mt-4  items-center gap-2 inline-flex',
                        {
                            'text-zinc-300': !state.acceptingNewClients,
                            'text-zin-800': state.acceptingNewClients,
                        },
                    )}>
                    <Clock className="h-5 w-5" />
                    <div className="text-center text-sm font-TTHovesSB">
                        Extend status to{' '}
                        {!state.acceptingNewClients || !state.availableUpto
                            ? new Date().toDateString()
                            : new Date(state.availableUpto).toDateString()}
                    </div>
                </div>

                <div className="flex items-center justify-between mt-2">
                    <div className="text-zinc-800 text-lg font-TTHovesM flex">
                        <p className="flex gap-2 items-center">
                            Availability Reminders
                            <span>
                                <InfoCircle className="h-5 w-5" />
                            </span>
                        </p>
                    </div>
                    <ToggleButton
                        id="avaialibility"
                        value={state.availibility}
                        onChange={e =>
                            setState({
                                ...state,
                                availibility: e.target.checked,
                            })
                        }
                    />
                </div>
            </div>
            {/* Work Preferences End */}
            <hr className="text-zinc-200 my-5" />
            {/* Service Preferences*/}
            <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                <p className="text-zinc-800 text-[22px] font-TTHovesM">
                    Service Preferences
                </p>
                <p className="text-zinc-500">
                    Add your details to help us match you with the perfect
                    opportunity!
                </p>
            </div>

            <form className="max-w-3xl mt-6">
                <Input
                    value={state.hourlyRate}
                    onChange={e =>
                        setState({
                            ...state,
                            hourlyRate: e.target.value,
                        })
                    }
                    label="Hourly Rate/hr"
                    placeholder="25 - 50"
                    type="text"
                />
                <div className="flex flex-col md:flex-row md:items-center gap-4 mt-4 md:mt-8">
                    <div className="flex-1 relative">
                        <label className="absolute -top-3 left-4 bg-creame-1 z-10 text-gray ">
                            Time Zone
                        </label>
                        <TimezoneSelect
                            value={state.timezone}
                            onChange={(e: any) =>
                                setState({
                                    ...state,
                                    timezone: e.value,
                                })
                            }
                            className="react-select"
                            placeholder="Select time zone"
                            components={{ DropdownIndicator }}
                            classNamePrefix="react-select"
                        />
                    </div>
                    <div className="flex-1">
                        <ReactSelect
                            label="Extended hours"
                            options={[
                                {
                                    value: '±3 Hours',
                                    label: '±3 Hours',
                                },
                                {
                                    value: '±5 Hours',
                                    label: '±5 Hours',
                                },
                            ]}
                            placeholder="Select extended hours"
                            value={state.extendedHours}
                            onChange={value =>
                                setState({
                                    ...state,
                                    extendedHours: value,
                                })
                            }
                        />
                    </div>
                </div>
                <div className="flex items-center gap-1 mt-5">
                    <div className="h-4 w-4">
                        <img
                            alt="bulb"
                            className="h-full w-full "
                            src="/assets/bulb.png"
                        />
                    </div>
                    <p className="text-zinc-500 text-sm">
                        Your time zone won't exclude you from opportunities; it
                        helps us find you better-matched opportunities.
                    </p>
                </div>
            </form>
            {/* Service Preferences */}

            <hr className="text-zinc-200 my-5" />
            {/* Languages */}
            <div className="flex flex-col gap-5 max-w-3xl">
                <div className="flex items-center justify-between">
                    <p className="text-zinc-800 text-[22px] font-TTHovesM">
                        Languages
                    </p>
                    <button type="button" onClick={addNewLanguage}>
                        <Add />
                    </button>
                </div>
                {state.languages.map((language, index) => (
                    <div key={index} className="flex items-center gap-4">
                        <div className="flex-1">
                            <ReactSelect
                                label="Language"
                                value={language.language}
                                onChange={value =>
                                    handleLanguageChange(
                                        value,
                                        'language',
                                        index,
                                    )
                                }
                                options={Languages.map(item => ({
                                    value: item,
                                    label: item,
                                }))}
                                placeholder="Select language"
                            />
                        </div>
                        <div className="flex-1">
                            <ReactSelect
                                label="Proficiency"
                                value={language.proficiency}
                                onChange={value =>
                                    handleLanguageChange(
                                        value,
                                        'proficiency',
                                        index,
                                    )
                                }
                                options={[
                                    {
                                        value: 'Basic',
                                        label: 'Basic',
                                    },
                                    {
                                        value: 'Intermediate',
                                        label: 'Intermediate',
                                    },
                                    {
                                        value: 'Advanced',
                                        label: 'Advanced',
                                    },
                                    {
                                        value: 'Fluent',
                                        label: 'Fluent',
                                    },
                                    {
                                        value: 'Native',
                                        label: 'Native',
                                    },
                                ]}
                                placeholder="Select Proficiency"
                            />
                        </div>
                        <button
                            type="button"
                            onClick={() => deleteLanguage(index)}>
                            <Trash className="text-zinc-500" />
                        </button>
                    </div>
                ))}
            </div>
            {/* Languages End*/}
        </div>
    )
}
