import { FC, useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import styles from './Auth.module.css'
import { Button } from 'components/form/Button/Button'

import { ArrowLeft } from 'iconsax-react'
import {
    IconBrowseJobs,
    IconCameraBlank,
    IconCollaborate,
    IconCollaborateBlank,
    IconCreateGallery,
    IconCreatePortfolio,
    IconEditPortfolioBlank,
    IconLineVertical,
    IconLinkPlatform,
    IconProfile,
    IconProfileImage,
    IconSessions,
} from '../../css/icons/icons'
import {
    SetupProfile,
    Collaborate,
    UploadProfileImage,
    LinkPlatform,
    SessionOffer,
    CreatePortfolio,
} from 'components/create-profile'

import { redirectTo } from 'utils/links'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'
import { PersonaliseChoice } from 'components/create-profile/PersonaliseChoice'
import { getItem, setItem } from 'services/localStorage.service'

const steps = {
    COMPLETE: 'complete',
    IN_PROGRESS: 'inProgress',
    PENDING: 'pending',
}

export const pages = {
    NEXT: 'next',
    PREV: 'prev',
}

const stepListMock = [
    {
        id: 0,
        icon: 'profile',
        progress: 'inProgress',
        title: 'Create a profile',
        message: 'Set up your profile',
    },
    {
        id: 1,
        icon: 'profile-image',
        progress: 'pending',
        title: 'Upload profile images',
        message: 'Upload cover and profile images',
    },
    // {
    //     id: 2,
    //     icon: 'link-platform',
    //     progress: 'pending',
    //     title: 'Link Platform',
    //     message: 'Connect platform to get portfolio',
    // },
    {
        id: 2,
        icon: 'sessions',
        progress: 'pending',
        title: 'Sessions you offer',
        message: 'Select photography services',
    },
    {
        id: 3,
        icon: 'create-portfolio',
        progress: 'pending',
        title: 'Create portfolio',
        message: 'Upload your best work',
    },
    {
        id: 4,
        icon: 'collaborate',
        progress: 'pending',
        title: 'Collaborate',
        message: 'Connect and collaborate',
    },
]

const stepListClient = [
    {
        id: 0,
        icon: 'profile',
        progress: 'inProgress',
        title: 'Create a profile',
        message: 'Set up your profile',
    },
    // {
    //     id: 1,
    //     icon: 'profile-image',
    //     progress: 'pending',
    //     title: 'Upload profile images',
    //     message: 'Upload cover and profile images',
    // },

    {
        id: 3,
        icon: 'sessions',
        progress: 'pending',
        title: 'Sessions you like',
        message: 'Select photography services',
    },
    {
        id: 4,
        icon: 'create-portfolio',
        progress: 'pending',
        title: 'Personalise your choice',
        message: 'Upload your best work',
    },
]

export const CreateProfile: FC = () => {
    const user = useSelector(userSelector)
    const [currentPage, setCurrentPage] = useState(
        localStorage.getItem('current') ?? 0,
    )
    const [stepList, setStepList] = useState(() => {
        if (user?.userType === 'Photographer') {
            return stepListMock
        } else {
            return stepListClient
        }
    })
    const [currentStep, setCurrentStep] = useState(
        localStorage.getItem('current') ?? 0,
    )

    useEffect(() => {
        const step = localStorage.getItem('current') ?? 0

        const stepListUpdate = stepList.map(item => {
            // if (item.id === +step + 1) {
            //     return { ...item, progress: 'inProgress' }
            // }
            if (item.id === +step) {
                return { ...item, progress: 'inProgress' }
            }
            if (+step > item.id) {
                return { ...item, progress: 'complete' }
            }
            return item
        })
        setStepList(stepListUpdate)
    }, [currentStep])

    const pageChange = page => {
        switch (page) {
            case pages.NEXT: {
                setCurrentPage(prev => +prev + 1)
                break
            }
            case pages.PREV: {
                setCurrentPage(prev => +prev - 1)
                break
            }
            default: {
                setCurrentPage(0)
                break
            }
        }
    }

    const stepIncrement = stepId => {
        const stepListUpdate = stepList.map(item => {
            if (item.id === stepId) {
                return { ...item, progress: 'complete' }
            }
            if (user?.userType === 'Client') {
                if (stepId === 3) {
                    setCurrentPage(stepId + 1)
                    return { ...item, progress: 'inProgress' }
                }
                if (item.id === stepId) {
                    return { ...item, progress: 'complete' }
                }
                if (item.id === stepId + 1) {
                    setCurrentPage(stepId)
                    return { ...item, progress: 'inProgress' }
                }
            } else {
                if (item.id === stepId + 1) {
                    return { ...item, progress: 'inProgress' }
                }
            }

            return item
        })
        setStepList(stepListUpdate)
        setCurrentStep(prev => +prev + 1)
        localStorage.setItem('current', JSON.stringify(+stepId + 1))
    }

    const stepDecrement = stepId => {
        const stepListUpdate = stepList.map(item => {
            if (item.id === stepId) {
                return { ...item, progress: 'pending' }
            }
            if (stepId > 0) {
                if (item.id === stepId - 1) {
                    return { ...item, progress: 'inProgress' }
                }
            }
            return item
        })
        setStepList(stepListUpdate)
        setCurrentStep(prev => +prev - 1)
        localStorage.setItem('current', JSON.stringify(+stepId - 1))
    }

    console.log(user)

    const navigate = useNavigate()

    return (
        <div className="block md:flex w-full h-screen overflow-hidden">
            {user?.userType === 'Photographer' && (
                <div className="hidden md:block w-1/4 bg-white p-0 md:p-8 overflow-hidden h-full">
                    <div className="">
                        <p className="text-body-bold text-primary text-dark-8 font-OTabolas z-10">
                            Suura
                        </p>
                    </div>
                    <div className="mt-28">
                        {stepList.map(step => (
                            <SignUpStep
                                key={step.id}
                                icon={getIcon(step.icon, step.progress)}
                                stepProgress={step.progress}
                                title={step.title}
                                message={step.message}
                            />
                        ))}
                    </div>
                </div>
            )}

            {currentPage < 5 ? (
                <div className="h-full w-full relative overflow-y-auto">
                    <Link to="/" className="block md:hidden">
                        <p className="text-body-bold text-primary text-dark-8 font-OTabolas p-6 z-10">
                            Suura
                        </p>
                    </Link>
                    <div className="border-b-2 border-zinc-100 relative md:hidden">
                        <div
                            className="border-b-2 border-brown-10 absolute"
                            style={{ width: '20%' }}
                        />
                    </div>
                    <div className="p-6 h-full max-w-3xl  scrollbar-hidden mx-auto overflow-y-auto">
                        <div
                            className="w-full flex justify-between py-8"
                            style={{
                                display: currentPage > 0 ? 'flex' : 'none',
                            }}>
                            <div
                                onClick={() => {
                                    if (
                                        user?.userType === 'Client' &&
                                        currentPage === 4
                                    ) {
                                        // pageChange(pages.PREV)
                                        // stepDecrement(2)
                                        setCurrentPage(3)
                                        setCurrentStep(3)
                                    }
                                    if (
                                        user?.userType === 'Client' &&
                                        currentPage === 3
                                    ) {
                                        // pageChange(pages.PREV)
                                        // stepDecrement(2)
                                        setCurrentPage(0)
                                        setCurrentStep(0)
                                    } else {
                                        pageChange(pages.PREV)
                                        stepDecrement(currentPage)
                                    }
                                }}>
                                <ArrowLeft />
                            </div>
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/*@ts-ignore*/}
                            {getItem('current') !== '2' &&
                                currentPage !== 4 &&
                                currentPage !== 2 && (
                                    <div
                                        className="text-base text-gray cursor-pointer"
                                        onClick={() => {
                                            if (
                                                user?.userType ===
                                                'Photographer'
                                            ) {
                                                pageChange(pages.NEXT)
                                                stepIncrement(currentStep)
                                            } else if (
                                                user?.userType === 'Client' &&
                                                currentPage === 4
                                            ) {
                                                pageChange(pages.NEXT)
                                                stepIncrement(currentStep)
                                            } else {
                                                navigate('/dashboard')
                                            }
                                        }}>
                                        Skip
                                    </div>
                                )}
                        </div>
                        {/* Create a Profile */}
                        <SetupProfile
                            currentPage={+currentPage}
                            pageChange={pageChange}
                            stepIncrement={stepIncrement}
                        />

                        {/* Upload Profile Image */}
                        <UploadProfileImage
                            currentPage={+currentPage}
                            pageChange={pageChange}
                            stepIncrement={stepIncrement}
                        />

                        {/* Link Platform */}
                        {/*<LinkPlatform*/}
                        {/*    currentPage={+currentPage}*/}
                        {/*    pageChange={pageChange}*/}
                        {/*    stepIncrement={stepIncrement}*/}
                        {/*/>*/}

                        {/* Session Your offer */}
                        <SessionOffer
                            currentPage={+currentPage}
                            pageChange={pageChange}
                            stepIncrement={stepIncrement}
                        />

                        <PersonaliseChoice
                            currentPage={+currentPage}
                            pageChange={pageChange}
                            stepIncrement={stepIncrement}
                        />

                        {/* Create portfolio */}
                        <CreatePortfolio
                            currentPage={+currentPage}
                            pageChange={pageChange}
                            stepIncrement={stepIncrement}
                        />

                        {/* Collaborate */}
                        <Collaborate
                            currentPage={+currentPage}
                            pageChange={pageChange}
                            stepIncrement={stepIncrement}
                        />
                    </div>
                </div>
            ) : (
                // Go to profile
                <div
                    id="page-6"
                    className="h-full flex flex-col overflow-y-auto mx-auto justify-center relative">
                    <div className="absolute md:hidden top-3 left-10">
                        <Link to="/">
                            <p className="text-body-bold text-primary text-dark-8 font-OTabolas z-10">
                                Suura
                            </p>
                        </Link>
                    </div>
                    <div className="mt-32 md:mt-0">
                        <p className="text-body-caption-bold text-dark-8 font-OTabolas text-center">
                            Welcome, You’re in!
                        </p>
                        <p
                            className={`mt-1 text-base text-gray font-TTHoves text-center`}>
                            What Suura’s feature do you want to explore first?
                        </p>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <div className="flex flex-col items-center justify-center">
                            <div className="flex flex-wrap justify-around">
                                <Link to="/dashboard/galleries/portfolio">
                                    <ExploreItem
                                        icon={<IconEditPortfolioBlank />}
                                        text="Customise Portfolio"
                                    />
                                </Link>
                                <ExploreItem
                                    icon={<IconCameraBlank />}
                                    text="Manage My Shoots"
                                />
                                <Link to="/dashboard/galleries/client">
                                    <ExploreItem
                                        icon={<IconCreateGallery />}
                                        text="Create a Client Gallery"
                                    />
                                </Link>
                            </div>
                            <div className="flex flex-col md:flex-row justify-around">
                                <Link to="/dashboard/board">
                                    <ExploreItem
                                        icon={<IconCollaborateBlank />}
                                        text="Browse Collab Board"
                                    />
                                </Link>
                                <Link to="/dashboard/browse/jobs">
                                    <ExploreItem
                                        icon={<IconBrowseJobs />}
                                        text="Browse Jobs"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Button
                        container="w-full flex justify-center pb-2"
                        className="bg-brown-10 border-brown-10 text-white px-7 h-12 w-60"
                        onClick={() =>
                            redirectTo(`/dashboard/${user?.userName}`)
                        }>
                        Go to profile
                    </Button>
                </div>
            )}
        </div>
    )
}

const SignUpStep = ({ icon, title, message, stepProgress }) => {
    return (
        <div className={styles.stepItem}>
            <div className={styles.stepContainerRight}>
                <div>{icon}</div>
                <div className={styles.verticalLine}>
                    <IconLineVertical
                        color={getLineColor(stepProgress).fillColor}
                    />
                </div>
            </div>
            <div className={styles.stepContainerLeft}>
                <div
                    className={`text-base ${
                        stepProgress === 'pending' ? 'text-gray' : 'text-dark-8'
                    } font-TTHovesM`}>
                    {title}
                </div>
                <div
                    className={`text-body-thin ${
                        stepProgress === 'pending'
                            ? 'text-gray'
                            : 'text-gray-600'
                    } font-TTHovesEL`}>
                    {message}
                </div>
            </div>
        </div>
    )
}

const getColors = (stepProgress: string) => {
    let fillColor = ''
    let bgColor = ''

    switch (stepProgress) {
        case steps.PENDING: {
            fillColor = '#7F8AA5'
            bgColor = '#FFFFFF'
            break
        }
        case steps.IN_PROGRESS: {
            fillColor = '#FFFFFF'
            bgColor = '#C7987A'
            break
        }
        case steps.COMPLETE: {
            fillColor = '#FFFFFF'
            bgColor = '#C7987A'
            break
        }
        default: {
            fillColor = '#7F8AA5'
            bgColor = '#FFFFFF'
            break
        }
    }
    return { fillColor, bgColor }
}

const getLineColor = (stepProgress: string) => {
    let fillColor = ''

    switch (stepProgress) {
        case steps.PENDING:
        case steps.IN_PROGRESS: {
            fillColor = '#EAECF0'
            break
        }
        case steps.COMPLETE: {
            fillColor = '#C7987A'
            break
        }
        default: {
            fillColor = '#EAECF0'
            break
        }
    }
    return { fillColor }
}

const getIcon = (icon: string, progress: string) => {
    switch (icon) {
        case 'profile': {
            return (
                <IconProfile
                    bgColor={getColors(progress)?.bgColor}
                    color={getColors(progress)?.fillColor}
                />
            )
        }
        case 'profile-image': {
            return (
                <IconProfileImage
                    bgColor={getColors(progress)?.bgColor}
                    color={getColors(progress)?.fillColor}
                />
            )
        }
        case 'link-platform': {
            return (
                <IconLinkPlatform
                    bgColor={getColors(progress)?.bgColor}
                    color={getColors(progress)?.fillColor}
                />
            )
        }
        case 'sessions': {
            return (
                <IconSessions
                    bgColor={getColors(progress)?.bgColor}
                    color={getColors(progress)?.fillColor}
                />
            )
        }
        case 'create-portfolio': {
            return (
                <IconCreatePortfolio
                    bgColor={getColors(progress)?.bgColor}
                    color={getColors(progress)?.fillColor}
                />
            )
        }
        case 'collaborate': {
            return (
                <IconCollaborate
                    bgColor={getColors(progress)?.bgColor}
                    color={getColors(progress)?.fillColor}
                />
            )
        }
        default: {
            return null
        }
    }
}

export const ExploreItem = ({ icon, text }) => {
    return (
        <div className="flex flex-row items-center border border-dashed border-gray-outline rounded-xl p-6 my-6 mx-2">
            <div className="flex justify-around items-center mr-6">
                <div className="mr-6">{icon}</div>
                <IconLineVertical />
            </div>
            <div className="col-span-6 text-base text-dark-8 font-TTHovesSB font-extrabold">
                {text}
            </div>
        </div>
    )
}
