import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useFormik } from 'formik'
import styles from './Auth.module.css'
import { Input } from 'components/form/Input/Input'
import { Button } from 'components/form/Button/Button'
import { AppleLogo, GoogleLogo } from '../../css/icons/icons'
import { LoginValidation } from 'utils/validations'
import { signIn } from '../../redux/actions/auth'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { AuthRightSide } from 'components/landing/AuthRightSide'
import axiosInstance from 'utils/axiosInstance'

export const LoginPage = () => {
    const [authError, setAuthError] = useState('')
    const dispatch = useThunkDispatch()

    const googleLogin = () => {
        axiosInstance.get('/user/google-signin').then(resp => {
            console.log(resp)
        })
    }
    const {
        errors,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        handleChange,
        handleBlur,
        values,
        touched,
    } = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: LoginValidation,
        onSubmit: values => {
            dispatch(signIn(values, setSubmitting, setAuthError))
        },
    })

    return (
        <div className="flex flex-col md:flex-row">
            <div className="flex-1">
                <Link to="/">
                    <div className="absolute top-4  w-32 h-14 left-5 md:left-10 z-10">
                        <img
                            src="/SUURA_24_Logo_01.png"
                            className="h-full w-full object-contain"
                            alt="logo"
                        />
                    </div>
                </Link>
                <form onSubmit={handleSubmit}>
                    <div className="relative pt-16 bg-gradient-to-br to-gray-200">
                        <div className="xl:px-16">
                            <div className="grid m-5 md:mx-10">
                                <p className="font-normal text-body-caption-bold text-dark-8 font-OTabolasM">
                                    Glad you are back!
                                </p>
                                <p className={styles.messageText}>
                                    Enter your registered email & password to
                                    log in.
                                </p>
                                {authError && (
                                    <div className="mt-3 mb-1 p-4 bg-red/5 rounded-xl shadow-sm border border-red/20 ">
                                        <div className="flex-col justify-start items-start gap-1 flex">
                                            <p className="text-error text-sm font-TTHovesSB">
                                                {authError}
                                            </p>
                                            <div className="text-error text-sm">
                                                Try again!
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="mt-3">
                                    <Input
                                        type="text"
                                        placeholder="Email"
                                        error={touched.email && errors.email}
                                        onChange={handleChange('email')}
                                        onBlur={handleBlur('email')}
                                        value={values.email}
                                        id="email-input"
                                    />
                                </div>

                                <div className="mt-6">
                                    <Input
                                        type="password"
                                        placeholder="Password"
                                        id="password-input"
                                        error={
                                            touched.password && errors.password
                                        }
                                        onChange={handleChange('password')}
                                        onBlur={handleBlur('password')}
                                        value={values.password}
                                    />
                                </div>

                                <div className="flex items-start mt-4 mb-6">
                                    <div className="flex items-center h-5">
                                        <input
                                            id="remember"
                                            type="checkbox"
                                            value=""
                                            className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                                        />
                                    </div>
                                    <label
                                        htmlFor="remember"
                                        className="ml-2 text-sm font-TTHoves text-dark-8 dark:text-gray">
                                        Keep me logged in
                                    </label>
                                    <a
                                        href="#"
                                        className="ml-auto text-sm font-TTHoves text-tale-10 hover:text-blue-700">
                                        Forgot Password?
                                    </a>
                                </div>
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="disabled:cursor-not-allowed disabled:bg-opacity-70 bg-brown-10 border-brown-10 text-white px-7 h-14 w-full">
                                    {isSubmitting ? 'Please wait...' : 'Log in'}
                                </Button>
                                <div className="text-center mt-2 mb-2">
                                    <p className={styles.messageText}>
                                        By clicking on login you agreed with the
                                        Suura's
                                    </p>
                                    <p className={styles.messageText}>
                                        terms of service & privacy guidelines
                                    </p>
                                    <div className="relative py-4">
                                        <div className="absolute inset-0 flex items-center">
                                            <div className="w-full border-b border-gray" />
                                        </div>
                                        <div className="relative flex justify-center">
                                            <span className="bg-pearl-white px-4 text-md text-gray">
                                                Or log in using
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    onClick={() => googleLogin()}
                                    className={styles.buttonSecondary}>
                                    <div className="relative flex items-center space-x-4 justify-center">
                                        <div className="absolute left-0 w-5">
                                            <GoogleLogo />
                                        </div>
                                        <span className="block w-max tracking-wide font-TTHoves text-dark-8 text-md transition duration-300 group-hover:text-blue-600 sm:text-base md:w-64 md:mx-auto">
                                            Login with Google
                                        </span>
                                    </div>
                                </button>
                                <button className={styles.buttonSecondary}>
                                    <div className="relative flex items-center space-x-4 justify-center">
                                        <div className="absolute left-0 w-5">
                                            <AppleLogo />
                                        </div>
                                        <span className="block w-max tracking-wide font-TTHoves  text-dark-8 text-md transition duration-300 group-hover:text-blue-600 sm:text-base md:w-64 md:mx-auto">
                                            Login with Apple
                                        </span>
                                    </div>
                                </button>
                                <div className="text-center font-TTHoves">
                                    <p className="text-dark-8 mt-4">
                                        New here ?&nbsp;
                                        <Link
                                            to="/role"
                                            className="text-tale-10 hover:text-blue-700">
                                            Create an Account
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <AuthRightSide />
        </div>
    )
}
