import { Profile2User } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import UserService from 'services/user.service'
import ModalSecondary from 'components/common/ModalSecandory'
import { useNotifications } from 'hooks/useNotification'

const service = new UserService()

export const CopyLinkModal = ({ id, showModal, setShowModal }) => {
    const [projects, setProjects] = useState<any>([])
    const [state, setState] = useState({
        projectId: '',
        message: '',
    })
    const { successMessage } = useNotifications()

    useEffect(() => {
        service
            .getBookings()
            .then(resp => {
                setProjects(resp.data.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    const handleSubmit = e => {
        e.preventDefault()
        service
            .linkGallery(id, state)
            .then(resp => {
                successMessage('Gallery linked successfully')
                setShowModal(false)
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <ModalSecondary
            width="w-full md:w-2/3 2xl:w-1/3"
            open={showModal}
            toggle={setShowModal}>
            {/* Form */}
            <form onSubmit={handleSubmit} className="bg-white rounded-lg">
                <div className="p-4 justify-start items-center gap-4 inline-flex">
                    <div className="p-3 bg-white rounded-[10px] border border-zinc-200">
                        <Profile2User className="h-5 w-5" />
                    </div>

                    <div className="flex-col justify-start items-start inline-flex">
                        <p className="text-zinc-900 text-lg font-TTHovesSB">
                            Link Gallery
                        </p>
                    </div>
                </div>
                <hr className="text-zinc-200 w-full " />

                <div className="flex flex-col gap-3 p-5">
                    <div className="">
                        <label className="text-sm text-zinc-700 font-TTHovesM">
                            Select Project *
                        </label>
                        <ReactSelect
                            value={state.projectId}
                            onChange={value =>
                                setState({
                                    ...state,
                                    projectId: value,
                                })
                            }
                            options={projects.map(item => ({
                                label: item.photoshootTitle,
                                value: item._id,
                            }))}
                            placeholder={'Choose a project'}
                        />
                    </div>

                    {/*<div>*/}
                    {/*    <label className="text-sm text-zinc-700 font-TTHovesM">*/}
                    {/*        Select Project **/}
                    {/*    </label>*/}
                    {/*    <ReactSelect*/}
                    {/*        value={''}*/}
                    {/*        onChange={() => console.log()}*/}
                    {/*        options={[]}*/}
                    {/*        placeholder={''}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <label className="text-sm text-zinc-700 font-TTHovesM">*/}
                    {/*        Email Message*/}
                    {/*    </label>*/}
                    {/*    <TextArea*/}
                    {/*        value={state.message}*/}
                    {/*        onChange={value =>*/}
                    {/*            setState({*/}
                    {/*                ...state,*/}
                    {/*                message: value,*/}
                    {/*            })*/}
                    {/*        }*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
                <hr className="text-zinc-200 w-full " />

                <div className="flex gap-4 p-5">
                    <button
                        type="button"
                        onClick={() => {
                            setShowModal(false)
                        }}
                        className="flex-1 text-slate-700  px-[18px] py-[10px] font-TTHovesM bg-white rounded-lg  border border-zinc-300 ">
                        Cancel
                    </button>
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className="flex-1 text-white px-[18px] py-[10px] font-TTHovesM bg-tale-10 rounded-lg">
                        Save
                    </button>
                </div>
            </form>
            {/* Form End */}
        </ModalSecondary>
    )
}
