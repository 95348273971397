import React, { Fragment, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Add, Edit, RefreshCircle, Trash } from 'iconsax-react'
import { DataNotFound } from 'components/common/DataNotFound'
import { useNavigate } from 'react-router-dom'
import UserService from 'services/user.service'
import { IconButton } from 'components/form/Button/Button'
import { ConfirmModal } from 'components/common/ConfirmModal'
import { useNotifications } from 'hooks/useNotification'

const service = new UserService()

export const EmailTemplate = ({ tab }) => {
    const navigate = useNavigate()
    const [data, setData] = useState<any>([])
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [id, setId] = useState('')

    const { successMessage } = useNotifications()

    useEffect(() => {
        setLoading(true)
        service
            .getEmailTemplate()
            .then(resp => {
                setData(resp.data.data)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
                setLoading(false)
            })
    }, [])

    const handleDelete = () => {
        service
            .deleteTemplate(id)
            .then(resp => {
                setIsOpen(false)
                const index = data?.findIndex(item => item._id === id)
                data.splice(index, 1)
                successMessage('Template Deleted successfully.')
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div
            className={classNames(
                'bg-white rounded-xl border border-zinc-200 mt-5 p-5 w-full',
                {
                    hidden: !tab,
                },
            )}>
            <div className="border-b border-zinc-200 pb-5 flex  gap-3 md:gap-0 items-center justify-between">
                {/*<div>*/}
                <p className="text-zinc-900 text-lg font-TTHovesM">
                    Email Templates
                </p>
                {/*<p className="text-slate-600 text-sm leading-tight">*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit,*/}
                {/*    sed do eiusmod tempor incididunt ut labore et dolore*/}
                {/*    magna aliqua.*/}
                {/*</p>*/}
                {/*</div>*/}
                <button
                    type="button"
                    onClick={() => navigate('/dashboard/services/template')}
                    className="w-auto px-4 py-2.5 bg-tale-10 rounded-lg shadow justify-center items-center gap-2 inline-flex">
                    <Add className="text-white h-5 w-5" />
                    <span className="text-white text-sm font-TTHovesM ">
                        Create New Template
                    </span>
                </button>
            </div>
            {data?.length > 0 ? (
                <div className="min-w-full">
                    <div className="hidden md:flex items-center text-zinc-500 font-TTHovesM p-4 mt-1">
                        <p className="w-1/3">Title</p>
                        <p className="flex-1" />
                    </div>

                    <div className="flex flex-col gap-4 mt-1 min-w-full">
                        {/* Row 1 */}
                        {data.map(item => (
                            <div
                                key={item._id}
                                className="flex items-center border border-zinc-200 rounded-lg p-4">
                                <div className="w-1/3 flex gap-2 items-center">
                                    <p>{item.emailTemplate.title}</p>
                                </div>

                                <div className="flex flex-1 justify-end gap-2">
                                    <IconButton
                                        onClick={() => {
                                            setId(item._id)
                                            setIsOpen(true)
                                        }}
                                        icon={
                                            <Trash className="h-5 w-5 text-red" />
                                        }
                                    />
                                    <IconButton
                                        onClick={() => {
                                            navigate(
                                                `/dashboard/services/template?id=${item._id}`,
                                            )
                                        }}
                                        icon={
                                            <Edit className="h-5 w-5 text-blue-700" />
                                        }
                                    />

                                    {/*<Menu as="div" className="relative">*/}
                                    {/*    {({ open }) => (*/}
                                    {/*        <>*/}
                                    {/*            <Menu.Button className="inline-flex px-4 py-2.5 bg-white rounded-lg  border border-zinc-300">*/}
                                    {/*                <TbDots />*/}
                                    {/*            </Menu.Button>*/}
                                    {/*            <Transition*/}
                                    {/*                show={open}*/}
                                    {/*                as={Fragment}*/}
                                    {/*                enter="transition ease-out duration-200"*/}
                                    {/*                enterFrom="transform opacity-0 scale-95"*/}
                                    {/*                enterTo="transform opacity-100 scale-100"*/}
                                    {/*                leave="transition ease-in duration-100"*/}
                                    {/*                leaveFrom="transform opacity-100 scale-100"*/}
                                    {/*                leaveTo="transform opacity-0 scale-95">*/}
                                    {/*                <Menu.Items*/}
                                    {/*                    static*/}
                                    {/*                    className="flex flex-col absolute z-10 -left-[75px] mt-2 w-28 rounded-md shadow-lg divide-y divide-zinc-200 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">*/}
                                    {/*                    <Menu.Item>*/}
                                    {/*                        <button*/}
                                    {/*                            type="button"*/}
                                    {/*                            className="p-3 text-sm hover:text-red/90 text-gray-800 text-red flex items-center gap-x-2">*/}
                                    {/*                            Resend*/}
                                    {/*                        </button>*/}
                                    {/*                    </Menu.Item>*/}
                                    {/*                    <Menu.Item>*/}
                                    {/*                        <button*/}
                                    {/*                            type="button"*/}
                                    {/*                            className="p-3 text-sm hover:text-red/90 text-gray-800 text-red flex items-center gap-x-2">*/}
                                    {/*                            Revoke*/}
                                    {/*                        </button>*/}
                                    {/*                    </Menu.Item>*/}
                                    {/*                </Menu.Items>*/}
                                    {/*            </Transition>*/}
                                    {/*        </>*/}
                                    {/*    )}*/}
                                    {/*</Menu>*/}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : !loading && data?.length === 0 ? (
                <div className="mt-6">
                    <DataNotFound title="No Templates added" />
                </div>
            ) : loading ? (
                <RefreshCircle className="size-12 mx-auto animate-spin text-tale-10 mt-6" />
            ) : null}

            <ConfirmModal
                onConfirm={handleDelete}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title="Are you sure to delete the template?"
                description="The template will be deleted permanently"
            />
        </div>
    )
}
