import { StarIcon } from 'utils/staticData'

const ProfileTopHeader = ({ user }) => {

    return (
        <div className="relative">
            <img
                src={`${process.env.REACT_APP_SERVER_DOMAIN}/${user?.coverImage}`}
                className="w-full h-64 object-cover rounded-[10px]"
                alt=""
            />

            <div className="px-10 flex gap-[18px]">
                <img
                    src={`${process.env.REACT_APP_SERVER_DOMAIN}/${user?.profileImage}`}
                    className="w-[140px] h-[140px] relative -top-[35px] border-[3px] border-white rounded-full object-cover"
                    alt=""
                />
                <div className="flex flex-col gap-3">
                    <div className="pt-3">
                        <h3 className="font-bold text-[22px]">{`${user?.firstName} ${user?.lastName}`}</h3>
                        <div className="flex gap-2 items-center">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.16797 7.33301H9.83464"
                                    stroke="#131224"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                />
                                <path
                                    d="M2.41184 5.65968C3.72517 -0.113657 12.2785 -0.106991 13.5852 5.66634C14.3518 9.05301 12.2452 11.9197 10.3985 13.693C9.0585 14.9863 6.9385 14.9863 5.59184 13.693C3.75184 11.9197 1.64517 9.04634 2.41184 5.65968Z"
                                    stroke="#131224"
                                    strokeWidth="1.5"
                                />
                            </svg>

                            <p className=" text-[#898690] text-sm">
                                {user?.location}
                            </p>
                        </div>
                    </div>
                    <div className="bg-[#EBF1FD] w-fit flex items-center px-[13px] py-2 rounded-[7px]">
                        <img src={StarIcon} className="w-5 h-5" alt="" />
                        <span className="text-sm text-[#131224]">5.9</span>
                        <span className="text-sm text-[#13122466]">(56)</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileTopHeader
