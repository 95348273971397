import { ArrowRight2, Home2 } from 'iconsax-react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { TabButton } from 'pages/dashboard/business/Service'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'

export const BusinessLayout = ({ title, children }) => {
    const location = useLocation()
    const push = useNavigate()

    const user = useSelector(userSelector)
    return (
        <div className="">
            <div className="items-center gap-2 flex">
                <Home2 className="h-5 w-5" />
                <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                <div className="px-2 py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                    Business
                </div>
                <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                <div className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                    {title}
                </div>
            </div>

            <div className="flex flex-col md:flex-row md:divide-x gap-8 divide-zinc-200 mt-6 md:h-[600px]">
                {/* Sidebar */}
                <div className="w-full md:w-1/6 hidden md:flex flex-col gap-3">
                    {user?.userType === 'Photographer' && (
                        <div className="">
                            <div className="px-3 py-2.5 rounded-md border border-zinc-200 flex justify-between items-center ">
                                <p className="text-slate-800 text-[15px] font-TTHovesM">
                                    Bookings
                                </p>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6 text-zinc-400">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                                    />
                                </svg>
                            </div>
                            <div className="p-3">
                                <div className="flex flex-col gap-2 border-l border-zinc-200 pl-3">
                                    {/*<Link*/}
                                    {/*    to=""*/}
                                    {/*    className={classNames(styles.link, {*/}
                                    {/*        'text-slate-800':*/}
                                    {/*            location.pathname === '',*/}
                                    {/*    })}>*/}
                                    {/*    Past Bookings*/}
                                    {/*</Link>*/}
                                    {/*<Link*/}
                                    {/*    to=""*/}
                                    {/*    className={classNames(styles.link, {*/}
                                    {/*        'text-slate-800':*/}
                                    {/*            location.pathname === '',*/}
                                    {/*    })}>*/}
                                    {/*    My Sessions*/}
                                    {/*</Link>*/}
                                    {/*<Link*/}
                                    {/*    to=""*/}
                                    {/*    className={classNames(styles.link, {*/}
                                    {/*        'text-slate-800':*/}
                                    {/*            location.pathname === '',*/}
                                    {/*    })}>*/}
                                    {/*    Booking Session types*/}
                                    {/*</Link>*/}
                                    <Link
                                        to="/dashboard/availability"
                                        className={classNames(styles.link, {
                                            'text-slate-800':
                                                location.pathname ===
                                                '/dashboard/availability',
                                        })}>
                                        Availability Preferences
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="">
                        <div className="px-3 py-2.5 rounded-md border border-zinc-200 flex justify-between items-center ">
                            <p className="text-slate-800 text-[15px] font-TTHovesM">
                                Photoshoots
                            </p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 text-zinc-400">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                                />
                            </svg>
                        </div>
                        <div className="p-3">
                            <div className="flex flex-col border-l gap-2 border-zinc-200 pl-3">
                                <Link
                                    to="/dashboard/photoshoot/requests"
                                    className={classNames(styles.link, {
                                        'text-slate-800':
                                            location.pathname ===
                                            '/dashboard/photoshoot/requests',
                                    })}>
                                    Requests
                                </Link>

                                <Link
                                    to="/dashboard/photoshoot/bookings"
                                    className={classNames(styles.link, {
                                        'text-slate-800':
                                            location.pathname ===
                                            '/dashboard/photoshoot/bookings',
                                    })}>
                                    Active Bookings
                                </Link>
                                <Link
                                    to="/dashboard/photoshoot/past-bookings"
                                    className={classNames(styles.link, {
                                        'text-slate-800':
                                            location.pathname ===
                                            '/dashboard/photoshoot/past-bookings',
                                    })}>
                                    Past Bookings
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Sidebar */}

                <div className="flex md:hidden border-b border-zinc-200  -my-3 gap-4 font-medium ">
                    {user?.userType === 'Photographer' && (
                        <p
                            onClick={() => {
                                push('/dashboard/availability')
                            }}
                            className={classNames(
                                'cursor-pointer text-zinc-700 py-2',
                                {
                                    'border-b border-tale-10':
                                        location.pathname ===
                                        '/dashboard/availability',
                                },
                            )}>
                            Bookings
                        </p>
                    )}
                    <p
                        onClick={() => {
                            push('/dashboard/photoshoot/requests')
                        }}
                        className={classNames(
                            'cursor-pointer text-zinc-700 py-2',
                            {
                                'border-b border-tale-10':
                                    location.pathname ===
                                    '/dashboard/photoshoot/requests',
                            },
                        )}>
                        Photoshoots
                    </p>
                </div>
                {location.pathname.includes('/dashboard/photoshoot') && (
                    <div className="w-full p-1 overflow-auto bg-white rounded-lg border border-zinc-200  gap-2  inline-flex md:hidden">
                        <TabButton
                            label="Requests"
                            isActive={
                                location.pathname ===
                                '/dashboard/photoshoot/requests'
                            }
                            onClick={() =>
                                push('/dashboard/photoshoot/requests')
                            }
                        />
                        <TabButton
                            label="Bookings"
                            isActive={
                                location.pathname ===
                                '/dashboard/photoshoot/bookings'
                            }
                            onClick={() =>
                                push('/dashboard/photoshoot/bookings')
                            }
                        />
                    </div>
                )}

                <div className="px-2 md:px-6 flex-1">{children}</div>
            </div>
        </div>
    )
}

const styles = {
    link: 'text-slate-500 font-TTHovesM text-sm p-2',
}
