import React from 'react'
import styles from 'pages/auth/Auth.module.css'
import { useFormik } from 'formik'
import TimezoneSelect from 'react-timezone-select'
import { components } from 'react-select'
import { Input } from 'components/form/Input/Input'
import { Button } from 'components/form/Button/Button'
import { pages } from 'pages/auth/CreateProfile'
import { SelectArrowIcon } from '../../css/icons/icons'
import UserService from 'services/user.service'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'
import { setUser } from '../../redux/reducers/authReducer'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { useNotifications } from 'hooks/useNotification'
import { ProfileValidation } from 'utils/validations'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import { LocationSelect } from 'components/form/LocationSelect'

const service = new UserService()

export const SetupProfile = ({ currentPage, pageChange, stepIncrement }) => {
    const user = useSelector(userSelector)
    const dispatch = useThunkDispatch()
    const { successMessage } = useNotifications()

    const {
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        values,
        touched,
        errors,
        setFieldValue,
        setFieldError,
        setFieldTouched,
    } = useFormik({
        initialValues: {
            firstName: user?.firstName ?? '',
            lastName: user?.lastName ?? '',
            email: user?.email ?? '',
            businessName: user?.businessName ?? '',
            userName: user?.userName ?? '',
            location: user?.location ?? '',
            timezone: user?.timezone ?? '',
            currency: user?.currency ?? '€ - Euro',
        },
        validationSchema: ProfileValidation,
        onSubmit: values => {
            service
                .createProfile(values)
                .then(resp => {
                    dispatch(setUser(resp?.data?.data))
                    successMessage('Profile updated successfully')
                    pageChange(pages.NEXT)
                    if (user?.userType === 'Client') {
                        stepIncrement(2)
                    } else {
                        stepIncrement(0)
                    }
                })
                .catch(error => {
                    console.log(error)
                    setFieldError('userName', error?.response?.data?.message)
                })
                .finally(() => {
                    setSubmitting(false)
                })
        },
    })

    return (
        <div
            id="page-0"
            style={{
                display: currentPage === 0 ? 'block' : 'none',
            }}>
            <div className="w-full mt-8">
                <p className="leading-10 text-body-caption-bold text-dark-8 font-OTabolas">
                    Welcome to Suura web platform!
                </p>
                <p className={`mt-1.5 ${styles.messageText}`}>
                    Let's start with the basics.{' '}
                    {user?.userType !== 'Client' &&
                        'This is what your future dream clients will see.'}
                </p>
            </div>
            <div className="mt-8">
                <form className="" onSubmit={handleSubmit}>
                    <div className="grid grid-cols-2 gap-4 pb-4">
                        <Input
                            type="text"
                            placeholder=""
                            label="First name"
                            name="firstName"
                            onChange={handleChange('firstName')}
                            onBlur={handleBlur('firstName')}
                            error={touched.firstName && errors.firstName}
                            value={values.firstName}
                        />
                        <Input
                            name="lastName"
                            onChange={handleChange('lastName')}
                            onBlur={handleBlur('lastName')}
                            error={touched.lastName && errors.lastName}
                            value={values.lastName}
                            type="text"
                            placeholder=""
                            label="Last name"
                        />
                    </div>
                    <div className="py-3">
                        <Input
                            type="text"
                            value={values.email}
                            name="email"
                            disabled={true}
                            placeholder="eg. johnsmith@gmail.com"
                            error={touched.firstName && errors.email}
                            onChange={handleChange('email')}
                            onBlur={handleBlur('email')}
                            label="Email address"
                        />
                    </div>
                    {user?.userType === 'Photographer' && (
                        <div className="py-3">
                            <Input
                                type="text"
                                placeholder=""
                                value={values.businessName}
                                name="businessName"
                                onChange={handleChange('businessName')}
                                label="Business name"
                            />
                        </div>
                    )}
                    <div className="py-3">
                        <Input
                            type="text"
                            placeholder="eg. johnsmith"
                            name="userName"
                            value={values.userName}
                            error={touched.userName && errors.userName}
                            onChange={handleChange('userName')}
                            onBlur={handleBlur('userName')}
                            label="Username"
                        />
                    </div>
                    <p className="bg-creame-1 text-gray text-base py-1 ">
                        Note: Usernames must be unique and cannot contain spaces
                        or special characters.
                    </p>
                    <div className="py-3">
                        <LocationSelect
                            value={values.location}
                            onChange={value => setFieldValue('location', value)}
                        />
                        {touched.location && errors?.location && (
                            <p className="text-red mt-0.5 ml-1 font-TTHoves text-sm">
                                {errors?.location as string}
                            </p>
                        )}
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-3">
                        <div className="relative">
                            <label className="absolute -top-3 left-4 bg-creame-1 z-10 text-gray">
                                Time Zone
                            </label>
                            <TimezoneSelect
                                value={values.timezone}
                                onChange={(data: any) =>
                                    setFieldValue('timezone', data?.value)
                                }
                                className="react-select"
                                name="timezone"
                                placeholder="Select time zone"
                                components={{ DropdownIndicator }}
                                onBlur={() => setFieldTouched('timezone')}
                                classNamePrefix="react-select"
                            />
                        </div>
                        <ReactSelect
                            placeholder="Select currency"
                            value={values.currency}
                            options={[
                                // { value: '$ - Dollar', label: '$ - Dollar' },
                                { value: '€ - Euro', label: '€ - Euro' },
                                // {
                                //     value: 'AED - Dirham',
                                //     label: 'AED - Dirham',
                                // },
                                // { value: '£ - Pound', label: '£ - Pound' },
                                // { value: 'Riyal', label: 'Riyal' },
                            ]}
                            onChange={value => setFieldValue('currency', value)}
                            label="Currency"
                        />
                    </div>
                    <div className="py-6">
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            className="bg-brown-10 disabled:cursor-not-allowed disabled:bg-opacity-70 border-brown-10 text-white px-7 h-12 w-full">
                            {isSubmitting ? 'Please wait...' : 'Continue'}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <SelectArrowIcon className="h-5 w-5" />
        </components.DropdownIndicator>
    )
}
