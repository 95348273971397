import { useCallback, useEffect, useState } from 'react'
import { Gallery, ThumbnailImageProps } from 'react-grid-gallery'
import { Link, useParams } from 'react-router-dom'
import UserService from 'services/user.service'
import { RefreshCircle } from 'iconsax-react'

const ImageComponent = (props: ThumbnailImageProps) => {
    const [show, setShow] = useState(false)

    const { src, alt, style, title } = props.imageProps
    return (
        <img
            className="object-cover object-top"
            alt={alt}
            src={src}
            title={title || ''}
            style={style}
        />
    )
}

const service = new UserService()

const fixedDimensions = [
    { width: 320, height: 174 },
    { width: 320, height: 212 },
    { width: 320, height: 213 },
    { width: 320, height: 183 },
    { width: 240, height: 320 },
    { width: 320, height: 190 },
    { width: 320, height: 148 },
    { width: 248, height: 320 },
    { width: 320, height: 113 },
    { width: 313, height: 320 },
    { width: 320, height: 194 },
    { width: 271, height: 320 },
    { width: 257, height: 320 },
    { width: 226, height: 320 },
    { width: 320, height: 215 },
    { width: 257, height: 320 },
    { width: 226, height: 320 },
]

export const PreviewGallery = () => {
    const [style, setStyle] = useState('')
    const [loading, setLoading] = useState(false)
    const [gallery, setGallery] = useState<any>({})
    const [images, setImages] = useState([])
    const { id } = useParams()

    const fetchData = useCallback(() => {
        setLoading(true)
        service
            .getSpecificGallery(id ?? '')
            .then(resp => {
                setStyle(resp.data.data.coverStyle)
                setGallery(resp.data.data)

                if (resp.data.data.coverStyle === 'beauty') {
                    setImages(
                        resp.data.data.images.map(image => {
                            const randomDimension =
                                fixedDimensions[
                                    Math.floor(
                                        Math.random() * fixedDimensions.length,
                                    )
                                ]
                            return {
                                src: `${process.env.REACT_APP_SERVER_DOMAIN}/${image}`,
                                width: randomDimension.width,
                                height: randomDimension.height,
                            }
                        }),
                    )
                } else {
                    setImages(resp.data.data.images)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [id])

    useEffect(() => {
        fetchData()
    }, [])

    if (loading) {
        return (
            <div className="h-full w-full  flex flex-col items-center justify-center">
                <RefreshCircle className="h-32 w-32 text-tale-10 animate-spin mx-auto " />
            </div>
        )
    }

    return style === 'editorial' ? (
        <Editorial gallery={gallery} images={images} />
    ) : style === 'eclipse' ? (
        <Eclipse gallery={gallery} images={images} />
    ) : (
        <div className="bg-dark-2 h-screen">
            <div className="h-96 flex flex-col items-center justify-center">
                <div className="h-28 w-28 rounded-full object-cover object-center ">
                    <img
                        alt="profile"
                        className="h-full w-full rounded-full"
                        src={`${process.env.REACT_APP_SERVER_DOMAIN}/${gallery?.userId?.profileImage}`}
                    />
                </div>
                <p className="text-white font-TTHovesM text-xl trakcing-wide">
                    {gallery?.userId?.firstName +
                        ' ' +
                        gallery?.userId?.lastName}
                </p>
                <p className=" text-7xl tracking-wide text-white font-OTabolas ">
                    {gallery?.title}
                </p>
            </div>
            <Gallery images={images} thumbnailImageComponent={ImageComponent} />
        </div>
    )
}

const Eclipse = props => {
    return (
        <div className="bg-[#0E0E0E] p-4">
            <Link
                to="/dashboard"
                className=" text-body-bold text-white font-OTabolas">
                Suura
            </Link>
            <div className="text-center py-8 h-60 flex flex-col gap-2 items-center justify-center ">
                <p className=" font-TTHovesSB text-6xl text-[#A56D5E]">
                    {props?.gallery?.title}
                </p>
                <p className="text-white font-TTHovesM text-xl trakcing-wide">
                    {props?.gallery?.userId?.firstName +
                        ' ' +
                        props?.gallery?.userId?.lastName}
                </p>
            </div>
            <div className="grid grid-flow-row grid-cols-1 items-center justify-items-center gap-32 p-8 md:px-32">
                {props.images.map(image => {
                    // Random rotation generated at render
                    const rotate = [
                        '-rotate-[3deg]',
                        '-rotate-[2deg]',
                        '-rotate-[1deg]',
                        'rotate-0',
                        'rotate-[1deg]',
                        'rotate-[2deg]',
                        'rotate-[3deg]',
                    ]
                    const random = Math.floor(Math.random() * rotate.length)

                    return (
                        <div
                            key={random}
                            className={`sticky top-28 max-w-3xl w-full transform rounded-sm bg-gray-200 p-1 shadow-xl first:top-24 ${rotate[random]} align grid justify-items-center md:p-2`}>
                            <img
                                src={`${process.env.REACT_APP_SERVER_DOMAIN}/${image}`}
                                alt={image}
                                className="z-50 w-full object-contain object-top"
                            />
                            <div
                                className={`absolute top-4 left-4 right-4 bottom-4 z-0 m-auto h-9/10 w-9/10 animate-pulse-slow bg-yellow-900`}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const Editorial = ({ images, gallery }) => {
    const image: any = `${
        process.env.REACT_APP_SERVER_DOMAIN + '/' + images[0]
    }`
    return (
        <div>
            <div
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    objectPosition: 'center center',
                }}
                className={`h-96 w-full relative flex flex-col  items-center justify-center  bg-dark-4`}>
                <p className="text-7xl tracking-wide text-white font-OTabolas ">
                    {gallery?.title}
                </p>
                <p className="place-items-end align-text-bottom absolute bottom-4  text-white font-TTHoves text-xl trakcing-wider">
                    By{' '}
                    {gallery?.userId?.firstName +
                        ' ' +
                        gallery?.userId?.lastName}
                </p>
            </div>

            <div className="grid grid-cols-2 gap-4 max-w-5xl mx-auto py-12">
                {images.map((image, index) => (
                    <div key={index} className="w-full h-96">
                        <img
                            src={`${process.env.REACT_APP_SERVER_DOMAIN}/${image}`}
                            alt={image}
                            className="h-full w-full object-cover object-top"
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}
