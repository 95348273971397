import React from 'react'
import BalanceTile from './BalanceTile'
import DepositTile from './DepositTile'
import UseBalance from 'hooks/useBalance'

const TopStrip: React.FC<{ setOpen: (boolean) => void }> = ({ setOpen }) => {
    const { userBalance } = UseBalance()
    return (
        <div className="flex flex-col md:flex-row gap-4">
            <div className="flex gap-4 flex-1">
                <div className="flex-1">
                    <BalanceTile
                        title="Available Balance"
                        date="12 August - 11 September"
                        balance={
                            userBalance ? `Є${userBalance.toFixed(2)}` : '--'
                        }
                    />
                </div>
                <div className="flex-1">
                    <BalanceTile
                        title="Total Spending"
                        date="12 August - 11 September"
                        balance="Є923.00"
                    />
                </div>
            </div>
            <div className="flex-1">
                <DepositTile setOpen={setOpen} />
            </div>
        </div>
    )
}

export default TopStrip
