import React, { Fragment, useEffect, useState } from 'react'
import { AddSquare, ArrowRight2, Home2 } from 'iconsax-react'
import { Input } from 'components/form/Input/Input'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import { RichEditor } from 'components/common/RichEditor'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import classNames from 'classnames'
import { styles } from 'pages/dashboard/Settings'
import UserService from 'services/user.service'
import { useNotifications } from 'hooks/useNotification'
import { useNavigate } from 'react-router-dom'
import { setProject } from '../../../redux/reducers/projectReducer'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { getImage } from 'pages/dashboard/business/Request'

const service = new UserService()

export const CreateInvoice = () => {
    const user = useSelector(userSelector)
    const navigate = useNavigate()
    const dispatch = useThunkDispatch()
    const { project } = useSelector((state: any) => state.project)
    const [state, setState] = useState({
        invoiceNumber: '',
        fromUser: user?.email, //user who accepted project
        issuedOn: '', //YYYY-MM-DD it should be cuurent date by default. no need to select
        billToEmail:
            project?.clients?.length > 0
                ? project?.clients[0]?.email
                : project?.proposalCreatedBy?.email, //user who created project
        offerValidity: '',
        customPackage: [
            {
                serviceName: '',
                rate: '',
            },
        ],
        description: '',
    })
    const [errors, setErrors] = useState({
        billTo: '',
        issuedOn: '',
        package: '',
        offerValidity: '',
    })

    const { successMessage } = useNotifications()

    useEffect(() => {
        service
            .getInvoiceNumber()
            .then(resp => {
                setState({
                    ...state,
                    invoiceNumber: resp.data.data.invoiceNumber,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    const handleChange = e => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value,
        })
    }

    const handleChangePackage = (e, index) => {
        const { name, value } = e.target
        const values = [...state.customPackage]
        values[index][name] = value
        setState({
            ...state,
            customPackage: values,
        })
    }

    const validateEmail = email => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(email)
    }

    const handleSubmit = () => {
        if (!state.billToEmail) {
            setErrors({
                ...errors,
                billTo: 'Please enter client email',
            })

            return
        }

        if (
            !state.customPackage[0]?.rate ||
            !state.customPackage[0]?.serviceName
        ) {
            setErrors({
                ...errors,
                package: 'Add at least one service',
            })

            return
        }

        if (!validateEmail(state.billToEmail)) {
            setErrors({
                ...errors,
                billTo: 'Please enter valid email',
            })

            return
        }

        if (!state.offerValidity) {
            setErrors({
                ...errors,
                offerValidity: 'Please select validity',
                billTo: '',
            })

            return
        }
        if (!state.issuedOn && state.offerValidity) {
            setErrors({
                ...errors,
                issuedOn: 'Please select invoice date',
                offerValidity: '',
                billTo: '',
            })

            return
        }
        service
            .createInvoice(state, project._id)
            .then(resp => {
                successMessage('Invoice created successfully')
                navigate(`/dashboard/business/deliverables/${project._id}`)
                dispatch(
                    setProject({ ...project, invoice: resp.data.data.invoice }),
                )
            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleDeleteService = i => {
        const copy = [...state.customPackage]
        copy.splice(i, 1)
        setState({
            ...state,
            customPackage: copy,
        })
    }

    return (
        <Fragment>
            <div className="flex items-center justify-between ">
                <div className="hidden items-center gap-2 md:flex">
                    <Home2 className="h-5 w-5" />
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                        Business
                    </div>
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <span>...</span>
                    <div className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                        {project?.photoshootTitle}
                    </div>
                </div>
                <div className={classNames('flex items-center gap-3')}>
                    <button
                        onClick={() => navigate(-1)}
                        type="button"
                        className={styles.default}>
                        <span className="text-slate-700 text-sm font-TTHovesM leading-tight">
                            Cancel
                        </span>
                    </button>
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        form="form7"
                        className={styles.primary}>
                        <span className="text-white text-sm font-TTHovesM leading-tight">
                            {/*{submitting ? 'Saving...' : 'Create Quote'}*/}
                            Create Invoice
                        </span>
                    </button>
                </div>
            </div>
            <div className="flex-col justify-start items-start inline-flex mt-5">
                <p className="text-zinc-900 text-lg font-TTHovesSB">
                    New Invoice
                </p>
                <p className="text-zinc-600 text-sm w-full">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Asperiores at, consequuntur reiciendis sed.
                </p>
            </div>
            <div className="flex items-start gap-6 mt-6">
                <div className="w-full md:w-2/3 flex-1 flex flex-col gap-8">
                    <div className="bg-white border border-zinc-200 rounded-lg p-5">
                        <div className="flex flex-col md:flex-row items-start gap-4 md:gap-28">
                            <div className="flex flex-col gap-1">
                                <p className="text-slate-800 font-TTHovesM">
                                    {new Date(
                                        project?.shootDate,
                                    ).toDateString()}
                                </p>
                                <p className="text-gray-600 text-[15px]">
                                    {project?.shootTime}
                                </p>
                                <div className="flex mt-1">
                                    <div className="h-8 w-8 rounded-full">
                                        <img
                                            alt="profile"
                                            src={
                                                user?.profileImage
                                                    ? `${process.env.REACT_APP_SERVER_DOMAIN}/${user?.profileImage}`
                                                    : '/assets/images/blank.webp'
                                            }
                                            className="h-full w-full rounded-full object-cover"
                                        />
                                    </div>
                                    <div className="h-8 w-8 rounded-full -ml-3">
                                        <img
                                            alt="profile"
                                            src={getImage(project, user)}
                                            className="h-full w-full rounded-full object-cover"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col gap-2">
                                <p className="text-slate-800 font-TTHovesM leading-tight">
                                    {project?.photoshootTitle}
                                </p>
                                <p className="text-gray-600 text-[15px]">
                                    {project?.locationString}
                                </p>
                                <div className="flex gap-2 ">
                                    <div className="px-3 text-center text-zinc-900 text-xs py-1.5 bg-white rounded-[7px] border border-slate-300">
                                        {project?.shootType?.name}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-start gap-4">
                        <Input
                            type="text"
                            name="invoiceNumber"
                            placeholder="Invoice"
                            value={state.invoiceNumber}
                            onChange={handleChange}
                            label="Invoice"
                        />
                        <div className="w-full leading-tight -mt-1">
                            <label className="text-zinc-500 text-sm">
                                From
                            </label>
                            <p className="font-TTHovesM ">
                                {user?.firstName + ' ' + user?.lastName}
                            </p>
                            <span className="text-sm text-zinc-700">
                                {user?.email}
                            </span>
                        </div>
                    </div>

                    <div className="flex gap-4">
                        <Input
                            type="date"
                            name="issuedOn"
                            error={errors.issuedOn}
                            min={new Date().toISOString().split('T')[0]}
                            placeholder="Invoice"
                            onChange={handleChange}
                            label="Issued on"
                        />
                        <Input
                            type="email"
                            name="billToEmail"
                            placeholder="Enter email"
                            error={errors.billTo}
                            onChange={handleChange}
                            disabled
                            label="Bill To"
                            value={state.billToEmail}
                        />
                    </div>
                    <Input
                        type="date"
                        onChange={e =>
                            setState({
                                ...state,
                                offerValidity: e.target.value,
                            })
                        }
                        min={new Date().toISOString().split('T')[0]}
                        error={errors.offerValidity}
                        label="Offer valid till"
                        placeholder="Select"
                        value={state.offerValidity}
                    />

                    <hr className="text-zinc-200 m-0 p-0 " />
                    <p className="text-zinc-900 text-lg -my-3 font-TTHovesSB leading-tight">
                        Add Payment
                    </p>
                    {/*<ReactSelect*/}
                    {/*    options={[{ value: '1 day', label: '1 day' }]}*/}
                    {/*    onChange={value =>*/}
                    {/*        console.log({*/}
                    {/*            ...values,*/}
                    {/*            collaborationId: value,*/}
                    {/*        })*/}
                    {/*    }*/}
                    {/*    label="Select Package"*/}
                    {/*    placeholder="Select"*/}
                    {/*    value={values.serviceType}*/}
                    {/*/>*/}
                    {state.customPackage.map((item, index) => (
                        <div key={index} className="flex items-center gap-4">
                            <Input
                                type="text"
                                name="serviceName"
                                error={errors.package}
                                placeholder="Service Name"
                                onChange={e => handleChangePackage(e, index)}
                                value={item.serviceName}
                                label="Item/Service Name"
                            />
                            <Input
                                type="text"
                                name="rate"
                                placeholder="0.00"
                                onChange={e => handleChangePackage(e, index)}
                                label="Rate"
                                value={item.rate}
                            />
                            {index !== 0 && (
                                <button
                                    onClick={() => handleDeleteService(index)}
                                    className="text-red">
                                    X
                                </button>
                            )}
                        </div>
                    ))}

                    <button
                        type="button"
                        onClick={() =>
                            setState({
                                ...state,
                                customPackage: [
                                    ...state.customPackage,
                                    { serviceName: '', rate: '' },
                                ],
                            })
                        }
                        className="text-sm font-TTHovesSB px-4 py-2.5 rounded-lg text-zinc-700 border w-52 gap-2 border-zinc-300 flex">
                        <AddSquare className="h-5 w-5" />
                        Add another Service
                    </button>
                    <div className="flex flex-col md:flex-row gap-4">
                        <div className="w-full md:w-2/3">
                            <p className="text-slate-800 font-TTHovesSB leading-tight">
                                Description
                            </p>
                            <p className="text-slate-600 text-sm leading-tight mt-1">
                                Write a short description.
                            </p>
                        </div>
                        <RichEditor
                            content={state.description}
                            setContent={(value: string) =>
                                setState({
                                    ...state,
                                    description: value,
                                })
                            }
                        />
                    </div>
                </div>
                <div className="hidden md:block w-[350px] bg-[#DEEDEE] rounded-xl border border-zinc-200 p-5">
                    <p className="text-zinc-800 text-lg font-TTHovesM">
                        Suura Calculator
                    </p>

                    <p className="mt-1 text-zinc-600 text-sm font-TTHovesM ">
                        The project fee is $2500.00. Fixed amount of{' '}
                        <span className="font-TTHovesSB">$125.00</span> will be
                        deducted from the total fee and you’ll get the rest
                        amount immediately after the project is completed. The
                        project will start{' '}
                        <span className="font-TTHovesSB"> Aug 1, 2023</span> and
                        is estimated to complete{' '}
                        <span className="font-TTHovesSB"> Aug 1, 2023.</span>
                    </p>
                </div>
            </div>
        </Fragment>
    )
}
