import { DocumentText } from 'iconsax-react'
import { Modal } from 'components/common/Modal'
import React, { useState } from 'react'
import { useGallery } from 'hooks/useGallery'
import classNames from 'classnames'
import UserService from 'services/user.service'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../../redux/selectors/user'

const service = new UserService()

export const DeliveryModal = ({
    deliveryModal,
    setDeliveryModal,
    fetchData,
    project,
}) => {
    const { galleries } = useGallery()
    const user = useSelector(userSelector)
    const [selected, setSelected] = useState(galleries[0]?._id)

    const handleAdd = () => {
        service
            .addGalleryProject(selected, {
                projectId: project,
                userId: user?._id,
            })
            .then(resp => {
                setDeliveryModal(false)
                fetchData()
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <Modal
            width="w-full md:w-1/2"
            showModal={deliveryModal}
            setShowModal={setDeliveryModal}
            title="Link Gallery"
            footerButton="Add to Project"
            handleClickPrimary={handleAdd}
            headerIcon={<DocumentText />}>
            {/* Form */}
            <div className="border gap-6 h-56 relative pb-14  overflow-x-auto p-4 border-gray-outline rounded-lg grid grid-cols-3">
                {galleries.map(item => (
                    <div
                        key={item._id}
                        onClick={() => setSelected(item._id)}
                        className={classNames(
                            ' rounded-lg h-44 w-full p-3  cursor-pointer hover:border hover:border-dashed border-tale-10 hover:bg-gradient-to-b hover:from-white hover:to-tale-10/2',
                            {
                                'bg-gradient-to-b border border-dashed  from-white to-tale-10/20':
                                    item._id === selected,
                            },
                        )}>
                        <img
                            alt="beauty"
                            src={`${process.env.REACT_APP_SERVER_DOMAIN}/${item.images[0]}`}
                            className="w-full h-full object-contain object-top overflow-visible rounded-lg"
                        />
                        <p className="text-center text-zinc-700 mt-4">
                            {item.title}
                        </p>
                    </div>
                ))}
            </div>
            <hr className="text-zinc-200 w-full " />
            {/* Form End */}
        </Modal>
    )
}
