import classNames from 'classnames'
import { CustomChart } from 'components/dashboard/CustomChart'
import { ArrowRight2 } from 'iconsax-react'
import { ArrowUpRightIcon } from '../../css/icons/icons'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'

export const Dashboard = () => {
    const user = useSelector(userSelector)

    return (
        <Fragment>
            <p className="text-slate-800 text-[34px] font-bold font-OTabolas leading-10">
                Welcome, {user?.firstName} 👋
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                <ColorCard
                    title="Improve profile"
                    buttonText="View Profile"
                    background="bg-violet-200"
                    subText="Get discovered by Clients."
                />
                <ColorCard
                    title="Find your next job"
                    buttonText="Browse Jobs"
                    background="bg-slate-200"
                    subText="Explore exclusive opportunities."
                />
                <ColorCard
                    title="Send invoice or proposal"
                    buttonText="Invite Client"
                    background="bg-yellow-200"
                    subText="Invite existing clients to kickoff projects."
                />
            </div>
            {/* Start Workspace */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 items-start gap-4 mt-8">
                <div className="flex-1">
                    <p className="text-slate-800 text-2xl font-OTabolas">
                        Your all-in-one
                        <br />
                        workspace
                    </p>
                    <p className="mt-1.5 text-zinc-500 font-TTHovesM">
                        Use Suura to keep track of leads,
                        <br />
                        project updates and payments.
                    </p>
                </div>
                <div className="flex-1 bg-white rounded-xl border border-zinc-200 p-5">
                    <p className="text-zinc-900 text-lg font-TTHovesSB">
                        Projects
                    </p>
                    <div className="text-zinc-500 flex items-end justify-between">
                        <p className="font-TTHovesM text-zinc-500 mt-4">
                            No new project updates
                        </p>
                        <ArrowUpRightIcon className="h-5 w-5" />
                    </div>
                </div>
                <div className="flex-1 bg-white rounded-xl border border-zinc-200 p-5">
                    <p className="text-zinc-900 text-lg font-TTHovesSB">
                        Projects
                    </p>
                    <div className="text-zinc-500 flex items-end justify-between">
                        <p className="font-TTHovesM text-zinc-500 mt-4">
                            No new project updates
                        </p>
                        <ArrowUpRightIcon className="h-5 w-5" />
                    </div>
                </div>
                <div className="flex-1 bg-white rounded-xl border border-zinc-200 p-5">
                    <p className="text-zinc-900 text-lg font-TTHovesSB">
                        Payments
                    </p>
                    <div className="text-zinc-500 flex items-end justify-between">
                        <p className="font-TTHovesM text-zinc-500 mt-4">
                            No new payments
                        </p>
                        <ArrowUpRightIcon className="h-5 w-5" />
                    </div>
                </div>
            </div>
            {/* End Workspace */}
            <div className="w-full h-px border-b border-zinc-200 py-4" />
            {/* Start Tags */}
            <div className="flex items-center gap-4 mt-5">
                <p className="text-center text-zinc-800 border border-zinc-300 rounded-full px-6 py-3 text-sm">
                    7d
                </p>
                <p className="text-center text-white bg-tale-10 rounded-full px-6 py-3 text-sm">
                    30d
                </p>
                <p className="text-center text-zinc-800 border border-zinc-300 rounded-full px-6 py-3 text-sm">
                    1y
                </p>
            </div>
            {/* End Tags */}
            <div className="w-full h-px border-b border-zinc-200 py-3" />
            <p className="text-zinc-500 text-lg font-TTHovesM mt-4">Views</p>
            <div className="flex flex-col md:flex-row items-start gap-4 w-full">
                <div className="flex-1 flex flex-col gap-4 w-full">
                    {/* Start Portfolio Views */}
                    <div className="relative rounded-xl border border-zinc-200 mt-3 p-5">
                        <div className="flex gap-4">
                            <p className="text-zinc-600 font-TTHovesM">
                                Portfolio views
                            </p>
                            <p className="bg-[#FEE4E2] px-3 py-1 text-[#B42318] rounded-3xl text-xs">
                                -22%
                            </p>
                        </div>
                        <p className="text-zinc-900 text-[40px] font-TTHovesM">
                            7
                        </p>
                        <CustomChart height={300} />
                        <div className="flex items-end justify-end">
                            <button className="py-3 px-6 bg-white rounded-[10px] border border-zinc-300 flex items-center gap-2">
                                <span className="text-center text-zinc-700 text-sm font-TTHovesM leading-tight">
                                    Share portfolio
                                </span>
                                <ArrowRight2 className="h-3 w-3 text-zinc-800" />
                            </button>
                        </div>
                    </div>
                    {/* End Portfolio Views */}
                    <p className="text-zinc-500 text-lg font-TTHovesM">Leads</p>
                    {/* Start Leads Cards */}
                    <div className="flex flex-col w-full md:flex-row gap-4">
                        <div className="flex-1 relative rounded-xl border border-zinc-200 p-5">
                            <p className="text-zinc-600 font-TTHovesM">
                                Applications
                            </p>
                            <p className="text-zinc-900 text-[40px] font-TTHovesM">
                                0
                            </p>
                            <CustomChart height={100} />
                        </div>
                        {/* Request Card */}
                        <div className="flex-1  rounded-xl border border-zinc-200 p-5">
                            <p className="text-zinc-600 font-TTHovesM">
                                Requests
                            </p>
                            <p className="text-center text-zinc-800 mt-4 leading-tight">
                                Get featured on the Discover page by <br />{' '}
                                improving your profile.
                            </p>
                            <button className="h-10 w-full mt-4 bg-white rounded-[10px] border border-zinc-300  text-center text-zinc-700 text-sm font-TTHovesM leading-tight">
                                Update Profile
                            </button>
                        </div>
                        {/* End Request Card */}
                    </div>
                    {/* End Leads */}
                    <p className="text-zinc-500 text-lg font-TTHovesM">
                        Income
                    </p>
                    {/* Start Income Cards */}
                    <div className="flex flex-col md:flex-row gap-4">
                        {/* Payouts Card Start */}
                        <div className="flex-1 w-full relative rounded-xl border border-zinc-200 p-5">
                            <p className="text-zinc-600 font-TTHovesM">
                                Total Payouts
                            </p>
                            <div
                                className="w-36 mx-auto bg-white rounded-[10px] mt-2 p-3"
                                style={{
                                    boxShadow:
                                        '0px 4px 14px 0px rgba(0, 0, 0, 0.06)',
                                }}>
                                <div className="w-[50px] h-[5px] bg-zinc-200 rounded-xl" />
                                <p className="text-zinc-700 text-lg font-TTHovesSB mt-3">
                                    $ 20,000
                                </p>
                                <div className="flex justify-end">
                                    <div className=" w-[72px] h-[5px] bg-zinc-200 rounded-[10px] mt-3" />
                                </div>
                            </div>
                            <p className="text-center text-zinc-800 mt-4 leading-tight ">
                                Get paid on time and keep 100% of
                                <br />
                                what you earn.
                            </p>
                            <button className="h-10 w-full mt-4 bg-white rounded-[10px] border border-zinc-300  text-center text-gray-700 text-sm font-TTHovesM leading-tight">
                                View wallet
                            </button>
                        </div>
                        {/* Payouts Card End */}
                        <div className="flex-1 w-full rounded-xl border border-zinc-200 p-5">
                            <p className="text-zinc-600 font-TTHovesM">
                                Invoices
                            </p>
                            <div
                                className="w-36 mx-auto bg-white rounded-[10px] mt-2 p-3"
                                style={{
                                    boxShadow:
                                        '0px 4px 14px 0px rgba(0, 0, 0, 0.06)',
                                }}>
                                <div className="w-[50px] h-[5px] bg-zinc-200 rounded-xl" />
                                <p className="text-zinc-700 text-lg font-TTHovesSB mt-3">
                                    $ 20,000
                                </p>
                                <div className="flex justify-end">
                                    <div className="w-[72px] h-[5px] bg-zinc-200 rounded-[10px] mt-3" />
                                </div>
                            </div>
                            <p className="text-center text-zinc-800 mt-4 leading-tight ">
                                Manage all of your Client payments <br /> in one
                                place.
                            </p>
                            <button className="h-10 w-full mt-4 bg-white rounded-[10px] border border-zinc-300  text-center text-gray-700 text-sm font-TTHovesM leading-tight">
                                Send Invoice
                            </button>
                        </div>
                        {/* Start Active Projects */}
                        <div className="flex-1 w-full rounded-xl border border-zinc-200 p-5">
                            <p className="text-zinc-600 font-TTHovesM">
                                Active Projects
                            </p>
                            <div
                                className="w-36 mx-auto bg-white rounded-[10px] mt-2 p-3"
                                style={{
                                    boxShadow:
                                        '0px 4px 14px 0px rgba(0, 0, 0, 0.06)',
                                }}>
                                <div className="w-[50px] h-[5px] bg-zinc-200 rounded-xl" />
                                <p className="text-zinc-700 text-lg font-TTHovesSB mt-3">
                                    $ 20,000
                                </p>
                                <div className="flex justify-end">
                                    <div className="w-[72px] h-[5px] bg-zinc-200 rounded-[10px] mt-3" />
                                </div>
                            </div>
                            <p className="text-center text-zinc-800 mt-4 leading-tight">
                                Streamline your contract creation <br /> and
                                client workflows.
                            </p>
                            <button className="h-10 w-full mt-4 bg-white rounded-[10px] border border-zinc-300 text-center text-zinc-700 text-sm font-TTHovesM leading-tight">
                                Send Proposal
                            </button>
                        </div>
                        {/* End Active Projects */}
                    </div>
                    {/* End Income */}
                </div>
                {/* Start Right Card Surra Pro */}
                <div className="sticky top-20 w-full md:w-[416px]  bg-white rounded-xl border border-zinc-200 mt-3 p-5">
                    <div className="flex items-center justify-between">
                        <p className="text-slate-800 text-lg font-TTHovesSB">
                            Suura Pro
                        </p>
                        <p className="bg-zinc-100 rounded text-gray-600 text-sm p-2">
                            <span className="text-zinc-600 font-TTHovesM">
                                $12
                            </span>
                            USD / month
                        </p>
                    </div>
                    <p className="text-slate-800 text-lg font-TTHovesSB py-4">
                        Unlock who's viewed your portfolio
                    </p>
                    <div className="flex items-center justify-between gap-3">
                        {/* Start Avatars */}
                        <div className="flex">
                            <div className="h-12 w-12 rounded-full ring-1 ring-white">
                                <img
                                    alt="avatar"
                                    className="h-full w-full rounded-full"
                                    src="/assets/homepage-photographer-1.jpg"
                                />
                            </div>
                            <div className="h-12 w-12 rounded-full ring-1 ring-white -ml-3">
                                <img
                                    alt="avatar"
                                    className="h-full w-full rounded-full"
                                    src="/assets/homepage-photographer-2.jpg"
                                />
                            </div>
                            <div className="h-12 w-12 rounded-full ring-1 ring-white -ml-3">
                                <img
                                    alt="avatar"
                                    className="h-full w-full rounded-full"
                                    src="/assets/homepage-image-2.jpg"
                                />
                            </div>
                        </div>
                        {/* End Avatars */}
                        <p className="text-zinc-500 font-medium flex-1">
                            Connect and collaborate with most popular clients
                        </p>
                    </div>
                    <div className="flex items-center gap-2 mt-7">
                        <p className="text-zinc-500 text-[10px] font-medium tracking-wider">
                            WHAT ELSE IS INCLUDED
                        </p>
                        <div className="flex-1 h-px bg-neutral-200" />
                    </div>

                    <div className="mt-4">
                        <div className="text-zinc-900 text-base font-TTHovesM">
                            Traffic views by source
                        </div>
                        <div className="text-zinc-500 text-sm font-TTHovesL">
                            Understand which sources are driving visitors.
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="text-zinc-900 text-base font-TTHovesM">
                            Project views
                        </div>
                        <div className="text-zinc-500 text-sm font-TTHovesL">
                            Identify your top projects Clients are interested
                            in.
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="text-zinc-900 text-base font-TTHovesM">
                            Service views
                        </div>
                        <div className="text-zinc-500 text-sm font-TTHovesL">
                            Boost bookings by tracking your top services.
                        </div>
                    </div>

                    <button
                        disabled
                        className="mt-4 disabled:cursor-not-allowed w-full text-zinc-300 font-TTHovesM  h-12 text-center py-3 bg-zinc-100 rounded-full">
                        On waitlist
                    </button>
                </div>
                {/* End Right Card Surra Pro */}
            </div>
        </Fragment>
    )
}

const ColorCard = ({ background, title, subText, buttonText }) => {
    return (
        <div
            className={classNames('flex-1 bg-opacity-80 rounded-xl p-5', [
                background,
            ])}>
            <p className="text-zinc-800 text-lg font-TTHovesSB">{title}</p>
            <p className="text-zinc-800 font-TTHovesM mt-1.5">{subText}</p>
            <div className="flex justify-between mt-5">
                <div />
                <button className="text-center text-white font-medium px-6 py-2.5 bg-zinc-800 rounded-xl">
                    {buttonText}
                </button>
            </div>
        </div>
    )
}
