import { Eye } from 'iconsax-react'
import moment from 'moment'

const TableRow = ({ item }) => {
    return (
        <div className="relative flex flex-col md:flex-row border border-primary rounded-xl md:items-center py-5">
            <div className="flex-1 pl-7">
                <p className="text-dark-8 leading-[24px] font-TTHovesM">
                    {'#21341321'}
                </p>
                <span className="text-[14px] leading-[20px] font-TTHovesL text-dark-5">
                    {'Transection ID Dummy'}
                </span>
            </div>
            <div className="flex-1 pl-7 md:pl-0 md:text-center text-dark-8 leading-[24px] ">
                {moment(item.date).format('MMM DD, YYYY')}
            </div>
            <div className="flex-1 pl-7 md:pl-0 md:text-center text-dark-8 leading-[24px] ">
                {'Expense Dummy'}
            </div>
            <div className="flex-1 pl-7 md:pl-0 md:text-center text-dark-8 leading-[24px] capitalize">
                {item.type}
            </div>
            <div className="flex-1 pl-7 md:pl-0 md:text-center leading-[24px] font-TTHovesM text-brown-10">
                {`Є${item.amount.toFixed(2)}`}
            </div>
            <div className="md:flex-1 pl-7 md:pl-0 md:text-center pr-7">
                <button
                    className="absolute md:static top-6 right-6 flex gap-3 md:mx-auto pl-3 items-center font-TTHovesM text-[14px] leading-[20px] border border-primary rounded-md py-[10px] px-[22px]"
                    type="button">
                    <Eye size="20px" color="#69884F" />
                    {'View'}
                </button>
            </div>
        </div>
    )
}

export default TableRow
