import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { IconSearch } from '../../css/icons/icons'
import {
    ProfileSetting,
    Help,
    AccountSetting,
    EmailPreferences,
    WorkPreferences,
    Billing,
} from 'components/dashboard/settings'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'
import { useNavigate, useSearchParams } from 'react-router-dom'
import UserService from 'services/user.service'
import { RefreshCircle } from 'iconsax-react'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'

export const TABS = {
    profile: 'PROFILE',
    account: 'ACCOUNT',
    work: 'WORK',
    email: 'EMAIL',
    help: 'HELP',
    badge: 'BADGE',
    billing: 'BILLING',
}

const service = new UserService()

export const Settings = () => {
    const [params] = useSearchParams()
    const navigate = useNavigate()
    const currentUser = useSelector(userSelector)
    const [tab, setTab] = useState('profile')
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState<any>({})

    useEffect(() => {
        setLoading(true)
        service
            .getSingleUser(currentUser?.userName)
            .then(resp => {
                setUser(resp.data.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    return (
        <div className="p-0 md:p-5">
            <div className="flex items-center justify-between border-b border-zinc-200 pb-2 md:pb-6">
                <div className="text-[#2B2A40] text-[28px] font-TTHovesM">
                    Settings
                </div>
                <div className="relative">
                    {/*<input*/}
                    {/*    className="bg-white w-60 md:w-72 border border-zinc-200 focus:ring-2 ring-brown-10 ring-opacity-50 focus:outline-none rounded-lg pl-10 pr-3.5 py-2"*/}
                    {/*    placeholder="Search"*/}
                    {/*/>*/}
                    {/*<IconSearch className="absolute left-3 top-[11px]" />*/}
                </div>
            </div>

            <div className="flex flex-col  md:flex-row gap-4 md:gap-12 mt-6">
                {/* Sidebar */}

                <div className="block md:hidden ">
                    <ReactSelect
                        value={tab}
                        onChange={value => {
                            navigate(`/dashboard/settings?tab=${value}`)
                            setTab(value)
                        }}
                        options={[
                            { label: 'Profile Settings', value: 'profile' },
                            { label: 'Account Settings', value: 'account' },
                            { label: 'Work Preferences', value: 'work' },
                            {
                                label: 'Billing Settings',
                                value: 'billing',
                            },
                            { label: 'Email Preferences', value: 'email' },
                            { label: 'Help', value: 'help' },
                        ]}
                        placeholder="Select"
                    />
                </div>

                <div className="w-full md:w-[200px] flex-row md:flex-col gap-2 hidden md:inline-flex overflow-auto">
                    <button
                        type="button"
                        onClick={() =>
                            navigate('/dashboard/settings?tab=profile')
                        }
                        className={classNames(styles.tabButton, {
                            [styles.tabActive]: params.get('tab') === 'profile',
                        })}>
                        Profile Settings
                    </button>
                    <button
                        type="button"
                        onClick={() =>
                            navigate('/dashboard/settings?tab=account')
                        }
                        className={classNames(styles.tabButton, {
                            [styles.tabActive]: params.get('tab') === 'account',
                        })}>
                        Account Settings
                    </button>

                    {user?.userType === 'Photographer' && (
                        <button
                            type="button"
                            onClick={() =>
                                navigate('/dashboard/settings?tab=work')
                            }
                            className={classNames(styles.tabButton, {
                                [styles.tabActive]:
                                    params.get('tab') === 'work',
                            })}>
                            Work Preferences
                        </button>
                    )}
                    {user?.userType === 'Photographer' && (
                        <button
                            type="button"
                            onClick={() =>
                                navigate('/dashboard/settings?tab=billing')
                            }
                            className={classNames(styles.tabButton, {
                                [styles.tabActive]:
                                    params.get('tab') === 'billing',
                            })}>
                            Billing Settings
                        </button>
                    )}
                    <button
                        type="button"
                        onClick={() =>
                            navigate('/dashboard/settings?tab=email')
                        }
                        className={classNames(styles.tabButton, {
                            [styles.tabActive]: params.get('tab') === 'email',
                        })}>
                        Email Preferences
                    </button>
                    <button
                        type="button"
                        onClick={() => navigate('/dashboard/settings?tab=help')}
                        className={classNames(styles.tabButton, {
                            [styles.tabActive]: params.get('tab') === 'help',
                        })}>
                        Help
                    </button>
                    {/*<button*/}
                    {/*    type="button"*/}
                    {/*    onClick={() => setTab(TABS.badge)}*/}
                    {/*    className={classNames(styles.tabButton, {*/}
                    {/*        [styles.tabActive]: tab === TABS.badge,*/}
                    {/*    })}>*/}
                    {/*    Suura Badge*/}
                    {/*</button>*/}
                </div>
                {/* Sidebar End */}
                {loading ? (
                    <RefreshCircle className="text-tale-10 animate-spin size-14 flex mx-auto items-center justify-center h-[calc(100%-300px)]" />
                ) : (
                    <div className="flex-1">
                        {/*  Profile Image */}
                        <ProfileSetting
                            user={user}
                            tab={params.get('tab') === 'profile'}
                        />
                        <AccountSetting
                            user={user}
                            tab={params.get('tab') === 'account'}
                        />
                        <WorkPreferences
                            user={user}
                            tab={params.get('tab') === 'work'}
                        />
                        <EmailPreferences
                            user={user}
                            tab={params.get('tab') === 'email'}
                        />
                        <Help tab={params.get('tab') === 'help'} />
                        <Billing
                            user={user}
                            tab={params.get('tab') === 'billing'}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export const styles = {
    primary:
        'px-4 py-2.5 disabled:bg-opacity-50 bg-tale-10 rounded-lg text-white justify-center items-center gap-2 inline-flex disabled:bg-opacity-70',
    default:
        'px-4 py-2.5 bg-white rounded-lg border border-zinc-300 justify-center items-center gap-3.5 inline-flex',
    tabButton:
        'focus:outline-none text-left px-3 py-2 text-slate-500 font-TTHovesM text-[15px] whitespace-nowrap',
    tabActive:
        'bg-white rounded-md border border-black border-opacity-5 text-zinc-700 w-full',
}
