import ImagesGrid from 'components/landing/ImagesGrid'
import { Button, IconButton } from 'components/form/Button/Button'
import {
    Arrow,
    I3Square,
    MoneyTick,
    ReceiptEdit,
    SearchNormal,
    Star1,
    SunFog,
} from 'iconsax-react'
import Photographer from 'components/landing/home/Photographer'
import Category from 'components/landing/home/Category'
import { MessageNotification } from 'components/landing/home/MessageNotification'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Hero } from 'components/landing/Hero'
import { ImageCard } from 'components/landing/photographer/ImageCard'
import UserService from 'services/user.service'
import { useNavigate } from 'react-router-dom'

const CATEGORIES_IMAGES = [
    '/assets/Frame1.png',
    '/assets/Frame2.png',
    '/assets/Frame3.png',
    '/assets/Frame4.png',
    '/assets/Frame6.png',
    '/assets/Frame7.png',
    '/assets/Frame8.png',
    '/assets/Frame9.png',
    '/assets/Frame0.png',
    '/assets/Frame11.png',
    '/assets/Frame12.png',
    '/assets/Frame13.png',
    '/assets/Frame14.png',
    '/assets/Frame15.png',
    '/assets/test.png',
]

const service = new UserService()

export const Home = () => {
    const navigate = useNavigate()
    const [data, setData] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        limit: 6,
        page: 1,
    })

    const fetchData = useCallback(() => {
        setLoading(true)
        service
            .getAllPhotographers({ ...pagination })
            .then(resp => {
                setData(resp.data.data)
            })
            .catch(() => {
                console.log('Error Photographers profile')
            })
            .finally(() => {
                setLoading(false)
            })
    }, [pagination])

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Fragment>
            {/*<div className="my-16 flex mx-auto text-center text-3xl md:text-heading-bold font-OTabolas md:w-[646px]">*/}
            {/*    Connecting clients and photographers worldwide.*/}
            {/*</div>*/}
            <div className="mb-16 relative">
                <Hero />
                {/*<ImagesGrid />*/}
                {/*<div className="hidden md:block -mr-10">*/}
                {/*    <img alt="" src="/assets/images/header1.png" />*/}
                {/*</div>*/}
                {/*<div className="block md:hidden -mx-5">*/}
                {/*    <img alt="" src="/assets/images/header2.png" />*/}
                {/*</div>*/}
                {/*<div*/}
                {/*    style={{*/}
                {/*        background:*/}
                {/*            'linear-gradient(180deg, rgba(255, 251, 249, 0.00) 0%, #FFFBF9 100%)',*/}
                {/*        width: '100%',*/}
                {/*    }}*/}
                {/*    className="w-full absolute h-[140px] bottom-0 "*/}
                {/*/>*/}
                {/*<div className="w-full absolute h-[120px] bottom-0 bg-gradient-to-b from-transparent via-[#FFFBF9] to-[#FFFBF9]" />*/}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 w-full">
                <div className="pb-4">
                    <span className="font-TTHoves text-body-normal text-dark-5">
                        Explore more
                    </span>
                    <div className="md:w-[437px] font-OTabolas text-sub-heading-bold text-dark-8 mt-2">
                        <p>
                            Our Most <br />
                            popular services
                            {/*<span className="ml-2 relative align-middle">*/}
                            {/*    <span className="bottom-0 inline-block">*/}
                            {/*        <img*/}
                            {/*            src="/assets/author-image-2.jpg"*/}
                            {/*            alt="signup-display-picture"*/}
                            {/*            className="h-10 w-10 rounded-full"*/}
                            {/*        />*/}
                            {/*    </span>*/}
                            {/*    <span className="-ml-2 inline-block">*/}
                            {/*        <img*/}
                            {/*            src="/assets/author-image-3.jpg"*/}
                            {/*            alt="signup-display-picture"*/}
                            {/*            className="h-10 w-10 rounded-full"*/}
                            {/*        />*/}
                            {/*    </span>*/}
                            {/*</span>*/}
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex gap-4 max-w-full  overflow-x-auto">
                {CATEGORIES_IMAGES.map((image, i) => (
                    <div key={i} className="w-56 flex-shrink-0">
                        <img
                            src={image}
                            alt={`cat ${i}`}
                            className="h-full w-full object-contain"
                        />
                    </div>
                ))}
                {/*<div className="font-TTHoves text-dark-8-5 text-normal md:w-[424px] my-4 md:my-8">*/}
                {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit,*/}
                {/*    sed do eiusmod tempor incididunt ut labore et dolore*/}
                {/*    magna aliqua. Ut enim ad minim veniam*/}
                {/*</div>*/}
                {/*<Button className="flex items-center bg-brown-10 mt-8 md:mt-0 text-white border-none">*/}
                {/*    <span className="px-2">Discover now</span>*/}
                {/*    <SearchNormal className="text-white w-5 h-5" />*/}
                {/*</Button>*/}
            </div>
            <div className="mt-20 flex flex-col md:flex-row justify-between md:items-center">
                <div className="flex-1">
                    <span className="font-TTHoves text-body-normal text-dark-5">
                        The perfect match
                    </span>
                    <p className="font-OTabolas text-sub-heading-bold text-dark-8 mt-2">
                        Only the best <br /> photographers are on Suura
                    </p>
                </div>

                <IconButton
                    text=" Find Photographer"
                    icon={<SearchNormal className="h-5 w-5" />}
                    variant="orange"
                    className=" bg-n-orange text-white mx-2 px-4 "
                    onClick={() => navigate('/photographers')}
                />
            </div>

            <div className="border-0 md:border-t md:border-l border-zinc-200 grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-0 lg:grid-cols-3 mt-10">
                {data?.map((item: any) => (
                    <ImageCard
                        key={item._id}
                        id={item._id}
                        name={item.firstName + ' ' + item.lastName}
                        type={item.type}
                        status={item.workPreferences.availibility}
                        price={item.servicePreferences.hourlyRate}
                        tier={item?.settings?.currentTier?.tier}
                        skills={item.sessions
                            ?.map(item => item?.session?.name)
                            ?.slice(0, 2)}
                        avatar={
                            item.profileImage
                                ? `${process.env.REACT_APP_SERVER_DOMAIN}/${item.profileImage}`
                                : null
                        }
                        userName={item?.userName}
                        currencySymbol={item?.currencySymbol}
                        location={item.location}
                        image={item.coverImage}
                        portfolio={item.portfolio}
                    />
                ))}
            </div>

            {/* Photographer Start */}
            {/*<div className="grid grid-cols-1 md:grid-cols-4 gap-4 box-content- mt-6">*/}
            {/*    <Photographer*/}
            {/*        src="/assets/homepage-photographer-1.jpg"*/}
            {/*        name="Melanie Beatrice"*/}
            {/*        location="Spokane, WA, USA."*/}
            {/*        buttonChildren={*/}
            {/*            <ul className="list-disc px-2">*/}
            {/*                <li>Top Photographer</li>*/}
            {/*            </ul>*/}
            {/*        }*/}
            {/*        className="md:col-span-1 h-[432px]"*/}
            {/*    />*/}
            {/*    <Photographer*/}
            {/*        src="/assets/homepage-photographer-2.jpg"*/}
            {/*        name="Jannely Affandi"*/}
            {/*        location="Salt Lake City, Utah, USA"*/}
            {/*        buttonChildren={*/}
            {/*            <ul className="list-disc px-2">*/}
            {/*                <li>Performer</li>*/}
            {/*            </ul>*/}
            {/*        }*/}
            {/*        className="md:col-span-1 h-[432px]"*/}
            {/*    />*/}
            {/*    <Photographer*/}
            {/*        src="/assets/homepage-photographer-3.jpg"*/}
            {/*        name="Autumn Waldman Photography"*/}
            {/*        location="Wahoo, NE, USA."*/}
            {/*        buttonChildren={*/}
            {/*            <div className="flex">*/}
            {/*                <span className="pr-4">Pro Photographer</span>*/}
            {/*                <Star1 />*/}
            {/*            </div>*/}
            {/*        }*/}
            {/*        className="col-span-1 md:col-span-2 h-[432px]"*/}
            {/*    />*/}
            {/*</div>*/}
            {/* Photographer End */}

            <div className="rounded-xl bg-hero p-12 mt-20">
                <div className="flex flex-col md:flex-row items-center">
                    <div className="w-full md:w-2/3">
                        <p className="font-OTabolas text-xl">Suura Pro</p>
                        <p className="text-2xl md:text-heading-bold font-OTabolas">
                            Elevate Your Photography Business with Pro and Elite
                            Plans
                        </p>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="mt-2 md:mt-0">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17.9012 4.99851C18.1071 5.49653 18.5024 5.8924 19.0001 6.09907L20.7452 6.82198C21.2433 7.02828 21.639 7.42399 21.8453 7.92206C22.0516 8.42012 22.0516 8.97974 21.8453 9.47781L21.1229 11.2218C20.9165 11.7201 20.9162 12.2803 21.1236 12.7783L21.8447 14.5218C21.9469 14.7685 21.9996 15.0329 21.9996 15.2999C21.9997 15.567 21.9471 15.8314 21.8449 16.0781C21.7427 16.3249 21.5929 16.549 21.4041 16.7378C21.2152 16.9266 20.991 17.0764 20.7443 17.1785L19.0004 17.9009C18.5023 18.1068 18.1065 18.5021 17.8998 18.9998L17.1769 20.745C16.9706 21.2431 16.575 21.6388 16.0769 21.8451C15.5789 22.0514 15.0193 22.0514 14.5212 21.8451L12.7773 21.1227C12.2792 20.9169 11.7198 20.9173 11.2221 21.1239L9.47689 21.8458C8.97912 22.0516 8.42001 22.0514 7.92237 21.8453C7.42473 21.6391 7.02925 21.2439 6.82281 20.7464L6.09972 19.0006C5.8938 18.5026 5.49854 18.1067 5.00085 17.9L3.25566 17.1771C2.75783 16.9709 2.36226 16.5754 2.15588 16.0777C1.94951 15.5799 1.94923 15.0205 2.1551 14.5225L2.87746 12.7786C3.08325 12.2805 3.08283 11.7211 2.8763 11.2233L2.15497 9.47678C2.0527 9.2301 2.00004 8.96568 2 8.69863C1.99996 8.43159 2.05253 8.16715 2.15472 7.92043C2.25691 7.67372 2.40671 7.44955 2.59557 7.26075C2.78442 7.07195 3.00862 6.92222 3.25537 6.8201L4.9993 6.09772C5.49687 5.89197 5.89248 5.4972 6.0993 5.00006L6.82218 3.25481C7.02848 2.75674 7.42418 2.36103 7.92222 2.15473C8.42027 1.94842 8.97987 1.94842 9.47792 2.15473L11.2218 2.87712C11.7199 3.08291 12.2793 3.08249 12.7771 2.87595L14.523 2.15585C15.021 1.94966 15.5804 1.9497 16.0784 2.15597C16.5763 2.36223 16.972 2.75783 17.1783 3.25576L17.9014 5.00153L17.9012 4.99851Z"
                                        fill="#2B293F"
                                    />
                                    <path
                                        d="M9 12L11 14L15.5 9.5"
                                        stroke="#DAD1BD"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <p className="font-TTHovesSB text-zinc-800">
                                    Grow your business
                                </p>
                                <p className="text-zinc-700 font-TTHoves md:w-2/3">
                                    Want to grow your business? Could you use
                                    more clients? Suura helps your business get
                                    your ideal clients.
                                </p>
                            </div>
                            <div className="mt-2 md:mt-0">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17.9012 4.99851C18.1071 5.49653 18.5024 5.8924 19.0001 6.09907L20.7452 6.82198C21.2433 7.02828 21.639 7.42399 21.8453 7.92206C22.0516 8.42012 22.0516 8.97974 21.8453 9.47781L21.1229 11.2218C20.9165 11.7201 20.9162 12.2803 21.1236 12.7783L21.8447 14.5218C21.9469 14.7685 21.9996 15.0329 21.9996 15.2999C21.9997 15.567 21.9471 15.8314 21.8449 16.0781C21.7427 16.3249 21.5929 16.549 21.4041 16.7378C21.2152 16.9266 20.991 17.0764 20.7443 17.1785L19.0004 17.9009C18.5023 18.1068 18.1065 18.5021 17.8998 18.9998L17.1769 20.745C16.9706 21.2431 16.575 21.6388 16.0769 21.8451C15.5789 22.0514 15.0193 22.0514 14.5212 21.8451L12.7773 21.1227C12.2792 20.9169 11.7198 20.9173 11.2221 21.1239L9.47689 21.8458C8.97912 22.0516 8.42001 22.0514 7.92237 21.8453C7.42473 21.6391 7.02925 21.2439 6.82281 20.7464L6.09972 19.0006C5.8938 18.5026 5.49854 18.1067 5.00085 17.9L3.25566 17.1771C2.75783 16.9709 2.36226 16.5754 2.15588 16.0777C1.94951 15.5799 1.94923 15.0205 2.1551 14.5225L2.87746 12.7786C3.08325 12.2805 3.08283 11.7211 2.8763 11.2233L2.15497 9.47678C2.0527 9.2301 2.00004 8.96568 2 8.69863C1.99996 8.43159 2.05253 8.16715 2.15472 7.92043C2.25691 7.67372 2.40671 7.44955 2.59557 7.26075C2.78442 7.07195 3.00862 6.92222 3.25537 6.8201L4.9993 6.09772C5.49687 5.89197 5.89248 5.4972 6.0993 5.00006L6.82218 3.25481C7.02848 2.75674 7.42418 2.36103 7.92222 2.15473C8.42027 1.94842 8.97987 1.94842 9.47792 2.15473L11.2218 2.87712C11.7199 3.08291 12.2793 3.08249 12.7771 2.87595L14.523 2.15585C15.021 1.94966 15.5804 1.9497 16.0784 2.15597C16.5763 2.36223 16.972 2.75783 17.1783 3.25576L17.9014 5.00153L17.9012 4.99851Z"
                                        fill="#2B293F"
                                    />
                                    <path
                                        d="M9 12L11 14L15.5 9.5"
                                        stroke="#DAD1BD"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <p className="font-TTHovesSB text-zinc-800">
                                    All communication on one platform
                                </p>
                                <p className="text-zinc-700 font-TTHoves md:w-2/3">
                                    Chat with your clients, send questionaires,
                                    take notes, plan dates, right in your Suura
                                    dashboard.
                                </p>
                            </div>
                            <div className="mt-2 md:mt-0">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17.9012 4.99851C18.1071 5.49653 18.5024 5.8924 19.0001 6.09907L20.7452 6.82198C21.2433 7.02828 21.639 7.42399 21.8453 7.92206C22.0516 8.42012 22.0516 8.97974 21.8453 9.47781L21.1229 11.2218C20.9165 11.7201 20.9162 12.2803 21.1236 12.7783L21.8447 14.5218C21.9469 14.7685 21.9996 15.0329 21.9996 15.2999C21.9997 15.567 21.9471 15.8314 21.8449 16.0781C21.7427 16.3249 21.5929 16.549 21.4041 16.7378C21.2152 16.9266 20.991 17.0764 20.7443 17.1785L19.0004 17.9009C18.5023 18.1068 18.1065 18.5021 17.8998 18.9998L17.1769 20.745C16.9706 21.2431 16.575 21.6388 16.0769 21.8451C15.5789 22.0514 15.0193 22.0514 14.5212 21.8451L12.7773 21.1227C12.2792 20.9169 11.7198 20.9173 11.2221 21.1239L9.47689 21.8458C8.97912 22.0516 8.42001 22.0514 7.92237 21.8453C7.42473 21.6391 7.02925 21.2439 6.82281 20.7464L6.09972 19.0006C5.8938 18.5026 5.49854 18.1067 5.00085 17.9L3.25566 17.1771C2.75783 16.9709 2.36226 16.5754 2.15588 16.0777C1.94951 15.5799 1.94923 15.0205 2.1551 14.5225L2.87746 12.7786C3.08325 12.2805 3.08283 11.7211 2.8763 11.2233L2.15497 9.47678C2.0527 9.2301 2.00004 8.96568 2 8.69863C1.99996 8.43159 2.05253 8.16715 2.15472 7.92043C2.25691 7.67372 2.40671 7.44955 2.59557 7.26075C2.78442 7.07195 3.00862 6.92222 3.25537 6.8201L4.9993 6.09772C5.49687 5.89197 5.89248 5.4972 6.0993 5.00006L6.82218 3.25481C7.02848 2.75674 7.42418 2.36103 7.92222 2.15473C8.42027 1.94842 8.97987 1.94842 9.47792 2.15473L11.2218 2.87712C11.7199 3.08291 12.2793 3.08249 12.7771 2.87595L14.523 2.15585C15.021 1.94966 15.5804 1.9497 16.0784 2.15597C16.5763 2.36223 16.972 2.75783 17.1783 3.25576L17.9014 5.00153L17.9012 4.99851Z"
                                        fill="#2B293F"
                                    />
                                    <path
                                        d="M9 12L11 14L15.5 9.5"
                                        stroke="#DAD1BD"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <p className="font-TTHovesSB text-zinc-800">
                                    More Storage
                                </p>
                                <p className="text-zinc-700 font-TTHoves  md:w-2/3">
                                    Our free plan gives you 10gb - need more
                                    space for your client galleries? Our pro
                                    tiers scale with you as your business grows.
                                </p>
                            </div>
                            <div className="mt-2 md:mt-0">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17.9012 4.99851C18.1071 5.49653 18.5024 5.8924 19.0001 6.09907L20.7452 6.82198C21.2433 7.02828 21.639 7.42399 21.8453 7.92206C22.0516 8.42012 22.0516 8.97974 21.8453 9.47781L21.1229 11.2218C20.9165 11.7201 20.9162 12.2803 21.1236 12.7783L21.8447 14.5218C21.9469 14.7685 21.9996 15.0329 21.9996 15.2999C21.9997 15.567 21.9471 15.8314 21.8449 16.0781C21.7427 16.3249 21.5929 16.549 21.4041 16.7378C21.2152 16.9266 20.991 17.0764 20.7443 17.1785L19.0004 17.9009C18.5023 18.1068 18.1065 18.5021 17.8998 18.9998L17.1769 20.745C16.9706 21.2431 16.575 21.6388 16.0769 21.8451C15.5789 22.0514 15.0193 22.0514 14.5212 21.8451L12.7773 21.1227C12.2792 20.9169 11.7198 20.9173 11.2221 21.1239L9.47689 21.8458C8.97912 22.0516 8.42001 22.0514 7.92237 21.8453C7.42473 21.6391 7.02925 21.2439 6.82281 20.7464L6.09972 19.0006C5.8938 18.5026 5.49854 18.1067 5.00085 17.9L3.25566 17.1771C2.75783 16.9709 2.36226 16.5754 2.15588 16.0777C1.94951 15.5799 1.94923 15.0205 2.1551 14.5225L2.87746 12.7786C3.08325 12.2805 3.08283 11.7211 2.8763 11.2233L2.15497 9.47678C2.0527 9.2301 2.00004 8.96568 2 8.69863C1.99996 8.43159 2.05253 8.16715 2.15472 7.92043C2.25691 7.67372 2.40671 7.44955 2.59557 7.26075C2.78442 7.07195 3.00862 6.92222 3.25537 6.8201L4.9993 6.09772C5.49687 5.89197 5.89248 5.4972 6.0993 5.00006L6.82218 3.25481C7.02848 2.75674 7.42418 2.36103 7.92222 2.15473C8.42027 1.94842 8.97987 1.94842 9.47792 2.15473L11.2218 2.87712C11.7199 3.08291 12.2793 3.08249 12.7771 2.87595L14.523 2.15585C15.021 1.94966 15.5804 1.9497 16.0784 2.15597C16.5763 2.36223 16.972 2.75783 17.1783 3.25576L17.9014 5.00153L17.9012 4.99851Z"
                                        fill="#2B293F"
                                    />
                                    <path
                                        d="M9 12L11 14L15.5 9.5"
                                        stroke="#DAD1BD"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <p className="font-TTHovesSB text-zinc-800">
                                    Exclusive Features
                                </p>
                                <p className="text-zinc-700 font-TTHoves md:w-2/3">
                                    Access advanced tools, customizable
                                    galleries, and priority support to enhance
                                    your workflow.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 md:mt-0">
                        <img
                            src="/assets/Frame90.png"
                            className="h-full w-full"
                            alt="cover"
                        />
                    </div>
                </div>
                <Button
                    onClick={() => navigate('/signup?role=Photographer')}
                    className="flex mt-4 md:mt-0 justify-center md:justify-start mx-auto md:mx-0 items-center bg-n-dark px-6 text-white border-none">
                    <span className="px-0">Get started for free</span>
                </Button>
                <p className="text-sm text-center md:text-left text-zinc-700 mt-2 pl-3">
                    No credit card required
                </p>
            </div>

            <div className="mt-14">
                <div className="flex-1">
                    <span className="font-TTHoves text-body-normal text-dark-5">
                        Plan photoshoots
                    </span>
                    <p className=" font-OTabolas text-sub-heading-bold text-dark-8 mt-2">
                        From Concept to Creation: <br /> Plan Your Shoot with
                        Ease
                    </p>
                </div>
                <div className="flex flex-col md:flex-row gap-6 mt-4">
                    <div className="relative rounded-xl bg-gradient-to-tr from-white to-[#CFC8DB]  via-50% via-white border border-zinc-200 p-8">
                        <p className="font-TTHovesSB text-lg text-zinc-800">
                            Define your vision
                        </p>
                        <p className="font-TTHoves text-zinc-600">
                            Create a detailed project request with your ideas,
                            preferences, and requirements.
                        </p>
                        <div className="absolute -left-2 -top-2">
                            <svg
                                width="35"
                                height="35"
                                viewBox="0 0 35 35"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16.6226 0.604178C17.002 -0.0893661 17.998 -0.0893649 18.3774 0.60418L21.2299 5.81984C23.0652 9.17563 25.8244 11.9348 29.1802 13.7701L34.3958 16.6226C35.0894 17.002 35.0894 17.998 34.3958 18.3774L29.1802 21.2299C25.8244 23.0652 23.0652 25.8244 21.2299 29.1802L18.3774 34.3958C17.998 35.0894 17.002 35.0894 16.6226 34.3958L13.7701 29.1802C11.9348 25.8244 9.17563 23.0652 5.81984 21.2299L0.604178 18.3774C-0.0893661 17.998 -0.0893649 17.002 0.60418 16.6226L5.81984 13.7701C9.17563 11.9348 11.9348 9.17563 13.7701 5.81984L16.6226 0.604178Z"
                                    fill="#F5C059"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="relative rounded-xl border border-zinc-200 bg-gradient-to-tr via-50% via-white from-white to-[#FCECD9] p-8">
                        <p className="font-TTHovesSB text-lg text-zinc-800">
                            Collaborate Seamlessly
                        </p>
                        <p className="font-TTHoves text-zinc-600">
                            Get jobs with clients, finalize details, and sign
                            contracts, all within the platform..
                        </p>
                        <div className="absolute -left-2 -top-2">
                            <svg
                                width="35"
                                height="35"
                                viewBox="0 0 35 35"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16.6226 0.604178C17.002 -0.0893661 17.998 -0.0893649 18.3774 0.60418L21.2299 5.81984C23.0652 9.17563 25.8244 11.9348 29.1802 13.7701L34.3958 16.6226C35.0894 17.002 35.0894 17.998 34.3958 18.3774L29.1802 21.2299C25.8244 23.0652 23.0652 25.8244 21.2299 29.1802L18.3774 34.3958C17.998 35.0894 17.002 35.0894 16.6226 34.3958L13.7701 29.1802C11.9348 25.8244 9.17563 23.0652 5.81984 21.2299L0.604178 18.3774C-0.0893661 17.998 -0.0893649 17.002 0.60418 16.6226L5.81984 13.7701C9.17563 11.9348 11.9348 9.17563 13.7701 5.81984L16.6226 0.604178Z"
                                    fill="#F5C059"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="relative rounded-xl border border-zinc-200 bg-gradient-to-tr via-50% via-white from-white to-[#D0D9D6] p-8">
                        <p className="font-TTHovesSB text-lg text-zinc-800">
                            Bring Your Vision to Life
                        </p>
                        <p className="font-TTHoves text-zinc-600">
                            Enjoy a hassle-free photoshoot, with tools to manage
                            everything from start to finish.
                        </p>
                        <div className="absolute -left-2 -top-2">
                            <svg
                                width="35"
                                height="35"
                                viewBox="0 0 35 35"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16.6226 0.604178C17.002 -0.0893661 17.998 -0.0893649 18.3774 0.60418L21.2299 5.81984C23.0652 9.17563 25.8244 11.9348 29.1802 13.7701L34.3958 16.6226C35.0894 17.002 35.0894 17.998 34.3958 18.3774L29.1802 21.2299C25.8244 23.0652 23.0652 25.8244 21.2299 29.1802L18.3774 34.3958C17.998 35.0894 17.002 35.0894 16.6226 34.3958L13.7701 29.1802C11.9348 25.8244 9.17563 23.0652 5.81984 21.2299L0.604178 18.3774C-0.0893661 17.998 -0.0893649 17.002 0.60418 16.6226L5.81984 13.7701C9.17563 11.9348 11.9348 9.17563 13.7701 5.81984L16.6226 0.604178Z"
                                    fill="#F5C059"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
                <Button
                    onClick={() => navigate('/signup?role=Photographer')}
                    className="flex mt-6 items-center bg-n-dark px-6 text-white border-none">
                    <span className="px-0">Try suura for free</span>
                </Button>
                <p className="text-sm text-zinc-700 mt-2 pl-3">
                    No credit card required
                </p>
            </div>

            {/*<div className="flex flex-col md:flex-row justify-between  gap-4 box-content my-8 md:my-20">*/}
            {/*    <div className="w-full pb-6 md:w-2/3">*/}
            {/*        <Category*/}
            {/*            title="Plan photoshoots"*/}
            {/*            heading={*/}
            {/*                <div>*/}
            {/*                    <p>Say goodbye to</p>*/}
            {/*                    <p>awkward photoshoots</p>*/}
            {/*                </div>*/}
            {/*            }*/}
            {/*            list={[*/}
            {/*                {*/}
            {/*                    header: 'Learn how to pose with confidence',*/}
            {/*                    message:*/}
            {/*                        "With the world's largest pose library always in your pocket",*/}
            {/*                },*/}
            {/*                {*/}
            {/*                    header: 'Find dreamy light with our Sun Tracker',*/}
            {/*                    message:*/}
            {/*                        'Plan your sessions around the magic hours down to the minute',*/}
            {/*                },*/}
            {/*                {*/}
            {/*                    header: 'Unleash your creative potential',*/}
            {/*                    message:*/}
            {/*                        'Curate your fave poses and push your creativity every shoot',*/}
            {/*                },*/}
            {/*            ]}*/}
            {/*            buttonText="Try Suura for free"*/}
            {/*            buttonClick={() => console.log('')}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <div className="flex gap-2">*/}
            {/*        <div className="flex flex-col gap-4 my-4">*/}
            {/*            <div className="p-4 rounded-lg bg-dark-0">*/}
            {/*                <div className="h-[40]">*/}
            {/*                    <I3Square />*/}
            {/*                </div>*/}
            {/*                <div className="whitespace-break text-body-small text-dark-8 font-TTHovesSB">*/}
            {/*                    Best poses library to help create stunning*/}
            {/*                    photos*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="h-96 w-44 md:w-full">*/}
            {/*                <img*/}
            {/*                    className="h-full w-full rounded-lg object-cover"*/}
            {/*                    src="/assets/homepage-image-1.jpg"*/}
            {/*                    alt=""*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="flex flex-col gap-4 mx-2 my-4">*/}
            {/*            <div className="h-96 w-44 md:w-80">*/}
            {/*                <img*/}
            {/*                    className="h-full w-full rounded-lg object-cover"*/}
            {/*                    src="/assets/homepage-image-2.jpg"*/}
            {/*                    alt=""*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div className="p-4 rounded-lg bg-tale-2">*/}
            {/*                <div className="h-[40]">*/}
            {/*                    <SunFog />*/}
            {/*                </div>*/}
            {/*                <div className="whitespace-break text-body-small text-dark-8 font-TTHovesSB">*/}
            {/*                    Get updated with the golden hours*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/* Category Start */}
            <div className="flex w-full flex-col-reverse md:flex-row gap-8 justify-between box-content my-8 md:my-20">
                <div className="w-full md:w-2/4 pb-6">
                    <img
                        src="/assets/image3.png"
                        className="rounded-xl object-cover w-full h-full"
                        alt=""
                    />
                </div>
                <div className="flex-1 pt-0 md:pt-6">
                    <Category
                        title="Personalised Portfolio"
                        heading={
                            <div>
                                <p>Stand out in a sea of</p>
                                <p>other photographers</p>
                            </div>
                        }
                        colspan={5}
                        list={[
                            {
                                header: 'Showcase your work',
                                message:
                                    'Easily create a beautifully designed mini-website',
                            },
                            {
                                header: 'Get booked easier then ever',
                                message:
                                    'Get requests straight into your app with your own booking link',
                            },
                            {
                                header: 'Browse job opportunities',
                                message:
                                    'Find your ideal gig based on location, budget, or shoot type',
                            },
                        ]}
                        buttonText="Create Your Portfolio Now"
                        buttonClick={() =>
                            navigate('/signup?role=Photographer')
                        }
                    />
                </div>
            </div>
            {/* Category End */}

            <div className="flex flex-col md:flex-row justify-between items-center gap-4 box-content">
                <div className="col-span-5">
                    <Category
                        title="Manage your business"
                        heading={
                            <div>
                                <p>Simplify Your Business,</p>
                                <p>Focus on Your Passion</p>
                            </div>
                        }
                        colspan={5}
                        list={[
                            {
                                header: 'Get paid faster with online payments',
                                message:
                                    'Easily accept credit, debit, wire transfer, and more',
                            },
                            {
                                header: 'Easy done-for-you booking system',
                                message:
                                    'Set yourself up in minutes and start booking clients on the go',
                            },
                            {
                                header: 'Protect your biz with contracts',
                                message:
                                    'Show you’re a pro with lawyer-approved templates and e-signatures',
                            },
                        ]}
                        buttonText="Start Earning Now"
                        buttonClick={() =>
                            navigate('/signup?role=Photographer')
                        }
                    />
                </div>
                <div className="relative col-span-5 pt-6">
                    <div className="absolute top-4 -left-32 w-full h-full">
                        <MessageNotification
                            icon={<MoneyTick className="text-n-orange" />}
                            message={
                                <div className="w-full text-base text-dark-3 font-medium font-TTHovesM">
                                    Your invoice of
                                    <span className="text-dark-8 ml-1 inline-block">
                                        $200.00
                                    </span>{' '}
                                    is paid
                                </div>
                            }
                        />
                        <MessageNotification
                            icon={<ReceiptEdit className="text-n-orange" />}
                            message={
                                <div className="flex text-base text-dark-3 font-medium font-TTHovesM">
                                    Signed wedding
                                    <p className="text-dark-8 ml-1 inline-block">
                                        contract
                                    </p>
                                </div>
                            }
                        />
                    </div>

                    <div className="hidden absolute md:flex flex-col justify-end items-center left-1/2 -translate-x-1/2 bottom-0 ">
                        <div className="bg-white rounded-xl mb-4 w-[320px] h-[164px] p-2">
                            <div className="grid grid-cols-2">
                                <div className="flex flex-col justify-around h-full p-6 bg-creame-1 rounded-xl">
                                    <div className="text-body-extrasmall font-TTHovesSB font-semibold text-netural-4">
                                        Customers
                                    </div>
                                    <div className="text-sub-heading-bold font-TTHovesM font-medium">
                                        52
                                    </div>
                                    <div className="flex items-center w-fit p-2 rounded-md bg-green-100">
                                        <Arrow color="green" />
                                        <div className="text-sm text-green-600 font-TTHovesM mx-1">
                                            37.8%
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-around h-full p-6 rounded-xl">
                                    <div className="text-body-extrasmall font-TTHovesSB font-semibold text-netural-4">
                                        Income
                                    </div>
                                    <div className="text-sub-heading-bold font-TTHovesM font-medium">
                                        256k
                                    </div>
                                    <div className="flex items-center w-fit p-2 rounded-md bg-green-100">
                                        <Arrow color="green" />
                                        <div className="text-sm text-green-600 font-TTHovesM mx-1">
                                            37.8%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-1/2">
                        <img
                            src="/assets/girl.png"
                            className="h-full w-full rounded-xl object-contain object-right"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
