import React from "react"
import { topup1 } from "utils/exportImages"

const DepositTile: React.FC<{ setOpen: (boolean) => void }> = ({ setOpen }) => {
    return (
        <div
            className="border border-[#EAECF0] rounded-xl py-[19px] px-4 bg-white bg-cover bg-no-repeat"
            style={{ backgroundImage: `url(${topup1})` }} // Add background image here
        >
            <h4 className="text-[18px] text-[#14171F] font-TTHovesSB leading-[24px] mb-1">
                {'Top up your wallet'}
            </h4>
            <p className="text-[#6C6B83] text-[14px] font-TTHovesM leading-[16px] mb-7">
                {'Deposit amount to your wallet'}
            </p>
            <button
                className="text-[14px] bg-[#fafafa] h-10 w-[287px] rounded-md leading-[20px] font-TTHovesSB text-[#38374B]"
                onClick={() => setOpen(true)}
            >
                {'Deposit Amount'}
            </button>
        </div>
    )
}

export default DepositTile
