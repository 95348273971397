// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Style_badge__ZIRGf {


    border-radius: 5px;


    padding-left: 0.5rem;


    padding-right: 0.5rem;


    padding-top: 0.375rem;


    padding-bottom: 0.375rem;


    font-family: TT-Hoves-SemiBold;


    font-size: 10px;


    text-transform: uppercase;


    line-height: .75rem;


    letter-spacing: -0.025em
}


.Style_green__NMXmD {


    border-width: 1px;


    --tw-border-opacity: 1;


    border-color: rgb(167 243 208 / var(--tw-border-opacity));


    --tw-bg-opacity: 1;


    background-color: rgb(209 250 229 / var(--tw-bg-opacity));


    --tw-text-opacity: 1;


    color: rgb(16 185 129 / var(--tw-text-opacity))
}

.Style_orange__77\\+JQ {


    border-width: 1px;


    --tw-border-opacity: 1;


    border-color: rgb(254 215 170 / var(--tw-border-opacity));


    --tw-bg-opacity: 1;


    background-color: rgb(255 237 213 / var(--tw-bg-opacity));


    --tw-text-opacity: 1;


    color: rgb(199 152 122 / var(--tw-text-opacity))
}

.Style_badge2__x6dZw {


    display: inline-flex;


    border-radius: 0.375rem;


    padding-left: 18px;


    padding-right: 18px;


    padding-top: 7px;


    padding-bottom: 7px;


    text-align: center;


    font-family: TT-Hoves-Medium;


    font-size: 0.875rem;


    line-height: 1.25rem
}

.Style_violet__sTsit {


    border-width: 1px;


    --tw-border-opacity: 1;


    border-color: rgb(221 214 254 / var(--tw-border-opacity));


    --tw-bg-opacity: 1;


    background-color: rgb(245 243 255 / var(--tw-bg-opacity));


    --tw-text-opacity: 1;


    color: rgb(109 40 217 / var(--tw-text-opacity))
}

.Style_pink__i0dLP {


    border-width: 1px;


    --tw-border-opacity: 1;


    border-color: rgb(251 207 232 / var(--tw-border-opacity));


    --tw-bg-opacity: 1;


    background-color: rgb(253 242 248 / var(--tw-bg-opacity));


    --tw-text-opacity: 1;


    color: rgb(190 24 93 / var(--tw-text-opacity))
}

.Style_progress__VIwLm {


    border-width: 1px;


    --tw-border-opacity: 1;


    border-color: rgb(167 243 208 / var(--tw-border-opacity));


    --tw-bg-opacity: 1;


    background-color: rgb(236 253 245 / var(--tw-bg-opacity));


    --tw-text-opacity: 1;


    color: rgb(4 120 87 / var(--tw-text-opacity))
}

.Style_grey__YVl01 {


    border-width: 1px;


    --tw-border-opacity: 1;


    border-color: rgb(228 228 231 / var(--tw-border-opacity));


    --tw-bg-opacity: 1;


    background-color: rgb(255 255 255 / var(--tw-bg-opacity));


    --tw-text-opacity: 1;


    color: rgb(82 82 91 / var(--tw-text-opacity));


    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);


    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);


    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
`, "",{"version":3,"sources":["webpack://./src/components/landing/photographer/Style.module.css"],"names":[],"mappings":"AACI;;;IAAA,kBAA6F;;;IAA7F,oBAA6F;;;IAA7F,qBAA6F;;;IAA7F,qBAA6F;;;IAA7F,wBAA6F;;;IAA7F,8BAA6F;;;IAA7F,eAA6F;;;IAA7F,yBAA6F;;;IAA7F,mBAA6F;;;IAA7F;AAA6F;;;AAK7F;;;IAAA,iBAA+D;;;IAA/D,sBAA+D;;;IAA/D,yDAA+D;;;IAA/D,kBAA+D;;;IAA/D,yDAA+D;;;IAA/D,oBAA+D;;;IAA/D;AAA+D;;AAI/D;;;IAAA,iBAA2D;;;IAA3D,sBAA2D;;;IAA3D,yDAA2D;;;IAA3D,kBAA2D;;;IAA3D,yDAA2D;;;IAA3D,oBAA2D;;;IAA3D;AAA2D;;AAI3D;;;IAAA,oBAAkF;;;IAAlF,uBAAkF;;;IAAlF,kBAAkF;;;IAAlF,mBAAkF;;;IAAlF,gBAAkF;;;IAAlF,mBAAkF;;;IAAlF,kBAAkF;;;IAAlF,4BAAkF;;;IAAlF,mBAAkF;;;IAAlF;AAAkF;;AAIlF;;;IAAA,iBAA2D;;;IAA3D,sBAA2D;;;IAA3D,yDAA2D;;;IAA3D,kBAA2D;;;IAA3D,yDAA2D;;;IAA3D,oBAA2D;;;IAA3D;AAA2D;;AAI3D;;;IAAA,iBAAqD;;;IAArD,sBAAqD;;;IAArD,yDAAqD;;;IAArD,kBAAqD;;;IAArD,yDAAqD;;;IAArD,oBAAqD;;;IAArD;AAAqD;;AAIrD;;;IAAA,iBAA8D;;;IAA9D,sBAA8D;;;IAA9D,yDAA8D;;;IAA9D,kBAA8D;;;IAA9D,yDAA8D;;;IAA9D,oBAA8D;;;IAA9D;AAA8D;;AAI9D;;;IAAA,iBAA6D;;;IAA7D,sBAA6D;;;IAA7D,yDAA6D;;;IAA7D,kBAA6D;;;IAA7D,yDAA6D;;;IAA7D,oBAA6D;;;IAA7D,6CAA6D;;;IAA7D,0CAA6D;;;IAA7D,uDAA6D;;;IAA7D;AAA6D","sourcesContent":[".badge {\n    @apply px-2 py-1.5 text-[10px] rounded-[5px] font-TTHovesSB uppercase leading-3 tracking-tight\n}\n\n\n.green {\n    @apply text-emerald-500 bg-emerald-100 border border-emerald-200\n}\n\n.orange {\n    @apply text-[#C7987A] bg-orange-100 border border-orange-200\n}\n\n.badge2 {\n    @apply rounded-md  text-center text-sm font-TTHovesM inline-flex px-[18px] py-[7px]\n}\n\n.violet {\n    @apply bg-violet-50 text-violet-700 border border-violet-200\n}\n\n.pink {\n    @apply bg-pink-50 border border-pink-200 text-pink-700\n}\n\n.progress {\n    @apply text-emerald-700 bg-emerald-50 border border-emerald-200\n}\n\n.grey {\n    @apply border border-zinc-200 shadow-sm bg-white text-zinc-600\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": `Style_badge__ZIRGf`,
	"green": `Style_green__NMXmD`,
	"orange": `Style_orange__77+JQ`,
	"badge2": `Style_badge2__x6dZw`,
	"violet": `Style_violet__sTsit`,
	"pink": `Style_pink__i0dLP`,
	"progress": `Style_progress__VIwLm`,
	"grey": `Style_grey__YVl01`
};
export default ___CSS_LOADER_EXPORT___;
