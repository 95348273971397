import styles from 'pages/auth/Auth.module.css'
import { Button } from 'components/form/Button/Button'
import { pages } from 'pages/auth/CreateProfile'
import classNames from 'classnames'
import { useSession } from 'hooks/useSession'
import UserService from 'services/user.service'
import { setUser } from '../../redux/reducers/authReducer'
import { useThunkDispatch } from 'hooks/useThunkDisptach'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { userSelector } from '../../redux/selectors/user'

const service = new UserService()
export const SessionOffer = ({ currentPage, stepIncrement, pageChange }) => {
    const [submitting, setSubmitting] = useState(false)

    const { sessions, setSessions } = useSession()
    const dispatch = useThunkDispatch()
    const user = useSelector(userSelector)

    const photographer = user?.userType === 'Photographer'

    const handleSubmit = e => {
        e.preventDefault()

        setSubmitting(true)
        const sessionIds = sessions
            .filter(s => s.selected)
            .map(item => item._id)

        service
            .sessionSignup({ sessionIds: sessionIds.join(',') })
            .then(resp => {
                dispatch(setUser(resp.data.data))
                pageChange(pages.NEXT)
                stepIncrement(3)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    return (
        <div
            id="page-3"
            style={{
                display: currentPage === 2 ? 'block' : 'none',
            }}>
            <div className="grid">
                <p className="font-normal text-body-caption-bold text-dark-8 font-OTabolas">
                    {photographer
                        ? 'What kinds of sessions do you offer?'
                        : 'What photoshoot would you like to book?'}
                </p>
                <p className={`mt-1 ${styles.messageText}`}>
                    {photographer
                        ? "You'll be able to display a portfolio that represents each type of session on your profile."
                        : 'In the next step, you’ll be able to choose pictures that best resemble the photography style you’re looking for, so we can find your perfect match'}
                </p>
                <div className="flex flex-wrap gap-2 py-10">
                    {sessions.map(item => {
                        return (
                            <SessionItem
                                key={item._id}
                                sessions={sessions}
                                setSessions={setSessions}
                                {...item}
                            />
                        )
                    })}
                </div>
                <div className="py-6">
                    <Button
                        className="bg-brown-10 border-brown-10 text-white px-7 h-12 md:w-1/2 disabled:cursor-not-allowed disabled:bg-opacity-70"
                        disabled={submitting}
                        onClick={handleSubmit}>
                        {submitting
                            ? 'Please wait..'
                            : user?.userType === 'Client'
                              ? 'Continue'
                              : 'Create Galleries'}
                    </Button>
                </div>
            </div>
        </div>
    )
}

const SessionItem = ({ _id, name, selected, sessions, setSessions }) => {
    const handleClick = () => {
        const copy = [...sessions]
        const updated = copy.map(item => {
            if (item._id === _id) {
                return {
                    ...item,
                    selected: !item.selected,
                }
            }
            return item
        })
        setSessions(updated)
    }

    return (
        <button
            type="button"
            onClick={handleClick}
            className={classNames(
                'flex-auto md:flex-none px-10 whitespace-nowrap text-center py-2.5  rounded-xl w-fit h-fit',
                {
                    'bg-brown-10 text-white border border-white': selected,
                    'bg-white text-zinc-800 border border-dark-1': !selected,
                },
            )}>
            {name}
        </button>
    )
}
