const PhotoCardForQuoteDetails = ({ photo }) => {
    return (
        <div className="w-[140px] h-[140px] rounded-lg overflow-hidden relative group">
            <img
                src={`${process.env.REACT_APP_SERVER_DOMAIN}/${photo}`}
                alt=""
                className="w-full h-full object-cover"
            />
        </div>
    )
}

export default PhotoCardForQuoteDetails
