import { useSelector } from 'react-redux'

interface BalanceDetails {
    balanceDetails: {
        balance: {
            balance: number
        }
    }
}

interface RootState {
    balanceDetails: {
        balance: {
            balance: number
        }
    }
}

// const service = new UserService()

const UseBalance = () => {
    const reduxState = useSelector(
        (state: RootState) => state,
    ) as BalanceDetails

    return { userBalance: reduxState.balanceDetails.balance.balance }
}

export default UseBalance
