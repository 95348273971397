import { useCallback, useEffect, useState } from 'react'
import UserService from 'services/user.service'

const service = new UserService()

export const usePhotographers = () => {
    const [photographers, setPhotographers] = useState<any>([])
    const [loading, setLoading] = useState(false)

    const fetchData = useCallback(() => {
        setLoading(true)
        service
            .getAllPhotographers({ page: 1, limit: 100 })
            .then(resp => {
                setPhotographers(resp.data.data)
            })
            .catch(error => {
                console.log({ error })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        fetchData()
    }, [])

    return {
        loading,
        setPhotographers,
        photographers,
    }
}
