import React from 'react'
import { Monitor } from 'iconsax-react'
import classNames from 'classnames'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { styles, TABS } from 'pages/dashboard/Settings'
import { Input } from 'components/form/Input/Input'
import { Button } from 'components/form/Button/Button'
import { userSelector } from '../../../redux/selectors/user'
import { AccountValidation } from 'utils/validations'
import UserService from 'services/user.service'
import { useNotifications } from 'hooks/useNotification'

const service = new UserService()

export const AccountSetting = ({ tab, user }) => {
    const { successMessage, errorMessage } = useNotifications()

    const {
        errors,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        resetForm,
        handleChange,
        handleBlur,
        values,
        touched,
    } = useFormik({
        initialValues: {
            registeredEmail: user.email,
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: AccountValidation,
        onSubmit: values => {
            service
                .updateAccount(values)
                .then(() => {
                    successMessage('Password updated successfully')
                })
                .catch(error => {
                    errorMessage(error?.response?.data?.message)
                })
                .finally(() => {
                    resetForm()
                    setSubmitting(false)
                })
        },
    })

    return (
        <div
            className={classNames('flex flex-col md:flex-row', {
                hidden: !tab,
            })}>
            <div className="flex-1 md:px-8">
                {/* Buttons */}
                <div className="flex-col md:flex-row justify-between items-start gap-6 inline-flex w-full border-b border-zinc-200 pb-6">
                    <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                        <p className="text-zinc-800 text-[22px] font-TTHovesM">
                            Account Settings
                        </p>
                        <p className="text-zinc-500">
                            Update your password or manage your Instagram
                            account
                        </p>
                    </div>
                    <div className="justify-start items-center gap-3 flex">
                        <button type="button" className={styles.default}>
                            <span className="text-slate-700 text-sm font-TTHovesM leading-tight">
                                Cancel
                            </span>
                        </button>
                        <button
                            type="submit"
                            form="form3"
                            disabled={isSubmitting}
                            className={styles.primary}>
                            <span className="text-white text-sm font-TTHovesM leading-tight">
                                {isSubmitting ? 'Saving...' : 'Save changes'}
                            </span>
                        </button>
                    </div>
                </div>
                {/* Buttons End */}
                {/* Form */}
                <form
                    onSubmit={handleSubmit}
                    className="flex flex-col gap-7 mt-8"
                    id="form3">
                    <Input
                        type="email"
                        placeholder="e.g johndoe@email.com"
                        label="Registered Email"
                        disabled
                        name="registeredEmail"
                        error={
                            touched.registeredEmail && errors.registeredEmail
                        }
                        onChange={handleChange('registeredEmail')}
                        onBlur={handleBlur('registeredEmail')}
                        value={values.registeredEmail}
                    />
                    <Input
                        type="password"
                        placeholder="Current Password"
                        name="currentPassword"
                        onChange={handleChange('currentPassword')}
                        onBlur={handleBlur('currentPassword')}
                        value={values.currentPassword}
                        error={
                            touched.currentPassword && errors.currentPassword
                        }
                        label="Curent Password"
                    />
                    <Input
                        type="password"
                        placeholder="New Password"
                        name="newPassword"
                        onChange={handleChange('newPassword')}
                        onBlur={handleBlur('newPassword')}
                        value={values.newPassword}
                        error={touched.newPassword && errors.newPassword}
                        label="Curent Password"
                    />
                    <Input
                        type="password"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        onChange={handleChange('confirmPassword')}
                        onBlur={handleBlur('confirmPassword')}
                        value={values.confirmPassword}
                        error={
                            touched.confirmPassword && errors.confirmPassword
                        }
                        label="Confirm New Password"
                    />
                </form>
                {/* Form End */}
                {/*<div className="flex-col justify-start items-start inline-flex mt-4">*/}
                {/*    <div className="text-zinc-800 text-[22px] font-TTHovesM">*/}
                {/*        Connect to Instagram*/}
                {/*    </div>*/}
                {/*    <div className="text-zinc-500 font-TTHovesM">*/}
                {/*        Link your Instagram account to easily upload photos to*/}
                {/*        your profile.*/}
                {/*    </div>*/}
                {/*    <Button*/}
                {/*        container="mt-3"*/}
                {/*        className="bg-brown-10 text-white w-72">*/}
                {/*        Connect Instagram*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </div>
            {/* Login Devices */}
            <div className="w-full md:w-[400px] mt-6 md:mt-0">
                <div className="flex-col justify-start flex gap-1.5">
                    <p className="text-zinc-800 text-lg font-TTHovesM">
                        Where you’re logged in
                    </p>
                    <p className="text-zinc-600 text-sm">
                        We’ll alert you via danyalsaif@mail.com if there is any
                        unusual activity on your account.
                    </p>
                </div>
                <div className="flex-col justify-start items-start gap-5 inline-flex mt-4 w-full">
                    <div className="items-start gap-4 flex">
                        <Monitor className="mt-2 text-zinc-500" />
                        <div className="flex-col justify-start items-start gap-1 flex">
                            <div className="justify-start items-center gap-5 flex">
                                <p className="text-zinc-700 font-TTHovesM">
                                    Macbook Pro 15-inch
                                </p>
                                <div className="px-1.5 py-1 bg-white rounded-md border border-zinc-300 items-center gap-1.5 flex">
                                    <div className="w-1.5 h-1.5 bg-green-500 rounded-full" />
                                    <p className="text-center text-zinc-700 text-[13px] font-TTHovesM">
                                        Active now
                                    </p>
                                </div>
                            </div>
                            <p className="text-slate-600 text-sm">
                                Melbourne, Australia • 22 Jan at 10:40am
                            </p>
                        </div>
                    </div>
                    <div className="h-px bg-zinc-200 w-full" />
                    <div className="items-start gap-4 flex">
                        <Monitor className="mt-1 text-zinc-500" />
                        <div className="flex-col justify-start items-start gap-1 flex">
                            <div className="justify-start items-center gap-5 flex">
                                <p className="text-zinc-700 font-TTHovesM">
                                    Macbook Pro 15-inch
                                </p>
                                {/*<div className="px-1.5 py-1 bg-white rounded-md shadow border border-zinc-300 items-center gap-1.5 flex">*/}
                                {/*    <div className="w-1.5 h-1.5 bg-green-500 rounded-full" />*/}
                                {/*    <p className="text-center text-zinc-700 text-[13px] font-TTHovesM">*/}
                                {/*        Active now*/}
                                {/*    </p>*/}
                                {/*</div>*/}
                            </div>
                            <p className="text-zinc-600 text-sm">
                                Melbourne, Australia • 22 Jan at 10:40am
                            </p>
                        </div>
                    </div>
                    <div className="h-px bg-zinc-200 w-full" />
                </div>
            </div>
            {/* Login Devices */}
        </div>
    )
}
