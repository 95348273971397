import { Navigate } from 'react-router-dom'
import { HomeLayout } from 'components/landing/HomeLayout'
import { DashboardLayout } from 'components/dashboard/DashboardLayout'
import {
    SignupPage,
    LoginPage,
    CreateProfile,
    Home,
    FindPhotographer,
    PhotographerProfile,
    Booking,
    Availability,
    Chat,
    PageNotFound,
    AddPost,
    AddQuote,
    Quotes,
    Client,
    ClientGallery,
    Request,
    Service,
    PhotoLibrary,
    CollabBoard,
    BrowseJobs,
    Dashboard,
    Settings,
} from 'pages'
import { NewPhotoshoot } from 'pages/dashboard/business/NewPhotoshoot'
import { CreateInvoice } from 'pages/dashboard/business/CreateInvoice'
import { Profile } from 'pages/dashboard/Profile'
import { PublicPortfolio } from 'pages/dashboard/galleries/PublicPortfolio'
import { EmailTemplate } from 'pages/dashboard/business/EmailTemplate'
import { VerifyEmail } from 'pages/auth/VerifyEmail'
import { QuoteDetail } from 'pages/dashboard/QuoteDetail'
import { CreateContract } from 'pages/dashboard/business/CreateContract'
import { SpecificGallery } from 'pages/dashboard/galleries/SpecificGallery'
import { GallerySettings } from 'pages/dashboard/galleries/GallerySettings'
import { Deliverables } from 'pages/dashboard/business/Deliverables'
import { RolePage } from 'pages/auth/RolePage'
import { ViewInvoice } from 'pages/dashboard/ViewInvoice'
import { ClientProposal } from 'pages/dashboard/business/ClientProposal'
import { ClientSignature } from 'pages/dashboard/business/ClientSignature'
import InvitePhotographer from 'pages/dashboard/InvitePhotgrapher'
import AddAvailability from 'pages/dashboard/business/AddAvailability'
import { ClientInvoices } from 'pages/dashboard/business/ClientInvoices'
import Wallet from 'pages/dashboard/wallet'
import { PreviewGallery } from 'pages/dashboard/galleries/GalleryPreview'
import { ClientGalleriesList } from 'pages/dashboard/business/ClientGalleriesList'
import { ClientSpecificGallery } from 'pages/dashboard/galleries/ClientSpecificGallery'
import { ClientSignup } from 'pages/auth/ClientSignup'

export const routes = (isLoggedIn, user) => [
    {
        path: '/dashboard/preview/:id',
        element: <PreviewGallery />,
    },
    {
        path: '/',
        element: <HomeLayout />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/photographers',
                element: <FindPhotographer />,
            },

            {
                path: '*',
                element: <PageNotFound />,
            },
        ],
    },

    {
        path: '/',
        element: isLoggedIn ? <DashboardLayout /> : <HomeLayout />,
        children: [
            {
                path: '/photographer/:id',
                element: <PhotographerProfile />,
            },
        ],
    },

    {
        path: '/role',
        element: !isLoggedIn ? (
            <RolePage />
        ) : (
            <Navigate to="/dashboard" replace />
        ),
    },
    {
        path: '/signup',
        element: !isLoggedIn ? (
            <SignupPage />
        ) : (
            <Navigate to="/create/profile" replace />
        ),
    },
    {
        path: '/login',
        element: !isLoggedIn ? (
            <LoginPage />
        ) : (
            <Navigate to="/dashboard" replace />
        ),
    },
    {
        path: '/verify',
        element: <VerifyEmail />,
    },
    {
        path: '/create/profile',
        element: isLoggedIn ? (
            <CreateProfile />
        ) : (
            <Navigate to="/login" replace />
        ),
    },
    {
        path: '/client-signup',
        element: <ClientSignup />,
    },
    {
        path: '/dashboard',
        element: isLoggedIn ? <DashboardLayout /> : <HomeLayout />,
        children: [
            {
                path: '/dashboard/add-quote',
                element: <AddQuote />,
            },
        ],
    },

    {
        path: '/dashboard',
        element:
            isLoggedIn &&
            user?.profileCompletionSteps?.create_profile &&
            user?.userType === 'Client' ? (
                <DashboardLayout />
            ) : isLoggedIn &&
              user?.profileCompletionSteps?.create_profile &&
              user?.profileCompletionSteps?.session ? (
                <DashboardLayout />
            ) : isLoggedIn &&
              (!user?.profileCompletionSteps?.create_profile ||
                  !user?.profileCompletionSteps?.session) ? (
                <Navigate to="/create/profile" replace />
            ) : (
                <Navigate to="/login" replace />
            ),
        children: [
            {
                path: '/dashboard',
                element: <Dashboard />,
            },
            {
                path: '/dashboard/:username',
                element: <Profile />,
            },
            {
                path: '/dashboard/chat/:id?',
                element: <Chat />,
            },
            {
                path: '/dashboard/photographers',
                element: <FindPhotographer />,
            },
            {
                path: '/dashboard/quotes',
                element: <Quotes />,
            },
            {
                path: '/dashboard/quote/invite-photographers/:quoteId',
                element: <InvitePhotographer />,
            },
            {
                path: '/dashboard/quote/:id',
                element: <QuoteDetail />,
            },
            // {
            //     path: '/dashboard/add-quote',
            //     element: <AddQuote />,
            // },

            {
                path: '/dashboard/board',
                element:
                    user?.userType === 'Photographer' ? (
                        <CollabBoard />
                    ) : (
                        <Navigate to="/dashboard" />
                    ),
            },
            {
                path: '/dashboard/add-post',
                element: <AddPost />,
            },
            {
                path: '/dashboard/browse/jobs',
                element: <BrowseJobs />,
            },

            {
                path: '/dashboard/settings',
                element: <Settings />,
            },
            {
                path: '/dashboard/photoshoot/requests',
                element: <Request />,
            },
            {
                path: '/dashboard/photoshoot/bookings',
                element: <Booking />,
            },
            {
                path: '/dashboard/photoshoot/new',
                element: <NewPhotoshoot />,
            },
            {
                path: '/dashboard/photoshoot/invoice',
                element:
                    user?.userType === 'Photographer' ? (
                        <CreateInvoice />
                    ) : (
                        <Navigate to="/dashboard" />
                    ),
            },
            {
                path: '/dashboard/clients',
                element:
                    user?.userType === 'Photographer' ? (
                        <Client />
                    ) : (
                        <Navigate to="/dashboard" />
                    ),
            },
            {
                path: '/dashboard/my-photographers',
                element: <Client />,
            },
            {
                path: '/dashboard/services',
                element: <Service />,
            },
            {
                path: '/dashboard/client-gallery/:id',
                element: <ClientSpecificGallery />,
            },
            {
                path: '/dashboard/availability',
                element: <Availability />,
            },
            {
                path: '/dashboard/add-availability',
                element: <AddAvailability />,
            },
            {
                path: '/dashboard/edit-availability',
                element: <AddAvailability />,
            },
            {
                path: '/dashboard/galleries/client',
                element:
                    user?.userType === 'Photographer' ? (
                        <ClientGallery />
                    ) : (
                        <Navigate to={'/dashboard'} />
                    ),
            },
            {
                path: '/dashboard/client/gallery',
                element: <ClientGalleriesList />,
            },
            {
                path: '/dashboard/galleries/library',
                element: <PhotoLibrary />,
            },
            {
                path: '/dashboard/galleries/portfolio',
                element:
                    user?.userType === 'Photographer' ? (
                        <PublicPortfolio />
                    ) : (
                        <Navigate to={'/dashboard'} />
                    ),
            },
            {
                path: '/dashboard/gallery/settings/:id',
                element:
                    user?.userType === 'Photographer' ? (
                        <GallerySettings />
                    ) : (
                        <Navigate to="/dashboard" />
                    ),
            },
            {
                path: '/dashboard/services/template',
                element:
                    user?.userType === 'Photographer' ? (
                        <EmailTemplate />
                    ) : (
                        <Navigate to="/dashboard" />
                    ),
            },
            {
                path: '/dashboard/business/contract/:id',
                element: <CreateContract />,
            },
            // {
            //     path: '/dashboard/business/proposal/:id',
            //     element: <ReviseProposal />,
            // },
            {
                path: '/dashboard/view/invoice/:id',
                element: <ViewInvoice />,
            },
            {
                path: '/dashboard/business/deliverables/:id',
                element: <Deliverables />,
            },
            {
                path: '/dashboard/business/proposal/:id',
                element: <ClientProposal />,
            },
            {
                path: '/dashboard/business/signature/:id',
                element: <ClientSignature />,
            },
            {
                path: '/dashboard/business/invoices',
                element: <ClientInvoices />,
            },
            {
                path: '/dashboard/wallet',
                element: <Wallet />,
            },
            {
                path: '/dashboard/gallery/:id',
                element:
                    user?.userType === 'Photographer' ? (
                        <SpecificGallery />
                    ) : (
                        <Navigate to="/dashboard" />
                    ),
            },

            {
                path: '*',
                element: <PageNotFound />,
            },
        ],
    },
]
