import React from 'react'
import { CloseIcon } from '../../css/icons/icons'

const CustomAlert = ({ title, message, visible, setVisible }) => {
    const hideAlert = () => {
        setVisible(false)
    }

    return (
        <div
            className={`fixed bottom-4 right-4 bg-creame-1 w-96 p-5 rounded-xl shadow-lg transition-transform duration-300 ${
                visible ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
            }`}>
            <div className="flex relative flex-col gap-1">
                <p className="font-TTHovesM text-zinc-700">{title}</p>

                <p className="font-TTHovesL text-zinc-700">{message}</p>
                <button
                    onClick={hideAlert}
                    className="absolute z-20 right-0 -top-16 bottom-0 ">
                    <CloseIcon className="text-zinc-700 h-5 w-5" />
                </button>
            </div>
        </div>
    )
}

export default CustomAlert
