import ModalSecondary from 'components/common/ModalSecandory'
import BreadCrums from 'components/common/breadCrums'
import TableMain from 'components/dashboard/wallet/TableMain'
import TopStrip from 'components/dashboard/wallet/TopStrip'
import { useEffect, useState } from 'react'
import DepositModal from 'components/dashboard/wallet/DepositModal'
import PaymentMethod from '../../../components/dashboard/wallet/Payment'
import UserService from 'services/user.service'
import { Modal } from 'components/common/Modal'

const service = new UserService()

const Wallet = () => {
    const [open, setOpen] = useState(false)
    const [openStripe, setOpenStripe] = useState(false)
    const [amount, setAmount] = useState(50)
    const [secretClient, setSecretClient] = useState('')
    const [saveIdsofPayments, setSaveIdsofPayments] = useState<any>(null)
    const [transactions, setTransactions] = useState(null)
    const [loading, setLoading] = useState(false)


    const onSuccess = async e => {
        setTimeout(() => {
            setOpenStripe(false)
            setOpen(false)
        }, 3000)
    }

    const openStripeModal = ({ clientSecret }) => {
        setOpen(false)
        setOpenStripe(true)
        setSecretClient(clientSecret)
    }

    useEffect(() => {
        const preFetch = async () => {
            await service.getPaymentHistory()
        }

        preFetch()
    }, [])

    const preFetch = async () => {
        setLoading(true)
        try {
            const data = await service.getUserWallet()
            setTransactions(data.data.data)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }
    useEffect(() => {
        preFetch()
    }, [])

    return (
        <div className="flex flex-col gap-4">
            <BreadCrums>
                <button className="px-2 py-[6px] text-gray-700 text-sm font-TTHovesSB leading-[20px] border border-primary bg-white rounded-[5px]">
                    {'Wallet'}
                </button>
            </BreadCrums>

            <TopStrip setOpen={setOpen} transactions={transactions} />

            <TableMain transactions={transactions} />

            <Modal
                showModal={open}
                setShowModal={setOpen}
                showBottom={false}
                showDescription={false}
                width="md:min-w-[670px]">
                <DepositModal
                    depositAmount={setAmount}
                    amount={amount}
                    openPaymnetModal={openStripeModal}
                    setSaveIdsofPayments={setSaveIdsofPayments}
                    saveIdsofPayments={saveIdsofPayments}
                />
            </Modal>

            <ModalSecondary open={openStripe} width="w-[100%]">
                <PaymentMethod
                    saveIdsofPayments={saveIdsofPayments}
                    amount={amount}
                    clientSecret={secretClient}
                    onSuccess={onSuccess}
                    onBack={() => {
                        setOpenStripe(false)
                        setOpen(false)
                    }}
                />
            </ModalSecondary>
        </div>
    )
}

export default Wallet
