import Select, { type DropdownIndicatorProps, components } from 'react-select'
import React, { CSSProperties, FC } from 'react'
import { SelectArrowIcon } from '../../css/icons/icons'
import classNames from 'classnames'

const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
    return (
        <components.DropdownIndicator {...props}>
            <SelectArrowIcon className="h-5 w-5" />
        </components.DropdownIndicator>
    )
}

interface IProps {
    label?: string
    value: string
    onChange: (value: string) => void
    onInputChange?: (value) => void
    options: {
        value: string
        label: string
    }[]
    hideSelectedOption?: boolean
    isClient?: boolean
    selectLabel?: boolean
    loading?: boolean
    error?: string
    placeholder: string
}

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}

export const UserSelect: FC<IProps> = ({
    label,
    value,
    options,
    loading,
    hideSelectedOption = false,
    onChange,
    selectLabel = true,
    isClient,
    error,
    placeholder,
    onInputChange,
}) => {
    const a = options.find(item => item.value === value)
    const formatGroupLabel = (data: any) => {
        return (
            <div style={groupStyles}>
                <span>
                    {data?.userType === 'Photographer'
                        ? 'Photographers'
                        : 'Recent Clients'}
                </span>
            </div>
        )
    }

    // Custom filter function
    const customFilter = (option, inputValue) => {
        const { firstName, lastName, email } = option.data
        const fullName = `${firstName} ${lastName}`

        return (
            fullName.toLowerCase().includes(inputValue.toLowerCase()) ||
            email.toLowerCase().includes(inputValue.toLowerCase())
        )
    }

    const customOption = props => {
        const { data, innerRef, innerProps, isFocused, isSelected } = props
        return (
            <div
                ref={innerRef}
                {...innerProps}
                className={classNames('flex  items-center p-3.5', {
                    'bg-[#C7987A] text-white': isSelected || isFocused,
                })}>
                <img
                    src={
                        data.profileImage
                            ? `${process.env.REACT_APP_SERVER_DOMAIN}/${data.profileImage}`
                            : '/assets/images/blank.webp'
                    }
                    alt={data.firstName}
                    className="w-10 h-10 object-cover rounded-full mr-2"
                />
                <div className="flex flex-col ">
                    <span>{data.firstName + ' ' + data?.lastName}</span>
                    <span className="text-sm">{data.email}</span>
                </div>
            </div>
        )
    }

    const CustomMenuList = props => {
        return (
            <components.MenuList {...props}>
                {selectLabel && (
                    <div className="px-4 py-2 font-semibold text-gray-700">
                        {isClient ? 'Recent Clients' : 'Photographers'}
                    </div>
                )}
                {props.children}
            </components.MenuList>
        )
    }

    // Custom component to display the selected value with image
    const CustomSingleValue = props => {
        const { data } = props
        return (
            <components.SingleValue {...props}>
                <div className="flex items-center">
                    <img
                        src={
                            data.profileImage
                                ? `${process.env.REACT_APP_SERVER_DOMAIN}/${data.profileImage}`
                                : '/assets/images/blank.webp'
                        }
                        alt={data.firstName}
                        className="w-6 h-6 rounded-full mr-2"
                    />
                    <span>{data.firstName}</span>
                </div>
            </components.SingleValue>
        )
    }

    return (
        <div className="relative">
            {label && (
                <label
                    className={classNames(
                        'absolute -top-3 left-4 z-10 bg-creame-1',
                        {
                            'text-red-200': error,
                            'text-gray': !error,
                        },
                    )}>
                    {label}
                </label>
            )}
            <Select
                onChange={(data: any) => onChange(data.value)}
                defaultValue={a}
                onInputChange={(value, e) =>
                    onInputChange && onInputChange(value)
                }
                options={options}
                hideSelectedOptions={hideSelectedOption}
                isLoading={loading}
                className={classNames('react-select', {
                    'react-select--has-error': error,
                })}
                filterOption={customFilter}
                formatGroupLabel={formatGroupLabel}
                placeholder={placeholder}
                components={{
                    DropdownIndicator,
                    Option: customOption,
                    MenuList: CustomMenuList,
                    SingleValue: CustomSingleValue,
                }}
                classNamePrefix="react-select"
            />
            {error ? (
                <p className="text-red mt-0.5 ml-1 font-TTHoves text-sm">
                    {error}
                </p>
            ) : null}
        </div>
    )
}
