import React, { useState } from 'react'
import { badge, topup2 } from 'utils/exportImages'
import SelectAmount from './SelectAmount'
import UserService from 'services/user.service'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'

const service = new UserService()

const DepositModal: React.FC<{
    openPaymnetModal: (e) => void
    depositAmount: (amount) => void
    amount: number,
    saveIdsofPayments?: any,
    setSaveIdsofPayments: (obj) => void
}> = ({ depositAmount, amount, openPaymnetModal, setSaveIdsofPayments, saveIdsofPayments }) => {
    const user = useSelector(userSelector)
    const [loading, setLoading] = useState(false)

    const createCustomer = async () => {
        setLoading(true)
        const payload = {
            firstName: user?.firstName,
            lastName: user?.lastName,
            phone: user?.phone,
            email: user?.email,
            userId: user?._id,
            userName: user?.userName,
            description: user?.description,
        }
        const res = await service.createCustomer(payload)
        const res2 = await service.createPaymentIntent({
            customerId: res.data.data.customerId,
            price: amount,
            currency: 'eur',
            paymentFor: 'wallet',
            email: user?.email,
            userId: user?._id,
        })
        setSaveIdsofPayments({
            ...saveIdsofPayments,
            ...res.data.data,
            ...res2.data.data,
        })
        setLoading(false)
        openPaymnetModal(res2.data?.data)
    }

    return (
        <div
            className="py-6 pt-14 px-4 bg-[#f5f5f5] rounded-xl bg-cover bg-no-repeat"
            style={{ backgroundImage: `url(${topup2})` }}>
            <div className="mb-6">
                <h4 className=" text-xl leading-[24px] font-TTHovesSB text-[#404553]">
                    {'Buy Suura Credits'}
                </h4>
                <p className="leading-[24px] font-TTHovesL text-[#404553]">
                    {'With credits you can apply to your ideal customers.'}
                </p>
            </div>

            <div className="bg-white p-4 rounded-xl">
                <div className="flex gap-4">
                    <img
                        className="w-[42px] h-[42px]"
                        src={badge}
                        alt="badge"
                    />
                    <div>
                        <h4 className="text-[#404553] text-[18px] leading-[24px] font-TTHovesSB">
                            {'Select the amount of credits'}
                        </h4>
                        <p className="text-[#515157] text-[14px] leading-[24px] font-TTHovesL">
                            {'the minimum spend is 30 euro'}
                        </p>
                    </div>
                </div>

                <SelectAmount depositAmount={depositAmount} amount={amount} />

                <div className="bg-[#F3EFF6] p-4 flex justify-end mt-4">
                    <button
                        className="h-[32px] bg-[#69884F] px-[46px] rounded text-white"
                        type="button"
                        onClick={() => !loading && createCustomer()}>
                        {loading ? 'Processing...' : 'Deposit amount'}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DepositModal
