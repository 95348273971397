import { Eye } from 'iconsax-react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const InvoicesTable = () => {
    const { project } = useSelector((state: any) => state.project)
    const navigate = useNavigate()

    console.log(project)

    return (
        <div className="min-w-full">
            <div className="hidden md:flex items-center text-zinc-500 font-TTHovesM p-4 mt-1">
                <p className="w-1/3" />

                <p className="flex-1 ">Issued Date</p>
                <p className="flex-1">Status</p>
                <p className="flex-1">Total</p>
                <p className="flex-1" />
            </div>

            <div className="flex flex-col gap-4 mt-1 min-w-full">
                {/* Row 1 */}
                {project?.invoice?.length > 0 ? (
                    project?.invoice?.map(item => (
                        <>
                            <div
                                key={item._id}
                                className="hidden md:flex items-center border border-zinc-200 rounded-lg p-4">
                                <div className="w-1/3">
                                    <p className="text-slate-800 font-TTHovesM leading-[21px]">
                                        {item?.invoiceNumber}
                                    </p>
                                </div>
                                <div className="flex-1">
                                    <span className="text-slate-800 font-TTHovesM">
                                        {new Date(
                                            item?.issuedOn,
                                        ).toDateString()}
                                    </span>
                                </div>
                                <div className="flex-1">
                                    {item?.paymentStatus === 'Confirm Payment'
                                        ? 'Paid'
                                        : 'Not Paid'}
                                    {/*<Badge*/}
                                    {/*    text="PAID"*/}
                                    {/*    type={StatusColorMap['Available']}*/}
                                    {/*/>*/}
                                </div>
                                <div className="flex-1">
                                    <p className="text-center text-slate-700 text-sm bg-zinc-50 px-6 py-2 rounded-md border border-zinc-200 inline-flex">
                                        {project?.currencySymbol +
                                            item.customPackage.reduce(
                                                (a, b) => a + b.rate,
                                                0,
                                            )}
                                    </p>
                                </div>

                                <div className="flex-1">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/view/invoice/${project._id}?invoice=${item._id}`,
                                            )
                                        }
                                        className="inline-flex items-center gap-1 text-tale-10 font-TTHovesM text-sm  p-2 bg-white rounded-lg  border border-zinc-300">
                                        <Eye className="text-tale-10 h-5 w-5" />{' '}
                                        View
                                    </button>
                                </div>
                            </div>
                            <div className="block md:hidden">
                                <div
                                    key={item._id}
                                    className="flex justify-between  items-start">
                                    <div className="flex flex-col gap-2 items-start">
                                        <p>{item?.invoiceNumber}</p>
                                        <span className="text-slate-800 font-TTHovesM">
                                            {new Date(
                                                item?.issuedOn,
                                            ).toDateString()}
                                        </span>
                                        <p className="text-center text-slate-700 text-sm bg-zinc-50 px-4 py-2 rounded-md border border-zinc-200">
                                            {project?.currencySymbol +
                                                item.customPackage.reduce(
                                                    (a, b) => a + b.rate,
                                                    0,
                                                )}
                                        </p>
                                    </div>

                                    <div className="flex flex-col gap-2">
                                        <button
                                            type="button"
                                            onClick={() =>
                                                navigate(
                                                    `/dashboard/view/invoice/${project._id}?invoice=${item._id}`,
                                                )
                                            }
                                            className="inline-flex items-center gap-1 text-tale-10 font-TTHovesM text-sm  p-2 bg-white rounded-lg  border border-zinc-300">
                                            <Eye className="text-tale-10 h-5 w-5" />{' '}
                                            View
                                        </button>
                                        <div className="flex-1">
                                            {item?.paymentStatus ===
                                            'Confirm Payment'
                                                ? 'Paid'
                                                : 'Not Paid'}
                                            {/*<Badge*/}
                                            {/*    text="PAID"*/}
                                            {/*    type={StatusColorMap['Available']}*/}
                                            {/*/>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))
                ) : (
                    <p className="text-center font-TTHovesM ">
                        No Invoice Created
                    </p>
                )}
            </div>
        </div>
    )
}
