import React, { Fragment, useEffect, useState } from 'react'
import {
    ArrowRight2,
    Global,
    Location,
    ReceiptItem,
    RefreshCircle,
    TickCircle,
} from 'iconsax-react'
import { ProfileGrid } from 'components/landing/photographer/ProfileGrid'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import UserService from 'services/user.service'
import Accordion from 'components/common/Accordion'
import { Menu, Transition } from '@headlessui/react'

const service = new UserService()

export const Profile = () => {
    const [currentSession, setCurrentSession] = useState('')
    const [more, setMore] = useState(3)
    const [education, setEducation] = useState<any>({})
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState<any>(null)

    const { username } = useParams()

    useEffect(() => {
        setLoading(true)
        service
            .getSingleUser(username ?? '')
            .then(res => {
                setUser(res.data.data)

                service
                    .getSettings(res.data.data?._id)
                    .then(resp => {
                        setEducation(resp.data.data)
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [username])

    const navigate = useNavigate()
    const firstThreeItems = user?.portfolio?.slice(0, more)
    const remainingCount = user?.portfolio?.length - 3

    console.log(education)

    return (
        <div className="w-full">
            {loading ? (
                <div className="h-screen w-full flex flex-col items-center justify-center ">
                    <RefreshCircle className="h-14 w-14 animate-spin text-tale-10" />
                </div>
            ) : (
                <div className="flex-1 ">
                    <div className="relative">
                        {user?.coverImage ? (
                            <div className="block h-96 w-full">
                                <img
                                    alt="couple"
                                    src={
                                        user?.coverImage
                                            ? `${process.env.REACT_APP_SERVER_DOMAIN}/${user?.coverImage}`
                                            : '/assets/placeholder.png'
                                    }
                                    className="w-full h-full rounded-lg object-cover"
                                />
                            </div>
                        ) : (
                            <div className="flex items-center justify-center w-full">
                                <label
                                    onClick={() =>
                                        navigate(
                                            '/dashboard/settings?tab=profile',
                                        )
                                    }
                                    className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-zinc-50 border-zinc-200 hover:bg-gray-100">
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg
                                            className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 20 16">
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                            />
                                        </svg>
                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                            <span className="font-semibold">
                                                Go to profile settings
                                            </span>{' '}
                                        </p>
                                        <p className="text-xs text-gray-500 dark:text-gray-400">
                                            Upload cover image (SVG, PNG, JPG or
                                            GIF (MAX. 800x400px)
                                        </p>
                                    </div>
                                </label>
                            </div>
                        )}
                        <div className="w-full">
                            <div className="flex flex-col md:flex-row  items-center w-full gap-3 ">
                                <div className="-mt-20 md:ml-6 w-52 h-52 flex-shrink-0 border-4 rounded-full border-white">
                                    <img
                                        className="rounded-full border-4 border-stone-50 overflow-hidden object-cover object-top h-full w-full"
                                        alt="profile"
                                        src={
                                            user?.profileImage
                                                ? `${process.env.REACT_APP_SERVER_DOMAIN}/${user?.profileImage}`
                                                : '/assets/images/blank.webp'
                                        }
                                    />
                                </div>
                                <div className=" w-full md:items-center flex flex-col md:flex-row justify-between">
                                    <div className="flex flex-1 flex-col gap-0.5 items-start">
                                        <p className="text-slate-800 text-lg font-TTHovesSB">
                                            {user?.firstName +
                                                ' ' +
                                                user?.lastName}
                                        </p>
                                        <p className="text-zinc-600 text-sm leading-none">
                                            {user?.businessName}
                                        </p>
                                        <div className="flex flex-col md:flex-row items-start md:items-center gap-3">
                                            <p className=" text-center px-3 py-1.5 bg-indigo-50 rounded-[7px] mt-1.5 text-gray-800 text-sm">
                                                {user?.servicePreferences
                                                    ?.hourlyRate
                                                    ? `${user?.currencySymbol} ${user?.servicePreferences?.hourlyRate} /hr`
                                                    : 'Not disclosed yet'}
                                            </p>
                                            <div className="flex flex-wrap items-center gap-3 mt-2">
                                                {firstThreeItems?.map(
                                                    session => (
                                                        <div
                                                            key={session._id}
                                                            className={classNames(
                                                                'cursor-pointer px-3 py-1.5 rounded-[7px] bg-white border border-slate-300',
                                                            )}>
                                                            <p className="text-center whitespace-nowrap text-zinc-900 text-sm">
                                                                {
                                                                    session
                                                                        ?.session
                                                                        ?.name
                                                                }
                                                            </p>
                                                        </div>
                                                    ),
                                                )}
                                                {remainingCount > 0 &&
                                                    more <
                                                        user?.sessions
                                                            ?.length && (
                                                        <p
                                                            onClick={() =>
                                                                setMore(10)
                                                            }
                                                            className="pl-2 rounded-full text-sm text-zinc-600 cursor-pointer">
                                                            +{remainingCount}{' '}
                                                            more
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    {user?.userType !== 'Photographer' ||
                                        (user?.profileCompletionPercentage !==
                                            100 && (
                                            <Menu
                                                as="div"
                                                className="hidden md:block relative">
                                                <Menu.Button className="p-3 rounded-lg bg-orange-100 flex items-center justify-between">
                                                    <div className="flex items-center gap-4">
                                                        <RoundChart
                                                            color1="#F0C84F"
                                                            color2="#F2F4F7"
                                                            percentage={
                                                                user?.profileCompletionPercentage
                                                            }
                                                            value={`${user?.profileCompletionPercentage}%`}
                                                            size={50}
                                                            strokeWidth={6}
                                                        />
                                                        <p className="text-slate-800 font-TTHovesM leading-snug">
                                                            Complete your
                                                            profile
                                                        </p>
                                                    </div>
                                                </Menu.Button>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95">
                                                    <Menu.Items className="absolute mx-auto w-80 right-0 md:w-96 top-0 origin-top-right rounded-xl p-4 bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                                        <div className="text-slate-400 text-sm p-1">
                                                            Complete Profile
                                                        </div>
                                                        <div className="bg-gradient-to-r from-yellow-50 via-pink-100 to-violet-50 rounded-lg mt-1">
                                                            <p className="text-center mx-auto p-3 text-gray-800 font-TTHovesM">
                                                                Your profile
                                                                will be listed
                                                                on the directory
                                                                when it reaches
                                                                90%.
                                                            </p>
                                                        </div>
                                                        <div className="divide-y divide-zinc-200 mt-2">
                                                            <div className="flex items-center gap-3 py-4">
                                                                {user
                                                                    ?.profileCompletionSteps
                                                                    ?.upload_images ? (
                                                                    <TickCircle
                                                                        variant="Bold"
                                                                        className="text-tale-10"
                                                                    />
                                                                ) : (
                                                                    <div className="w-[20px] h-[20px] opacity-3¡0 rounded-full border-2 border-slate-400" />
                                                                )}
                                                                <p className="text-zinc-800 font-TTHovesM">
                                                                    Add a short
                                                                    bio/Upload
                                                                    cover and
                                                                    profile
                                                                    image
                                                                </p>
                                                            </div>
                                                            <div className="flex items-center gap-3 py-4">
                                                                {user
                                                                    .profileCompletionSteps
                                                                    ?.upload_images ? (
                                                                    <TickCircle
                                                                        variant="Bold"
                                                                        className="text-tale-10"
                                                                    />
                                                                ) : (
                                                                    <div className="w-[20px] h-[20px] opacity-30 rounded-full border-2 border-slate-400" />
                                                                )}
                                                                <p className="text-zinc-800 font-TTHovesM">
                                                                    Upload a
                                                                    cover image
                                                                </p>
                                                            </div>
                                                            <div className="flex items-center gap-3 py-4">
                                                                {user
                                                                    ?.profileCompletionSteps
                                                                    ?.collaborate ? (
                                                                    <TickCircle
                                                                        variant="Bold"
                                                                        className="text-tale-10"
                                                                    />
                                                                ) : (
                                                                    <div className="w-[20px] h-[20px] opacity-30 rounded-full border-2 border-slate-400" />
                                                                )}
                                                                <p className="text-zinc-800 font-TTHovesM">
                                                                    Add your
                                                                    collaboration
                                                                    interests
                                                                </p>
                                                            </div>{' '}
                                                            <div className="flex items-center gap-3 py-4">
                                                                {user
                                                                    ?.profileCompletionSteps
                                                                    ?.session ? (
                                                                    <TickCircle
                                                                        variant="Bold"
                                                                        className="text-tale-10"
                                                                    />
                                                                ) : (
                                                                    <div className="w-[20px] h-[20px] opacity-30 rounded-full border-2 border-slate-400" />
                                                                )}
                                                                <p className="text-zinc-800 font-TTHovesM">
                                                                    Sessions you
                                                                    offer
                                                                </p>
                                                            </div>
                                                            <div className="flex items-center gap-3 py-4">
                                                                {user
                                                                    ?.profileCompletionSteps
                                                                    ?.portfolio ? (
                                                                    <TickCircle
                                                                        variant="Bold"
                                                                        className="text-tale-10"
                                                                    />
                                                                ) : (
                                                                    <div className="w-[20px] h-[20px] opacity-30 rounded-full border-2 border-slate-400" />
                                                                )}
                                                                <p className="text-zinc-800 font-TTHovesM">
                                                                    Add photos
                                                                    to your
                                                                    portfolio
                                                                </p>
                                                            </div>
                                                            <div className="flex items-center gap-3 py-4">
                                                                {user
                                                                    ?.servicePreferences
                                                                    ?.hourlyRate ? (
                                                                    <TickCircle
                                                                        variant="Bold"
                                                                        className="text-tale-10"
                                                                    />
                                                                ) : (
                                                                    <div className="w-[20px] h-[20px] opacity-30 rounded-full border-2 border-slate-400" />
                                                                )}
                                                                <p className="text-zinc-800 font-TTHovesM">
                                                                    Add pricing
                                                                    packages
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex max-w-4xl justify-between">
                        <div className="flex mt-6 flex-col gap-2">
                            <div className="flex items-center gap-2">
                                <Location size={18} />
                                <p className="text-slate-700 text-sm font-medium leading-tight">
                                    {user?.location}
                                </p>
                            </div>
                            <div className="flex items-center gap-2">
                                <ReceiptItem size={18} />
                                <p className="text-slate-700 text-sm font-medium leading-tight">
                                    {education?.experiences?.yearsInBusiness} in
                                    business
                                </p>
                            </div>
                            {/*<div className="flex items-center gap-2">*/}
                            {/*    <Global size={18} />*/}
                            {/*    <p className="text-slate-700 text-sm font-medium leading-tight">*/}
                            {/*        {user?.website}*/}
                            {/*    </p>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <div className="border-t max-w-4xl border-zinc-200 mt-3">
                        <p className="mt-3 font-TTHovesM text-zinc-800">
                            About {user?.firstName}
                        </p>
                        <p
                            className="text-slate-800 font-medium mt-1.5"
                            dangerouslySetInnerHTML={{
                                __html: user?.bio,
                            }}
                        />
                    </div>
                    <div className="border-t max-w-4xl border-zinc-200 mt-3">
                        <p className="mt-3 font-TTHovesM text-zinc-800">
                            Experiences & Certifications
                        </p>
                        {education?.experiences?.yearsInBusiness &&
                        education?.experiences?.noOfEmployees > 0 &&
                        education?.experiences?.yearsOfExperience ? (
                            <p className="text-zinc-800 text-sm mt-2">
                                Experience
                            </p>
                        ) : (
                            <p className="text-sm text-zinc-800 mt-2">
                                No Experience added yet{' '}
                            </p>
                        )}
                        <div className="flex mt-1 gap-2">
                            {education?.experiences?.yearsInBusiness && (
                                <div className="px-3 py-1.5 text-sm rounded-md border border-zinc-200 bg-white">
                                    {education?.experiences?.yearsInBusiness} in
                                    business
                                </div>
                            )}
                            {/*<div className="px-3 py-1.5 text-sm rounded-md border border-zinc-200 bg-white">*/}
                            {/*    56 reviews*/}
                            {/*</div>*/}
                            {education?.experiences?.noOfEmployees > 0 && (
                                <div className="px-3 py-1.5 text-sm rounded-md border border-zinc-200 bg-white">
                                    {education?.experiences?.noOfEmployees}{' '}
                                    employees
                                </div>
                            )}
                            {education?.experiences?.yearsOfExperience && (
                                <div className="px-3 py-1.5 text-sm rounded-md border border-zinc-200 bg-white">
                                    {education?.experiences?.yearsOfExperience}{' '}
                                    of experience
                                </div>
                            )}
                        </div>

                        {education?.certifications?.length > 0 && (
                            <p className="text-zinc-800 text-sm mt-3">
                                Certifications
                            </p>
                        )}
                        <div className="flex mt-1 gap-2">
                            {education?.certifications?.map(item => (
                                <div
                                    key={item?._id}
                                    className="px-3 py-1.5 text-sm rounded-md border border-zinc-200 bg-white">
                                    {item?.title}
                                </div>
                            ))}
                        </div>
                    </div>
                    <p className="text-slate-800 mt-2 text-[22px] font-TTHovesM ">
                        Portfolio
                    </p>
                    <div className="grid grid-cols-3 md:grid-cols-10 gap-3 mt-2">
                        <div
                            onClick={() => setCurrentSession('')}
                            className={classNames(
                                'cursor-pointer px-4 py-2.5 rounded-[7px]',
                                {
                                    'bg-white border text-zinc-900 border-slate-300':
                                        currentSession,
                                    'bg-tale-10 text-white': !currentSession,
                                },
                            )}>
                            <p className="text-center text-sm">All</p>
                        </div>
                        {user?.portfolio?.map(tag => (
                            <div
                                key={tag?._id}
                                onClick={() =>
                                    setCurrentSession(tag.session._id)
                                }
                                className={classNames(
                                    'cursor-pointer px-4 py-2.5 rounded-[7px]',
                                    {
                                        'bg-white border text-zinc-900 border-slate-300':
                                            tag?.session?._id !==
                                            currentSession,
                                        'bg-tale-10 text-white':
                                            tag?.session?._id ===
                                            currentSession,
                                    },
                                )}>
                                <p className="text-center text-sm">
                                    {tag?.session?.name}
                                </p>
                            </div>
                        ))}
                    </div>
                    <ProfileGrid
                        portfolio={user?.portfolio}
                        id={user?._id}
                        current={currentSession}
                    />

                    {education?.faqs?.length > 0 && (
                        <p className="text-slate-800 mt-6 text-[22px] font-TTHovesM ">
                            FAQs
                        </p>
                    )}
                    <div className="flex flex-col gap-2 mt-2">
                        {education?.faqs?.map(item => (
                            <Accordion
                                showBurger={false}
                                key={item?._id}
                                title={item?.question}>
                                <div className="text-sm py-2">
                                    {item?.answer}
                                </div>
                            </Accordion>
                        ))}
                    </div>

                    <p className="mt-6 font-TTHovesM text-zinc-800 border-t border-zinc-200 py-3">
                        Reviews
                    </p>
                    <div className="max-w-4xl grid grid-cols-3 divide-x divide-zinc-200">
                        <div>
                            <p className="font-TTHovesM text-zinc-800">
                                Total Reviews
                            </p>
                            <p className="font-TTHovesSB text-3xl mt-3">132</p>
                            <p className="leading-none text-zinc-500 font-TTHovesL">
                                No of reviews this year
                            </p>
                        </div>
                        <div className="pl-10">
                            <p className="font-TTHovesM text-zinc-800">
                                Total Reviews
                            </p>
                            <p className="font-TTHovesSB text-3xl mt-3">4.9</p>
                            <p className="leading-none text-zinc-500 font-TTHovesL">
                                Average ratings this year
                            </p>
                        </div>
                        {/*<div className="pl-10">*/}
                        {/*    <p className="font-TTHovesM text-zinc-800">*/}
                        {/*        Total Reviews*/}
                        {/*    </p>*/}
                        {/*    <p className="font-TTHovesSB text-3xl mt-3">*/}
                        {/*        4.9*/}
                        {/*    </p>*/}
                        {/*    <p className="leading-none text-zinc-500 font-TTHovesL">*/}
                        {/*        Average ratings this year*/}
                        {/*    </p>*/}
                        {/*</div>*/}
                    </div>
                    {/*<div className="hidden md:flex mt-4 px-20 py-8 bg-[#E9F1DB] rounded-[10px] items-center gap-7">*/}
                    {/*    <div className="h-36 w-36">*/}
                    {/*        <img*/}
                    {/*            alt="profile"*/}
                    {/*            src="/assets/homepage-photographer-2.jpg"*/}
                    {/*            className="h-full w-full rounded-full ring-4 ring-white"*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <div className="flex flex-col gap-2">*/}
                    {/*        <p className="text-slate-500 font-medium ">*/}
                    {/*            Feeling interested?*/}
                    {/*        </p>*/}
                    {/*        <p className="text-slate-800 text-3xl font-medium font-OTabolas mb-1">*/}
                    {/*            Want to work with Barbiana Liu?{' '}*/}
                    {/*        </p>*/}
                    {/*        <Button className="w-60 flex gap-4 items-center justify-center bg-tale-10 border-tale-10 p-3 text-white">*/}
                    {/*            <Send2 className="h-6 w-6" color="#FFFFFF" />*/}
                    {/*            <span>Get in touch</span>*/}
                    {/*        </Button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            )}
        </div>
    )
}

interface RoundChartProps {
    percentage: number
    size: number
    strokeWidth: number
    color1: string
    color2: string
    value: string
}

const RoundChart: React.FC<RoundChartProps> = ({
    percentage,
    size,
    strokeWidth,
    color1,
    value,
    color2,
}) => {
    const radius = size / 2 - strokeWidth / 2
    const circumference = 2 * Math.PI * radius
    const offset = circumference - (percentage / 100) * circumference
    return (
        <svg
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
            xmlns="http://www.w3.org/2000/svg">
            <circle
                cx={size / 2}
                cy={size / 2}
                r={radius}
                fill="none"
                stroke={color2}
                strokeWidth={strokeWidth}
            />

            <circle
                cx={size / 2}
                cy={size / 2}
                r={radius}
                fill="none"
                stroke={color1}
                strokeWidth={strokeWidth}
                strokeDasharray={circumference}
                strokeDashoffset={offset}
                strokeLinecap="round"
                transform={`rotate(-90 ${size / 2} ${size / 2})`}
            />
            <text
                x="50%"
                y="50%"
                textAnchor="middle"
                alignmentBaseline="middle"
                fontSize="12"
                fill="black"
                fontWeight={700}>
                {value}
            </text>
        </svg>
    )
}

export default RoundChart

{
    /*<div className="w-full md:w-96 rounded-[10px] border border-slate-300">*/
}
{
    /*    <div className="flex items-center justify-between p-4">*/
}
{
    /*        <div className="flex gap-2">*/
}

{
    /*<StatusBadge*/
}
{
    /*    type={StatusColorMap['Response']}*/
}
{
    /*    text="Response time: 1HR"*/
}
{
    /*/>*/
}
{
    /*    </div>*/
}
{
    /*    <button*/
}
{
    /*        type="button"*/
}
{
    /*        onClick={() =>*/
}
{
    /*            navigate('/dashboard/settings?tab=profile')*/
}
{
    /*        }*/
}
{
    /*        className="bg-white rounded-lg px-3 py-2 border gap-2 border-zinc-200 flex items-center text-sm">*/
}
{
    /*        <Edit2 className="h-4 w-4" />*/
}
{
    /*        <span className="font-TTHovesM">Edit</span>*/
}
{
    /*    </button>*/
}
{
    /*</div>*/
}
{
    /*<div className="flex flex-col items-center justify-center gap-3 mt-4">*/
}
{
    /*    <div className="block md:hidden h-96 w-full">*/
}
{
    /*        <img*/
}
{
    /*            alt="couple"*/
}
{
    /*            src={user?.coverImage}*/
}
{
    /*            className="w-full h-full rounded-lg object-cover"*/
}
{
    /*        />*/
}
{
    /*    </div>*/
}

{
    /*<div className="-mt-7">*/
}
{
    /*<PhotographerType*/
}
{
    /*    type="Pro photographer"*/
}
{
    /*    fontSize="text-[13px]"*/
}
{
    /*    padding="p-2"*/
}
{
    /*/>*/
}
{
    /*</div>*/
}
{
    /*<p className="text-slate-800 text-[22px] font-semibold">*/
}
{
    /*    {user?.firstName + ' ' + user?.lastName}*/
}
{
    /*</p>*/
}
{
    /*<Button className="w-60 flex gap-4 items-center justify-center bg-tale-10 border-tale-10 p-3 text-white">*/
}
{
    /*    <Send2 className="h-6 w-6" color="#FFFFFF" />*/
}
{
    /*    <span>Get in touch</span>*/
}
{
    /*</Button>*/
}
{
    /*{user?.workPreferences?.acceptingNewClients && (*/
}
{
    /*    <div className="font-medium">*/
}
{
    /*        <span className="text-slate-500">*/
}
{
    /*            {user?.firstName} is{' '}*/
}
{
    /*        </span>*/
}
{
    /*        <span className="text-slate-800">*/
}
{
    /*            accepting new Clients*/
}
{
    /*        </span>*/
}
{
    /*    </div>*/
}
{
    /*)}*/
}

{
    /*            ))}*/
}
{
    /*    </div>*/
}
{
    /*    <div className="p-4">*/
}
{
    /*        <p className="text-slate-500 text-base font-medium">*/
}
{
    /*            Pricing*/
}
{
    /*        </p>*/
}
{
    /*        <p className="w-32 text-center p-2.5 bg-indigo-50 rounded-[7px] mt-2 text-gray-800 text-sm">*/
}
{
    /*            {user?.servicePreferences?.hourlyRate*/
}
{
    /*                ? `${user?.currencySymbol} ${user?.servicePreferences?.hourlyRate} /hr`*/
}
{
    /*                : 'Not disclosed yet'}*/
}
{
    /*        </p>*/
}
{
    /*        <div className="border-t border-zinc-200 py-2 mt-4">*/
}
{
    /*            <p className="text-slate-500 text-base font-medium">*/
}
{
    /*                Session*/
}
{
    /*            </p>*/
}

{
    /*            <div className="border-t border-zinc-200 py-2 mt-4">*/
}
{
    /*                <p className="text-slate-500 text-base font-medium ">*/
}
{
    /*                    About*/
}
{
    /*                </p>*/
}
{
    /*
                    {/*                <div className="flex items-center gap-2 mt-4">*/
}
{
    /*                    <Location />*/
}
{
    /*                    <p className="text-slate-500 text-sm font-semibold leading-tight">*/
}
{
    /*                        {user?.location}*/
}
{
    /*                    </p>*/
}
{
    /*                </div>*/
}
{
    /*            </div>*/
}
{
    /*        </div>*/
}
{
    /*    </div>*/
}
{
    /*</div>*/
}
