import { configureStore, Action } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { thunk, ThunkAction as TAction } from 'redux-thunk'
import { persistCombineReducers, persistStore } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import authReducer from './reducers/authReducer'
import projectReducer from './reducers/projectReducer'
import balance_wallet from './reducers/wallet'

const reducers = {
    user: authReducer,
    project: projectReducer,
    balanceDetails: balance_wallet,
}

const persistConfig = {
    key: 'root',
    storage: storage,
    version: 1,
    stateReconciler: autoMergeLevel2,
    whitelist: ['user', 'project'],
}

const persistedReducer = persistCombineReducers(persistConfig, reducers)

export const store = configureStore({
    reducer: persistedReducer,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(thunk),
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = TAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
export const persistor = persistStore(store)
