import React, { Fragment, useEffect, useState } from 'react'
import { ArrowRight2, Home2, RefreshCircle } from 'iconsax-react'
import { Input } from 'components/form/Input/Input'
import { ReactSelect } from 'components/form/ReactSelect/ReactSelect'
import UserService from 'services/user.service'
import { IconGallery } from '../../../css/icons/icons'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../redux/selectors/user'
import classNames from 'classnames'
import { styles } from 'pages/dashboard/Settings'
import { useNavigate, useParams } from 'react-router-dom'
import { useNotifications } from 'hooks/useNotification'

const service = new UserService()

export const GallerySettings = () => {
    const [state, setState] = useState<any>({
        title: '',
        watermark: false,
        allowDownload: false,
        images: [],
        coverStyle: '',
        passwordProtected: false,
    })

    const navigate = useNavigate()
    const user = useSelector(userSelector)
    const { successMessage } = useNotifications()

    const { id } = useParams()

    useEffect(() => {
        service
            .getSpecificGallery(id ?? '')
            .then(resp => {
                setState({
                    ...state,
                    title: resp.data.data.title,
                    watermark: resp.data.data.watermark,
                    allowDownload: resp.data.data.allowDownload,
                    images: resp.data.data.images,
                    coverStyle: resp.data.data.coverStyle,
                    passwordProtected: resp.data.data.passwordProtected,
                })

                if (resp?.data?.data?.userId?._id !== user?._id) {
                    navigate('/dashboard')
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [id])

    const handleSubmit = () => {
        service
            .updateGallerySettings(id ?? '', { ...state, userId: user._id })
            .then(resp => {
                console.log(resp)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setState({
                    title: '',
                    watermark: false,
                    allowDownload: false,
                    images: [],
                    passwordProtected: false,
                })
                successMessage('Settings updated successfully')
                navigate(`/dashboard/gallery/${id}`)
            })
    }
    console.log('=====', state)
    return (
        <Fragment>
            <div className="flex items-center justify-between ">
                <div className="hidden items-center gap-2 md:flex">
                    <Home2 className="h-5 w-5" />
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div
                        onClick={() => navigate(-1)}
                        className="px-2 cursor-pointer py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                        Galleries
                    </div>
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div
                        onClick={() => navigate(-1)}
                        className="px-2 cursor-pointer py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                        Gallery Settings
                    </div>
                    <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                    <div className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                        {state?.title}
                    </div>
                </div>
                <div className={classNames('flex items-center gap-3')}>
                    <button
                        onClick={() => navigate(-1)}
                        type="button"
                        className={styles.default}>
                        <span className="text-slate-700 text-sm font-TTHovesM leading-tight">
                            Back
                        </span>
                    </button>
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className={styles.primary}>
                        <span className="text-white text-sm font-TTHovesM leading-tight">
                            Save
                        </span>
                    </button>
                </div>
            </div>
            <div className="flex-col justify-start items-start inline-flex mt-5">
                <p className="text-zinc-900 text-lg font-TTHovesSB">
                    Gallery Settings
                </p>
            </div>
            <div className="flex flex-col md:flex-row gap-10 mt-8">
                <div className="w-full md:w-2/3 flex flex-col gap-8">
                    <Input
                        type="text"
                        name="title"
                        placeholder="Gallery Title"
                        onChange={e =>
                            setState({
                                ...state,
                                title: e.target.value,
                            })
                        }
                        label="Gallery Title"
                        value={state.title}
                    />

                    <div className="flex flex-col gap-2">
                        <label className="font-TTHovesM text-zinc-800">
                            Cover Photo Style
                        </label>
                        <div className="border p-4 border-gray-outline rounded-lg h-60 pb-10 w-full flex gap-4">
                            <div
                                onClick={() =>
                                    setState({
                                        ...state,
                                        coverStyle: 'editorial',
                                    })
                                }
                                className={classNames(
                                    'hover:border hover:border-dashed cursor-pointer border-tale-10 hover:bg-gradient-to-b hover:from-white hover:to-tale-10/20 rounded-lg flex-1',
                                    {
                                        'bg-gradient-to-b border border-dashed  from-white to-tale-10/20':
                                            state.coverStyle === 'editorial',
                                    },
                                )}>
                                <img
                                    alt="editorial"
                                    src="/assets/editorial.png"
                                    className="w-full h-full object-contain"
                                />
                                <p className="text-center text-zinc-700 mt-2">
                                    Editorial
                                </p>
                            </div>
                            <div
                                onClick={() =>
                                    setState({
                                        ...state,
                                        coverStyle: 'eclipse',
                                    })
                                }
                                className={classNames(
                                    'bg-white rounded-lg cursor-pointer hover:border hover:border-dashed border-tale-10 hover:bg-gradient-to-b hover:from-white hover:to-tale-10/20 flex-1',
                                    {
                                        'bg-gradient-to-b border border-dashed  from-white to-tale-10/20':
                                            state.coverStyle === 'eclipse',
                                    },
                                )}>
                                <img
                                    alt="eclipse"
                                    src="/assets/eclipse.png"
                                    className="w-full h-full object-contain"
                                />
                                <p className="text-center text-zinc-700 mt-2">
                                    Eclipse
                                </p>
                            </div>
                            <div
                                onClick={() =>
                                    setState({
                                        ...state,
                                        coverStyle: 'beauty',
                                    })
                                }
                                className={classNames(
                                    'bg-white rounded-lg cursor-pointer hover:border hover:border-dashed border-tale-10 hover:bg-gradient-to-b hover:from-white hover:to-tale-10/20 flex-1',
                                    {
                                        'bg-gradient-to-b border border-dashed  from-white to-tale-10/20':
                                            state.coverStyle === 'beauty',
                                    },
                                )}>
                                <img
                                    alt="beauty"
                                    src="/assets/beauty.png"
                                    className="w-full h-full object-contain"
                                />
                                <p className="text-center text-zinc-700 mt-2">
                                    Beauty
                                </p>
                            </div>
                        </div>
                    </div>
                    <ReactSelect
                        placeholder="Add Watermark"
                        options={[
                            {
                                value: 'No',
                                label: 'No',
                            },
                            {
                                value: 'Yes',
                                label: 'Yes',
                            },
                        ]}
                        onChange={value =>
                            setState({
                                ...state,
                                watermark: value !== 'No',
                            })
                        }
                        label="Add Watermark"
                        value={state.watermark ? 'Yes' : 'No'}
                    />
                    <ReactSelect
                        placeholder="Allow Download"
                        options={[
                            {
                                value: 'No',
                                label: 'No',
                            },
                            {
                                value: 'Yes',
                                label: 'Yes, Show a download button on the gallery',
                            },
                        ]}
                        onChange={value =>
                            setState({
                                ...state,
                                allowDownload: value !== 'No',
                            })
                        }
                        label="Allow Download"
                        value={state.allowDownload ? 'Yes' : 'No'}
                    />
                    <ReactSelect
                        placeholder="Password Protected"
                        options={[
                            {
                                value: 'No',
                                label: 'No, anyone with the url can access this album',
                            },
                            {
                                value: 'Yes',
                                label: 'Yes',
                            },
                        ]}
                        onChange={value =>
                            setState({
                                ...state,
                                passwordProtected: value !== 'No',
                            })
                        }
                        label="Password Protected"
                        value={state.passwordProtected === "true" || state.passwordProtected === true ? 'Yes' : 'No'}
                    />
                    {state?.passwordProtected && (
                        <Input
                            value={state.galleryPassword}
                            onChange={e => setState({
                                ...state,
                                galleryPassword: e.target.value,
                            })}
                            placeholder="Password"
                            type="password"
                            label="Enter password"
                        />
                    )}
                </div>
            </div>
        </Fragment>
    )
}
