import { ArrowRight2, Home2, RefreshCircle } from 'iconsax-react'
import { Fragment, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { BasicForm } from 'components/dashboard/quotes/BasicForm'
import { BudgetForm } from 'components/dashboard/quotes/BudgetForm'
import { CheckCircle } from '../../css/icons/icons'
import { useSelector } from 'react-redux'
import { authSelector, userSelector } from '../../redux/selectors/user'
import UserService from 'services/user.service'
import { useFormik } from 'formik'
import { QuoteValidationSchema } from 'utils/validations'
import { useNotifications } from 'hooks/useNotification'
import { useSession } from 'hooks/useSession'
import Gallery from 'components/dashboard/quotes/Gallery'
import { setAddQuote } from '../../redux/reducers/projectReducer'
import { useThunkDispatch } from 'hooks/useThunkDisptach'

const service = new UserService()

export const AddQuote = () => {
    const [form, setForm] = useState('Basic') // Basic, Gallery, Budget
    const [loading, setLoading] = useState(false)
    const [photographerImages, setPhotographerImages] = useState({
        images: [],
        session: null,
    })
    const [selectedPhotgrapherImages, setSelectedImages] = useState<string[]>(
        [],
    )

    const { isAuth } = useSelector(authSelector)

    const dispatch = useThunkDispatch()

    const user = useSelector(userSelector)
    const { successMessage } = useNotifications()
    const { sessions } = useSession()

    const [params] = useSearchParams()

    const nextStep = () => {
        dispatch(setAddQuote(formikProps.values))
        if (
            BASIC === 'Basic' &&
            formikProps.values?.title &&
            formikProps.values?.serviceType
        ) {
            setForm(form === 'Basic' ? 'Budget' : 'Gallery')
            formikProps.setFieldTouched('title', true)
            formikProps.setFieldTouched('serviceType', true)
        } else if (BASIC === 'Budget' && formikProps.values?.shootTime) {
            setForm(form === 'Basic' ? 'Budget' : 'Gallery')
        } else if (formikProps.isValid) {
            formikProps.handleSubmit()
        }
    }

    const formikProps = useFormik({
        initialValues: {
            title: '',
            serviceType: '',
            description: '',
            shootDate: '',
            quoteLimit: '5',
            budget: '',
            shootTime: '',
            estimatedShootTime: '',
            user: user?._id,
            status: 'Live',
            longitude: '',
            rate: 'Flat',
            latitude: '',
            locationString: '',
        },
        validationSchema: QuoteValidationSchema,
        onSubmit: values => {
            if (isAuth) {
                if (
                    params.has('id') &&
                    selectedPhotgrapherImages.length &&
                    form === 'Gallery'
                ) {
                    service
                        .updateQuote(values, params.get('id') ?? '')
                        .then(res => {
                            service
                                .quotePhotographerImages(res.data.data._id, {
                                    jobPortfolio:
                                        selectedPhotgrapherImages || [],
                                })
                                .then(() => {
                                    navigate('/dashboard/quotes')
                                    successMessage('Quote updated successfully')
                                })
                        })
                        .catch(error => {
                            console.log({ error })
                        })
                        .finally(() => {
                            formikProps.setSubmitting(false)
                        })
                } else if (
                    selectedPhotgrapherImages.length &&
                    form === 'Gallery'
                ) {
                    service
                        .createQuote(values)
                        .then(res => {
                            service
                                .quotePhotographerImages(res.data.data._id, {
                                    jobPortfolio: selectedPhotgrapherImages,
                                })
                                .then(() => {
                                    navigate('/dashboard/quotes')
                                    successMessage('Quote added successfully')
                                })
                        })
                        .catch(error => {
                            console.log({ error })
                        })
                        .finally(() => {
                            formikProps.setSubmitting(false)
                        })
                }
            } else {
                navigate('/client-signup')
            }
        },
    })

    const onChangeSession = async (name, value) => {
        if (name) formikProps.setFieldValue(name, value)

        const { data } = await service.getPhotographerImages(value)
        if (data?.data.length) setPhotographerImages(data?.data[0])
    }

    const selectedImages = (imgURL: string) => {
        const newSelectedPhotgrapherImages: string[] = [
            ...selectedPhotgrapherImages,
        ]

        if (newSelectedPhotgrapherImages.includes(imgURL)) {
            const index = newSelectedPhotgrapherImages.indexOf(imgURL)
            newSelectedPhotgrapherImages.splice(index, 1)
        } else {
            newSelectedPhotgrapherImages.push(imgURL)
        }

        setSelectedImages(newSelectedPhotgrapherImages)
    }

    useEffect(() => {
        if (params.has('id')) {
            setLoading(true)
            service
                .getSingleQuote(params.get('id') ?? '')
                .then(resp => {
                    formikProps.setFieldValue('title', resp.data.data.title)
                    formikProps.setFieldValue(
                        'serviceType',
                        resp.data.data.serviceType?._id,
                    )
                    onChangeSession(null, resp.data.data.serviceType?._id)
                    formikProps.setFieldValue(
                        'description',
                        resp.data.data.description,
                    )
                    formikProps.setFieldValue(
                        'shootTime',
                        resp.data.data.shootTime,
                    )
                    formikProps.setFieldValue(
                        'estimatedShootTime',
                        resp.data.data.estimatedShootTime,
                    )
                    formikProps.setFieldValue(
                        'shootDate',
                        new Date(resp.data.data.shootDate)
                            .toISOString()
                            .split('T')[0],
                    )
                    formikProps.setFieldValue(
                        'locationString',
                        resp.data.data.locationString,
                    )
                    formikProps.setFieldValue('rate', resp.data.data.rate)
                    formikProps.setFieldValue('budget', resp.data.data.budget)
                    // formikProps.setFieldValue(
                    //     'shootDate',
                    //     resp.data.data.shootDate,
                    // )
                    formikProps.setFieldValue(
                        'quoteLimit',
                        resp.data.data.quoteLimit,
                    )

                    setSelectedImages(resp.data?.data?.jobPortfolio || [])
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [params])

    const BASIC = form
    const navigate = useNavigate()

    return (
        <Fragment>
            <div className="flex flex-col md:flex-row md:items-start justify-between">
                {isAuth && (
                    <div className="hidden items-center gap-2 md:flex">
                        <Home2 className="h-5 w-5" />
                        <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                        <p className="px-2 py-1 text-slate-600 text-sm font-TTHovesM leading-tight">
                            Directory
                        </p>
                        <ArrowRight2 className="h-4 w-4 text-zinc-400" />
                        <p className="px-2 py-1.5 bg-white rounded-[5px] border border-black border-opacity-10 text-slate-700 text-sm font-TTHovesSB leading-tight">
                            {params.has('id') ? 'Update' : 'Create'} Quote
                        </p>
                    </div>
                )}

                {/* Buttons */}
                <div
                    className={`flex items-center ${
                        !isAuth && 'border-b w-full border-zinc-200 '
                    }  justify-between`}>
                    {!isAuth && (
                        <div className=" pb-5 ">
                            <div className="text-slate-800 text-lg font-TTHovesSB leading-7">
                                {BASIC === 'Basic'
                                    ? 'What do you need help with?'
                                    : BASIC === 'Budget'
                                      ? 'What is the budget for your project?'
                                      : "What's your style?"}
                            </div>
                            <div className="text-slate-600 text-sm">
                                {BASIC === 'Basic'
                                    ? 'Tell us about your project needs.'
                                    : BASIC === 'Budget'
                                      ? 'This will help us match you with the photographer within your range.'
                                      : 'Choose the style you like, this will help photographers to know what you are looking for.'}
                            </div>
                        </div>
                    )}
                    <div className="flex items-center gap-3">
                        <button
                            type="button"
                            onClick={() =>
                                BASIC === 'Basic'
                                    ? navigate('/dashboard/quotes')
                                    : setForm(
                                          form === 'Gallery'
                                              ? 'Budget'
                                              : 'Basic',
                                      )
                            }
                            className={`${styles.default}`}>
                            <span className="text-slate-700 text-sm font-TTHovesM leading-tight">
                                {BASIC !== 'Basic' ? 'Back' : 'Cancel'}
                            </span>
                        </button>
                        <button
                            onClick={() => nextStep()}
                            // disabled={formikProps.isSubmitting}
                            type="button"
                            className={`${styles.primary}`}>
                            <span className="text-white text-sm font-TTHovesM leading-tight">
                                {BASIC === 'Basic' || BASIC === 'Budget'
                                    ? 'Next Step'
                                    : params.has('id')
                                      ? 'Update Quote'
                                      : 'Create Quote'}
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            {/* Title */}
            {isAuth && (
                <div className="border-b py-5 border-zinc-200 w-full">
                    <div className="text-slate-800 text-lg font-TTHovesSB leading-7">
                        {BASIC === 'Basic'
                            ? 'What do you need help with?'
                            : BASIC === 'Budget'
                              ? 'What is the budget for your project?'
                              : "What's your style?"}
                    </div>
                    <div className="text-slate-600 text-sm">
                        {BASIC === 'Basic'
                            ? 'Tell us about your project needs.'
                            : BASIC === 'Budget'
                              ? 'This will help us match you with the photographer within your range.'
                              : 'Choose the style you like, this will help photographers to know what you are looking for.'}
                    </div>
                </div>
            )}
            {/* Title End */}
            <div className="flex flex-col md:flex-row gap-16 mt-9">
                {loading ? (
                    <RefreshCircle className="animate-spin flex-1 size-10 text-tale-10 my-8" />
                ) : (
                    <form className="w-full md:w-2/3">
                        {BASIC === 'Basic' ? (
                            <BasicForm
                                sessions={sessions}
                                {...formikProps}
                                onChangeSession={onChangeSession}
                            />
                        ) : undefined}
                        {BASIC === 'Budget' ? (
                            <BudgetForm {...formikProps} page={form} />
                        ) : undefined}
                        {BASIC === 'Gallery' ? (
                            <Gallery
                                slectedService={sessions.find(
                                    item =>
                                        item._id ===
                                        formikProps?.values?.serviceType,
                                )}
                                data={photographerImages}
                                selectedImages={selectedImages}
                                selectedPhotgrapherImages={
                                    selectedPhotgrapherImages
                                }
                            />
                        ) : undefined}
                    </form>
                )}

                {/* Right Side */}
                {isAuth && (
                    <div className="flex-1 flex flex-col gap-3">
                        <div className="min-h-[290px] bg-white rounded-xl border border-zinc-200 p-5">
                            <p className="text-zinc-500 text-[10px] font-TTHovesM tracking-wider">
                                QUOTED BY
                            </p>
                            <div className="flex gap-3 items-center mt-4">
                                <div className="h-12 w-12 rounded-full relative">
                                    <img
                                        alt="profile"
                                        className="h-full w-full rounded-full object-cover object-top"
                                        src={
                                            user.profileImage
                                                ? `${process.env.REACT_APP_SERVER_DOMAIN}/${user.profileImage}`
                                                : '/assets/images/blank.webp'
                                        }
                                    />
                                    <div className="absolute bottom-0.5 right-1 h-2 w-2 rounded-full bg-green-600" />
                                </div>
                                <div>
                                    <div className="text-zinc-700 text-base font-TTHovesSB ">
                                        {user?.firstName + ' ' + user?.lastName}
                                    </div>
                                    <div className="text-zinc-600 text-sm leading-[18px]">
                                        {user?.location}
                                    </div>
                                </div>
                            </div>
                            <div className="h-px w-full my-4 bg-zinc-200" />
                            <div className="text-zinc-700 text-base font-TTHovesSB">
                                Pre-launch checklist
                            </div>
                            <div className="text-zinc-600 text-[13px] leading-[18px]">
                                Complete the checklist in oder to submit the job
                                quotation
                            </div>
                            <div className="flex flex-col gap-6 mt-4 relative z-10">
                                <div className="flex items-center gap-3">
                                    {BASIC === 'Basic' ||
                                    BASIC === 'Budget' ||
                                    BASIC === 'Gallery' ? (
                                        <div className="h-6 w-6 rounded-full border-2 border-[#E8C0A6]">
                                            <div className="border-[3px] border-white bg-[#E8C0A6] h-full w-full rounded-full" />
                                        </div>
                                    ) : (
                                        <CheckCircle className="text-zinc-200 w-6 h-6" />
                                    )}
                                    <p className="text-zinc-900 text-sm font-TTHovesM">
                                        Quote Details
                                    </p>
                                </div>
                                <div className="absolute h-10 w-px bg-zinc-200 left-3 top-4 -z-10" />
                                <div className="flex items-center gap-3">
                                    {BASIC === 'Budget' ||
                                    BASIC === 'Gallery' ? (
                                        <div className="h-6 w-6 rounded-full border-2 border-[#E8C0A6]">
                                            <div className="border-[3px] border-white bg-[#E8C0A6] h-full w-full rounded-full" />
                                        </div>
                                    ) : (
                                        <CheckCircle className="text-zinc-200 w-6 h-6" />
                                    )}
                                    <p className="text-zinc-600 text-sm ">
                                        Budget
                                    </p>
                                </div>
                                <div className="absolute h-10 w-px bg-zinc-200 left-3 bottom-4 -z-10" />
                                <div className="flex items-center gap-3">
                                    {BASIC === 'Gallery' ? (
                                        <div className="h-6 w-6 rounded-full border-2 border-[#E8C0A6]">
                                            <div className="border-[3px] border-white bg-[#E8C0A6] h-full w-full rounded-full" />
                                        </div>
                                    ) : (
                                        <CheckCircle className="text-zinc-200 w-6 h-6" />
                                    )}
                                    <p className="text-zinc-600 text-sm ">
                                        Gallery
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="p-5 bg-white rounded-xl border border-zinc-200 flex-col justify-center items-start gap-[11px] inline-flex">
                            <div className="items-center gap-2 inline-flex">
                                <div className="w-4 h-4">
                                    <img
                                        alt="bulb"
                                        className="h-full w-full"
                                        src="/assets/bulb.png"
                                    />
                                </div>
                                <p className="text-zinc-900 text-sm font-TTHovesSB tracking-wider">
                                    SUURA Smart Suggestion
                                </p>
                            </div>
                            <div className="self-stretch pr-7 pb-[0.63px] justify-start items-start inline-flex">
                                <p className=" text-zinc-600 text-sm">
                                    Keep in mind that budget and duration are
                                    flexible and can be adjusted during
                                    discussions with Photographers
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                {/* Right Side End */}
            </div>
        </Fragment>
    )
}

const styles = {
    primary:
        'px-4 py-2.5 bg-tale-10 rounded-lg shadow justify-center items-center gap-2 inline-flex',
    default:
        'px-4 py-2.5 bg-white rounded-lg shadow border border-zinc-300 justify-center items-center gap-3.5 inline-flex',
}
